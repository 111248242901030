import { ArrowCircleRightIcon } from '@heroicons/react/solid'
import { SinglePricingCardContainer, SinglePricingHeading, SinglePricingImage, SinglePricingList, SinglePricingDetails, SinglePricingButton } from './styles'

const SinglePriceCard = ({plan, price, priceInDollars, checkList, color, planImage, submitPlan, value}) => {

    const specificBg = {
        backgroundColor: color,
    }

    const specificBorder = {
        borderColor: color,
    }

    return (
        <SinglePricingCardContainer> 
            <SinglePricingHeading style={specificBg}>
                <h2> {plan} </h2>
                <h3> {value ? "" : `${plan === "Basic" ? "" : "₦"}${price}`}{!value && (plan === "Annual" ? " per month" : "")} </h3>
                <h3> {value ? `${plan === "Basic" ? "" : "$"}${priceInDollars}` : ""} {value && (plan === "Annual" ? " per month" : "")} </h3>
                <h3> {plan === "Annual" ? (value ? `$${priceInDollars * 12}` : `₦${price * 12}`) : ""} </h3>
            </SinglePricingHeading>

            <SinglePricingDetails>
                <div style={specificBg}></div>

                <SinglePricingImage style={specificBorder}>
                    <img src={planImage} alt={plan} />
                </SinglePricingImage>

                <SinglePricingList>
                    {
                        checkList.map((item, index) =>  <li key={index}> <img src={item.image} alt={item.alt} /> {item.text} </li>)
                    }
                </SinglePricingList>

            </SinglePricingDetails>

            <SinglePricingButton onClick={() => submitPlan(plan)} style={specificBg}>select plan <ArrowCircleRightIcon style={{height: "30px", marginLeft: "10px"}} />  </SinglePricingButton>
            
        </SinglePricingCardContainer>
    )
}

export default SinglePriceCard