import React from "react";
import { ErrorMessage, useField } from "formik";

export const Input = ({ label, ...props }) => {
	const [field, meta] = useField(props);
	return (
		<div className="mb-2 error-container">
			<label htmlFor={field.name}>{label}</label>
			<input
				className={`form-control shadow-none border border-dark py-3 ${
					meta.touched && meta.error && "is-invalid"
				}`}
				{...field}
				{...props}
				autoComplete="off"
			/>
			<ErrorMessage component="div" name={field.name} className="error text-left mt-1 mb-3" 
			/>
		</div>
	);
};
