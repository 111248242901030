import Header from '../../layout/Header/Header'
import Footer from '../../layout/Footer'
import { members } from './data'
import {
	TeamContainer,
	TeamWrapper,
	MemberWrapper,
	MemberHeader,
	MemberImage,
	MemberTitle,
	MemberDescription,
} from './styles'

const TeamNimdee = () => {
	const singleMember = (name, role, image, about, index) => {
		return (
			<MemberWrapper key={index}>
				<MemberHeader>
					<MemberImage>
						<img src={image} alt={`${name}_image`} />
					</MemberImage>
					<MemberTitle>
						<h1>{name}</h1>
						<h2>{role}</h2>
					</MemberTitle>
				</MemberHeader>
				<MemberDescription>{about}</MemberDescription>
			</MemberWrapper>
		)
	}

	return (
		<>
			<Header />

			<TeamContainer>
				<h2>Meet the Nimdee Team</h2>
				<TeamWrapper>
					{members.map((member, index) => {
						const { name, role, image, about } = member
						return singleMember(name, role, image, about, index)
					})}
				</TeamWrapper>
			</TeamContainer>

			<Footer />
		</>
	)
}

export default TeamNimdee
