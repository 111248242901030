import React, { useState } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import { Input } from "../TextField/TextField";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import Header from "../../layout/Header/Header";
import LoadingModal from "../../layout/LoadingModal/LoadingModal";
import { config } from '../../Constant'
import Button from '../Button/Button'
import {SuccessToast, ErrorToast} from '../Toast/Toast';

var url = config.url.API_URL

const SignupSchoolForm = () => {

	const [modalShow, setModalShow] = useState(false);
	const history = useHistory();
	const validate = Yup.object({
		schoolName: Yup.string()
			.max(34, "Must be 34 characters or less")
			.required("School name is Required"),
		email: Yup.string()
			.email("Email is invalid")
			.required("Email is required"),
		phoneNo: Yup.string()
			.max(11, "phoneNo must be at least 11 digits")
			.required("Email is required"),
		password: Yup.string()
			.min(6, "Password must be at least 6 characters")
			.required("Password is required"),
		address: Yup.string()
			.required("Password is required"),
		adminName: Yup.string()
			.min(6, "Admin name cannot be less than 6 characters")
			.required("name is  is required"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref("password"), null], "Password must match")
			.required("Confirm your password"),
		Voucher: Yup.string()
			.min(6, "Voucher code cannot be less than 6 characters")
			.max(6, "Voucher code cannot be more than 6 characters")
			.required("Voucher code is required"),
	});

	return (
		<Formik
			initialValues={{
				schoolName: "",
				email: "",
				phoneNo: "",
				password: "",
				adminName: "",
				confirmPassword: "",
				address: "",
				Voucher: "",
			}}
			validationSchema={validate}
			onSubmit={(values) => {
				setModalShow(true)
				values.phoneNo = '0'+values.phoneNo;
				console.log(values)
				axios
					.post(
						`${url}/Auth/auth.php/schoolsignup`,
						values
					).then((res) => {
						setModalShow(false);
						if (res.status === 200) {
							SuccessToast(res.data.message);
							setTimeout(() => {
								history.push("/school-and-teacher");
							}, 2000);
						} else {
							setModalShow(false);
							ErrorToast(res.data.message);
						}
					})
					.catch(function (error) {
						ErrorToast(error.response.data.message);
						setModalShow(false);
						console.log(error);
					});
			}}
		>
			{(formik) => (
				<div>
					<Header />
					<LoadingModal
						show={modalShow}
						onHide={() => setModalShow(false)}
					/>
					<div className="container justify-content-center my-5">
						<div className="container">
							<h2 className="bold mt-3 text-center text-uppercase">School Signup</h2>
							<div className="center-icon m-auto">
								<p className="parent-signup m-auto"></p>
							</div>
							<div className="row mt-4">
								<Form className="reset_password-form">
									<div className="mb-4 labelStyle">
										<Input
											label="School Name"
											id="form-input"
											name="schoolName"
											type="text"
											placeholder="Please enter your name"
										/>
									</div>
									<div className="mb-4 labelStyle">
										<Input
											label="Email"
											name="email"
											id="form-input"
											type="email"
											placeholder="Please enter your email"
										/>
									</div>
									<div className="mb-4 labelStyle">
										<Input
											label="Phone no."
											name="phoneNo"
											id="form-input"
											type="number"
											placeholder="Please enter your phone number"
										/>
									</div>
									<div className="mb-4 labelStyle">
										<Input
											label="Admin name"
											name="adminName"
											id="form-input"
											type="text"
											placeholder="Please enter the admin's name"
										/>
									</div>
									<div className="mb-4 labelStyle">
										<Input
											label="School address"
											name="address"
											id="form-input"
											type="text"
											placeholder="Please enter your school address"
										/>
									</div>
									<div className="mb-4 labelStyle">
										<Input
											label="Password"
											name="password"
											type="password"
											id="form-input"
											placeholder="Please enter your password"
										/>
									</div>
									<div className="mb-4 labelStyle">
										<Input
											label="Confirm Password"
											name="confirmPassword"
											type="password"
											id="form-input"
											placeholder="Please confirm your password"
										/>
									</div>
									<div className="mb-4 labelStyle">
										<Input
											label="Enter Voucher"
											name="Voucher"
											type="text"
											id="form-input"
											placeholder="Please Enter your voucher code if any"
										/>
									</div>
									<div className="button-div m-auto text-center mb-5">
										<Link to="/signup" className="mr-2">
											<Button className="btn mt-3" type="button" title="Back"></Button>
										</Link>
										<Button className="btn mt-3" type="submit" title="Sign Up" onClick={() =>
												{
												window.fbq('track', 'Lead')
											}}></Button>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			)}
		</Formik>
	);
};

export default SignupSchoolForm;