import { useRef, useEffect, useState  } from "react"
import { Link } from 'react-router-dom'
import { DashboardTwoTone, ComputerTwoTone, PeopleTwoTone, SupervisorAccountTwoTone, DescriptionTwoTone, EmailTwoTone, Subject } from '@material-ui/icons';
import { Overlay, SideBarContainer, SideBarMenu, SideBarMenuWrapper, BrandLogo } from './styles'
// import nimdee_logo from '../../../images/nimdee-02 1.svg'
import nimdee_logo from '../../../images/nimdee-02-removebg-resized.png'

const navData = [
    //SCHOOL TABS
    {
        id: 0,
        icon: <DashboardTwoTone />,
        title: "Dashboard"
    },

    //TEACHER TABS
    {
        id: 1,
        icon: <ComputerTwoTone />,
        title: "Classes"
    },
    {
        id: 2,
        icon: <PeopleTwoTone />,
        title: "Pupils"
    },

    //ADMINISTRATION TABS
    {
        id: 3,
        icon: <SupervisorAccountTwoTone />,
        title: "All Teachers"
    },
    {
        id: 4,
        icon: <ComputerTwoTone />,
        title: "All classes"
    },
    {
        id: 5,
        icon: <PeopleTwoTone />,
        title: "All Pupils"
    },
    {
        id: 6,
        icon: <DescriptionTwoTone />,
        title: "Spreadsheet importer"
    },
    {
        id: 7,
        icon: <EmailTwoTone />,
        title: "Contact us"
    },
]

const SideNav = ({ isOpen, setIsOpen, setCurrentPage }) => {

    const sidebarRef = useRef(null)
    const underlayRef = useRef(null)
    const [ activeTabId, setActiveTabId ] = useState(0)
    const teacherRole = JSON.parse(sessionStorage.getItem('teacherRole'))

    //controls hidding and showing of sidebar and its underlay
    useEffect(() => {
        if(isOpen ){
            sidebarRef.current.style.left = 0
            underlayRef.current.style.visibility = 'visible'
        } else {
            sidebarRef.current.style.left = `-280px`
            underlayRef.current.style.visibility = 'hidden'
        }
    }, [isOpen])

    //prevent page scroll when modal is shown
    useEffect(() => {
    if(isOpen){
        document.body.style.overflow = 'hidden';
    } 
    return () => {
        document.body.style.overflow = 'unset';
    } 
    }, [isOpen]);

    //handle closemodal/sidebar on click outside modal
    const closeModal = (e) => {
        if(underlayRef.current === e.target || sidebarRef.current === e.target){
            setIsOpen(false)
        }
    }

    //handle set reference id for page to be shown and handles close sidebar on link click
    const handleClickTabLink = (id) => {
        setCurrentPage(id)
        setActiveTabId(id)
        setIsOpen(false)
    }

    //describes the property of active tab link on click
    const activeTab = {
        backgroundColor: "rgb(54, 53, 97)",
        color: "white",
    }

    return (
        <Overlay ref={underlayRef} onClick={closeModal}>
        <SideBarContainer ref={sidebarRef}>
        <BrandLogo>
            <Subject onClick={() => setIsOpen(false)} />
            <img src={nimdee_logo} alt="NIMDEE_LOGO" height={35} width={150} />
        </BrandLogo>
      
            <SideBarMenuWrapper>
                {teacherRole === "Teacher" ? "" :
                    <SideBarMenu>
                        <h2>SCHOOL</h2>

                        <ul>
                            {
                                navData.slice(0, 1).map(data => {
                                    let activeStyle = {}

                                    if(activeTabId === data.id){
                                        activeStyle = activeTab
                                    }

                                    return (
                                        <li key={data.id} onClick={() => handleClickTabLink(data.id)}>
                                            <Link style={activeStyle}>
                                                {data.icon}
                                                <span> {data.title} </span>
                                            </Link>
                                        </li>
                                    )
                                } )
                            }
                        </ul>
                    </SideBarMenu>
                }

                <SideBarMenu>
                    <h2>TEACHER</h2>

                    <ul>
                        {
                            navData.slice(1, 3).map(data => {
                                let activeStyle = {}

                                if(activeTabId === data.id){
                                    activeStyle = activeTab
                                }

                                return (
                                    <li key={data.id} onClick={() => handleClickTabLink(data.id)}>
                                        <Link style={activeStyle}>
                                            {data.icon}
                                            <span> {data.title} </span>
                                        </Link>
                                    </li>
                                )
                            })  
                        }
                    </ul>
                    
                </SideBarMenu>

                {teacherRole === "Teacher" ? "" :
                    <SideBarMenu>
                        <h2>ADMINISTRATION</h2>

                        <ul>

                            {
                                navData.slice(3, 8).map(data => {
                                    let activeStyle = {}

                                    if(activeTabId === data.id){
                                        activeStyle = activeTab
                                    }

                                    return (
                                        <li key={data.id} onClick={() => handleClickTabLink(data.id)}>
                                            <Link style={activeStyle}>
                                                {data.icon}
                                                <span> {data.title} </span>
                                            </Link>
                                        </li>
                                    )
                                } )
                            }
                        </ul>
                        
                    </SideBarMenu>
                }
            </SideBarMenuWrapper>      
        </SideBarContainer>
        </Overlay>
    )
}

export default SideNav