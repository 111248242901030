import React from "react";
import { ErrorMessage, useField } from "formik";

export const Input = ({ ...props }) => {
	const [field, meta] = useField(props);
	return (
		<div className="mb-2 error-container">
			<input
				className={`form-control shadow-none border border-dark ${
					meta.touched && meta.error && "is-invalid"
				}`}
				{...field}
				{...props}
				autoComplete="off"
			/>
			{props.childLogin ? 
				<ErrorMessage component="div" name={field.name} className="error text-center" /> 
				: <ErrorMessage component="div" name={field.name} className="error text-left" />
			}
		</div>
	);
};
