import styled from "styled-components"
import { motion } from 'framer-motion'

export const styles = {
    containerWidth: "90vw",

    largeIcon: {
        height: "120px", 
        color: "#777"
    },

    informationCircleIcon : {
        height: "20px",
        paddingRight: "10px",
        color: "darkOrange",
        alignSelf: "start"
    },

    smallIcon : {
        height: "20px",
        padding: "0 10px",
        color: "#C4C4C4",
    },

    //BRIEF STYLING FOR UNSTYLED PAGE NUMBERS AND PAGE ITEMS
    unStyledPageListItem: {
        border: "none",
        padding: "3px 5px"
    }
}

export const ContentContainer = styled(motion.div)`
    height: 100%;
    position: relative;
    display: grid;
`
export const ContentWrapper = styled.div`
    @media screen and (min-width: 1024px){
        /* padding-left: 280px; */
    }
`
export const TopBanner = styled.div`
    background-color: rgb(54, 53, 97);
    padding: 20px 15px;
    color: white;
    margin-top: 75px;
    z-index: 30;
    -webkit-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
    -khtml-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
    border: 3px solid  rgb(54, 53, 97);

    button {
        background-color: rgba(206, 66, 101, 0.658);
        border-radius: 20px;
        border: none;
        padding: 0.5rem 1rem;
        color: white;
        outline: none;
        -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
        -khtml-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);

        > a {
            text-decoration: none;
            color: white;
        }

        > .MuiSvgIcon-root {
            font-size: 18px;
            margin-right: 10px;
        }

        > span, > a {
            text-transform: capitalize;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
        }

        > span > a {
            color: white;
            text-decoration: none;
        }

        :hover {
            background-color: #f0184e;
            color: white;
        }
    }
`

export const BannerText = styled.div`
    

    > h4 {
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 20px;
        font-size: 24px;
    }

    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > h4 {
            font-size: 27px;
            margin-bottom: 0;
        }
    }
`

export const SearchField = styled.div`
    max-width: 500px;
    padding: 20px 0;
    margin-left: auto;
`

export const ExportArea = styled.div`
    width: fit-content;
    margin-left: auto;
    padding: 10px 0;

    > button {
        padding: 0.5rem 1rem;

        > a {
            text-decoration: none;
            color: inherit;

            > span {
                margin-left: 10px;
            }

        }

        > span {
                margin-left: 10px;
        }

        @media screen and (max-width: 550px){
            span {
                display: none;
            }
        }
    }
`

export const TableWrapper = styled.div`
    padding: 15px;
    margin: 15px;
    margin-top: 80px;
    background-color: white;
    border: 2px solid rgb(54, 53, 97);
    /* overflow-x: auto; */

    .MuiIconButton-root {
        outline: none;
        border: none;
    }

    thead {
        background-color: rgb(54, 53, 97);
        color: white;
        user-select: none;
    }

    tbody tr td {
        .MuiSvgIcon-root {
            font-size: 18px;
        }
    }

    td:last-of-type {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .MTableHeader-header-13 {
        background-color: rgba(0, 0, 0, 0.1);
        font-weight: 600;
    }

    button {
        border-radius: 20px;
    }

    /*Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 1024px and also iPads specifically.*/
	/* @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) { */
    @media only screen and (max-width: 1024px), (max-device-width: 1024px) {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

        tr {
            margin: 0 0 1rem 0;
        }
        
        tr:nth-child(odd) {
            background: whitesmoke;
        }
    
		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
            padding-top: 20px !important;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			top: 5px;
            font-weight: 700;
            font-size: 10px;
            text-transform: uppercase;
			white-space: nowrap;
		}
	}
`

export const PupilsTable = styled.div`
    @media only screen and (max-width: 1024px), (max-device-width: 1024px) {
        th, td {text-align: left !important;}
        td:nth-of-type(1):before { content: "Name"; }
		td:nth-of-type(2):before { content: "Teacher Name"; }
		td:nth-of-type(3):before { content: "Class Desc."; }
		td:nth-of-type(4):before { content: "Login Code"; }
		td:nth-of-type(5):before { content: "Points"; }
		td:nth-of-type(6):before { content: "Last Activity"; }
		td:nth-of-type(7):before { content: "Created At"; }
    }
`

export const MyPupilsTable = styled.div`
    @media only screen and (max-width: 1024px), (max-device-width: 1024px) {
        th, td {text-align: left !important;}
        td:nth-of-type(1):before { content: "Name"; }
		td:nth-of-type(2):before { content: "Login Code"; }
		td:nth-of-type(3):before { content: "Points"; }
		td:nth-of-type(4):before { content: "Last Activity"; }
		td:nth-of-type(5):before { content: "Created At"; }
    }
`

export const ClassesTable = styled.div`
    @media only screen and (max-width: 1024px), (max-device-width: 1024px) {
        th, td {text-align: left !important;}
        td:nth-of-type(1):before { content: "Class Name"; }
		td:nth-of-type(2):before { content: "Description"; }
		td:nth-of-type(3):before { content: "# of pupil"; }
		td:nth-of-type(4):before { content: "Teacher"; }
    }
` 

export const TeachersTable = styled.div`
    @media only screen and (max-width: 1024px), (max-device-width: 1024px) {
        th, td {text-align: left !important;}
        td:nth-of-type(1):before { content: "Name"; }
		td:nth-of-type(2):before { content: "School Email"; }
		td:nth-of-type(3):before { content: "# of pupil"; }
		td:nth-of-type(4):before { content: "last activity"; }
    }
` 

export const Notification = styled.div`
    text-align: center;
    font-weight: 700;

    .MuiSvgIcon-root {
        font-size: 77px;
    }
`

export const ButtonContainer = styled.div`
    margin-top: 10px;

    > button {

        > a {
            text-decoration: none;
            color: white;
        }

        :first-of-type {
            margin-right: 5px;
        }
    }
`

export const Alter = styled.span``