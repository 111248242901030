import { Link } from "react-router-dom";
import { Facebook, Instagram, Twitter } from '@material-ui/icons';
import { FooterContainer, FooterColumns } from './styles'
import Modal from "react-modal";
import NewsletterForm from "../../components/NewsletterForm";
Modal.setAppElement("#root");

const Footer = () => {
    return (
        <FooterContainer>
            <NewsletterForm />

            <FooterColumns>

                {/* column one*/}
                <ul>
                    <h3>about us</h3>
                    <li>
                        <Link to="/teamnimdee"> meet team nimdee </Link>
                    </li>
                    <li>
                        <Link to="/termsandconditions"> terms and conditions </Link>
                    </li>
                    <li>
                        <Link to="/privacypolicy"> privacy policy </Link>
                    </li>
                </ul>
                

                {/* column two*/}
                <ul>
                    <h3>community</h3>
                    <li>
                        <a target="blank" href="https://blog.nimdee.com/"> blog </a>
					</li>
                    <li>
                        <Link to="#"> events </Link>
                    </li>
                    <li>
                        <a href="mailto:support@nimdee.com"> contact us </a>
					</li>
                </ul>

                {/* column three */}
                <ul>
                    <h3>support</h3>
                    <li>
                        <Link to="#"> search </Link>
                    </li>
                    <li>
                        <Link to="/faq"> faq </Link>
                    </li>
                    <li>
                        <a target="blank" href="https://wa.me/2349033854783"> whatsapp </a>
					</li>
                    <li>
                        <a href="mailto:support@nimdee.com">
                            Email: Support@nimdee.com
                        </a>
					</li>
                </ul>

                {/* column four */}
                <ul>
                    <h3>social media</h3>
                    <li>
                        <a href="https://twitter.com/nimdee2" target="blank"> <Twitter /> twitter </a>
					</li>
                    <li>
                        <a href="https://www.instagram.com/nimdee_/?hl=en" target="blank">  <Instagram /> instagram</a>
                    </li>
                    <li>
                        <a href="https://web.facebook.com/home.php?ref=wizard&_rdc=1&_rdr" target="blank"> <Facebook /> facebook</a>
                    </li>
                </ul>
            </FooterColumns>
        </FooterContainer>
    )
}

export default Footer