import { Modal, Button as BootstrapButton } from "react-bootstrap";
import { Formik, Form } from "formik";
import { Input } from "../../../components/TextField/ParentsTextfield";
import { fetchSchool } from "../../../features/redux/actionsCreators/school";
import { useDispatch, useSelector } from "react-redux";
import { SuccessToast, ErrorToast } from '../../../components/Toast/Toast';
import Button from '../../../components/Button/Button'
import axios from "axios";
import DropDown from "./DropDown";
import * as Yup from "yup";
import '../styles/styles.css'
import { config } from "../../../Constant";

function AddPupil(props) {

  const { isEditing, assignedClass, setAssignedClass, isEditedPupil, setIsEditedPupil, setModalShow } = props
  const URL = `${config.url.API_URL}/AdminDashboard/admindashboard.php`
  const dispatch = useDispatch()
  const { myClasses } = useSelector(state => state.school)

  // let uniqueClasses = myClasses.map(singleClass => singleClass.class_description)
  // uniqueClasses = [...new Set(uniqueClasses)]
  // console.log(uniqueClasses);

  // const classAssigned = myClasses.find(singleClass => singleClass.class_description === assignedClass)
  // console.log(classAssigned);

  let currentClass = Array.isArray(myClasses) && myClasses?.find(singleClass => singleClass)
  // console.log(currentClass);

  const validate = Yup.object({
    student_name: Yup.string()
      .max(34, "Must be 34 characters or less")
			.required("Name is required"),
	});
  
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        
      <Modal.Header className="bg-grey">
        <Modal.Title id="contained-modal-title-vcenter">
          {isEditing ? "EDIT PUPIL" : "ADD PUPIL"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
      <Formik
        initialValues={{
          student_name: isEditing ? isEditedPupil.student_name : "",
        }}
        validationSchema={validate}
        onSubmit={(values) => {
          const login_token = JSON.parse(sessionStorage.getItem('login_token'));
          values.login_token = login_token

          // console.log(values)

          if(!isEditing){
            // setIsEditedPupil({})
            // values.class_id = classAssigned?.class_id
            values.class_id = currentClass?.class_id
            values.student_id = null
            // values.teacher_name = classAssigned?.teacher_name
            values.teacher_name = currentClass?.teacher_name
            
            // console.log(values);

            axios.post(`${URL}/addSinglePupil`, values)
            .then((res) => {
              dispatch(fetchSchool())
              setAssignedClass("")
              SuccessToast(res.data.message)
            })
            .catch(error => {
              console.log(error.response)
              ErrorToast(error.response && error.response.data.message ? error.response.data.message : error.message);
            })

          } else {
            // values.class_id = isEditedPupil.class_id || classAssigned?.class_id
            // values.student_id = isEditedPupil.student_id
            // values.teacher_name = isEditedPupil.teacher_name || classAssigned?.teacher_name

            values.class_id = isEditedPupil.class_id
            values.student_id = isEditedPupil.student_id
            values.teacher_name = isEditedPupil.teacher_name

            // console.log(values);

            axios.post(`${URL}/addSinglePupil`, values)
            .then((res) => {
              dispatch(fetchSchool())
              setAssignedClass("")
              SuccessToast(res.data.message)
            })
            .catch(error => {
              console.log(error.response)
              ErrorToast(error.response && error.response.data.message ? error.response.data.message : error.message);
            })
          }
         setModalShow(false)
        }}
      >
        {(formik) => (
          <div>
            <h5> {isEditing ? "Edit pupil's details" : "Complete pupil's details"}</h5>
            <Form className="my-2">
                <Input
                  label="Student Name"
                  name="student_name"
                  type="text"
                  placeholder="Enter pupil's name"
                  />
    
                <div className="my-3"></div>

                {/* <DropDown legend={isEditing ? isEditedPupil.class_description : currentClass.class_description} options={uniqueClasses} assignedClass={assignedClass} setAssignedClass={setAssignedClass} isEditedPupil={isEditedPupil} setIsEditedPupil={setIsEditedPupil} /> */}

                <DropDown legend={isEditing ? isEditedPupil.class_description : currentClass.class_description} assignedClass={assignedClass} setAssignedClass={setAssignedClass} isEditedPupil={isEditedPupil} setIsEditedPupil={setIsEditedPupil} />
                
                <div className="my-3"></div>

                {/* {(isEditing && isEditedPupil.teacher_name.length > 0) && <p>Current teacher: {isEditing && isEditedPupil.teacher_name} </p>} */}

                {/* {classAssigned && <p>Assigned teacher: {classAssigned?.teacher_name} </p>} */}

                {currentClass && <p>Assigned teacher: {currentClass?.teacher_name} </p>}

              <Button type="submit" className="py-2 px-5 mt-2" title="Save" />
            </Form>
          </div>
          )}
          </Formik>
      </Modal.Body>
      <Modal.Footer className="bg-grey">
          <BootstrapButton variant="outline-dark" onClick={props.onHide} className="py-2 px-5" style={{borderRadius: "20px"}}>Cancel</BootstrapButton>
        </Modal.Footer>
    </Modal>
  );
}

  export default AddPupil