import {Link} from 'react-router-dom';
import styled from 'styled-components'
import Button from '../../components/Button/Button';
import ammenaWithBooks from '../../images/Ameena-with-books-and-blue-background.svg'
import jamalWithSuitcase from '../../images/Jamal-with-suitcase-and-yellow-background.svg';
import Header from '../../layout/Header/Header';

const Signup = () => {
    return (
        <div>
            <Header/>

			<LoginContainer>
				<h1>CREATE AN ACCOUNT</h1>
				<LoginButtons>
					<ImageButton>
						<div>
							<img src={jamalWithSuitcase} alt="jamal-with-suitcase" />
						</div>
						<Link to='/pricing'>
							<Button type="submit" title="Parent signup"/>
						</Link>
					</ImageButton>

					<ImageButton>
						<div>
                        	<img src={ammenaWithBooks} alt="ameena-with-books" />
						</div>
						<Link to='schoolsignup'>
							<Button type="submit" title="School signup"/>
						</Link>
                    </ImageButton>
				</LoginButtons>
			</LoginContainer>
        </div>
    )
}
export default Signup

const LoginContainer = styled.div`
	text-align: center;
	width: 90vw;
	max-width: 1650px;
	margin: 0 auto;
	margin-top: 55px;

	> h1 {
		font-size: 25px;
		font-weight: 700;
		color: #444;
		text-transform: uppercase;
	}

	@media screen and (min-width: 768px){
		> h1 {
			font-size: 30px;
		}
	}
`

const LoginButtons = styled.div`
	@media screen and (min-width: 768px){
		display: flex;
		text-align: center;
		justify-content: center;
	}
`

const ImageButton = styled.div`
	display: grid;
	place-items: center;
	margin: 10px 0;
	padding: 20px 10px;
	border-radius: 7px;

	> div {
		max-height: 400px;
		max-width: 400px;
		margin: 10px 0;

		> img {
			object-fit: fill;
			height: 90%;
			width: 90%;
		}
	}

	@media screen and (min-width: 768px){
		/* border-top: 2px solid #ddd; */

		> div {
			height: 400px;
			width: 400px;
		}
	}
`