import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
// import Header from "../layout/Header/Header";
import { config } from '../../Constant'
import Button  from "../../components/Button/Button";
import LoadingModal from "../../layout/LoadingModal/LoadingModal";

const VerifyEmail = (props) => {
	// const url = document.location.hostname;
	const {token} = useParams();
	const history = useHistory();

	useEffect(() => {
		axios
			.post(
				`${config.url.API_URL}/Auth/auth.php/verifyemail`,
				JSON.stringify({token: token})
			)
			.then((res) => {
				if (res.status === 200) {
					setSuccess(res.data.message);
					setLoaded(true)
					setTimeout(() => {
						history.push("/parentslogin");
					}, 5000);
				} else {
				}
			})
			.catch(function (error) {
				console.log(error);
				setLoaded(true)
			});
	}, [history, token]);

	const [Success, setSuccess] = useState("");
	const [isLoaded, setLoaded] = useState(false);

	return (
		<div>
			{/* <Header /> */}
			{isLoaded ? (
				<div>
					{Success ? 
						<div className="container h-100">
							<div className="justify-content-md-center align-items-center vh-100 pt-5">
								<h2 className="text-center text-success">
									Congratulations, Your account has been verified
								</h2>
								<div className="text-center m-auto pt-5 ">
									<img
										src="../../images/verified-icon.png"
										alt="verified-icon"
									/>
								</div>
								<Link to="/parentslogin">
									<Button type='button' title='Click here to login'/>
								</Link>
							</div>
						</div>
					:	<div className="container">
							<div className="expired pt-5">
								<h2 className="text-danger">This link has expired</h2>
								<Link to="/parentslogin">
									<Button type='button' title='Click here to login'/>
								</Link>
							</div>
						</div>
					}
				</div>
				) : (
					<LoadingModal/>
				)}
		</div>
	);
};

export default VerifyEmail;