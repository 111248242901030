import styled from 'styled-components'

export const ContactFormContainer = styled.div`
    margin-top: 80px;
    border: 1px solid brown;
`

export const FormWrapper = styled.div`
    width: 90vw;
    max-width: 650px;
    margin: 50px auto;

    input, textarea {
        border-radius: 5px;
        padding-top: 23px !important;
        padding-bottom: 23px !important;
        border: 2px solid #777 !important;
    }
`

export const FormHeading = styled.div`

    > h2 {
        font-size: 25px;
    }

    > h3 {
        font-size: 22px;
        font-weight: 800;
    }

    > h4 {
        font-size: 22px;
    }

    > a {
        border: none;
        border-radius: 20px;
        outline: none;

        :focus {
            border: none;
            outline: none;
        }
    }

    @media screen and (min-width: 768px){
        > h2 {
            font-size: 30px;
        }

        > h3 {
            font-size: 25px;
        }

        > h4 {
            font-size: 25px;
        }
    }

`

export const WhatsappButton = styled.button`
    padding: 8px 12px;
    border-radius: 10px;
    border-radius: 2px;
    /* background-color: green; */
    color: #075E54;
    border: 2px solid #075E54;
    outline: none;
    font-size: 11px;
    /* box-shadow: 0 0 5px lightgreen; */
    font-weight: 600;
    transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) 200ms;
    position: relative;
    z-index: 0;

    :focus {
        /* border: none; */
        border: 2px solid white;
        outline: none;
    }

    ::after {
        content: "";
        border-radius: 10px;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        background-color: #075E54;
        z-index: -1;
        transition: 500ms;
    }

    :hover {
        color: white;
        border: 2px solid white;
        box-shadow: 0 0 20px #777;

        ::after {
            width: 100%; 
        }
    }

    .MuiSvgIcon-root {
        font-size: 30px;
        margin-left: 10px;
    }

    @media screen and (min-width: 768px){
        padding: 10px 15px;
        font-size: 14px;
    }
`

export const DropDownContainer = styled.div`
    display: grid;
    gap: 10px;
    margin: 15px 0;

    @media screen and (min-width: 500px){
        grid-template-columns: repeat(2, 1fr);
    }
`