import styled from 'styled-components'

export const DropDownContainer = styled.div`
    position: relative;
`
export const DropDownTitle = styled.div`
    border: 1px solid #222;
    padding: 14px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select:none;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
`
export const DropDownMenu = styled.ul`
    list-style-type: none;
    padding: 0;
    border: 1px solid #222;
    box-shadow: 0px 5px 10px #999;
    background-color: white;
    position: absolute;
    width: 100%;
    z-index: 30;
    overflow-y: scroll;
    max-height: 200px;

    ::-webkit-scrollbar {
        background-color: white;
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
        cursor: pointer;
    }
`
export const DropDownItem = styled.li`
    padding: 10px;
    background-color: white;
    user-select:none;
    font-size: 14px;
    
    :hover {
        background-color: rgb(54, 53, 97);
        color: white;
    }
`