import React from 'react';
import Header from '../../layout/Header/Header';
import Footer from '../../layout/Footer';
import { PrivacyPolicyData } from './PrivacyPolicyData';
import Accordion from '../../Helpers/Accordion';

const PrivacyPolicy = () => {
  return (
    <>
      <Header />

      <div className='terms container'>
        <h1 className='text-center mt-3'>Privacy Policy</h1>
        <h5 className='text-center mb-5 mt-5'>
          Welcome to the Nimdee's Privacy Notice (“Notice”)
        </h5>
        <div className="accordion">
                {PrivacyPolicyData.map(({ title, content, content2, list1, list2, list3, contentOnNewLine1, contentOnNewLine2, contentOnNewLine3, contentOnNewLine4, list1sublist, list2sublist, list3sublist, list4sublist, list5sublist, list6sublist, list7sublist}) => (
                  <Accordion title={title} content={content} content2={content2} list1={list1} list2={list2} list3={list3} contentOnNewLine1={contentOnNewLine1} contentOnNewLine2={contentOnNewLine2} contentOnNewLine3={contentOnNewLine3} contentOnNewLine4={contentOnNewLine4} list1sublist={list1sublist} list2sublist={list2sublist} list3sublist={list3sublist} list4sublist={list4sublist} list5sublist={list5sublist} list6sublist={list6sublist} list7sublist={list7sublist}/>
                ))}
            </div>
            <div className='contact mb-5'>
              <h4>Contact Us</h4>
              <p>
                If You have any questions about this Privacy Notice, please contact
                Us at <a href='mailto:support@nimdee.com'> support@nimdee.com</a>
              </p>
            </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
