import React from 'react'
import { Link } from 'react-router-dom'
import './Error404.css'

const Error404 = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="Oops">Oops!</h1>
                    <p className="findPage">We can’t seem to find the page <br/> you are looking for</p>
                    <Link className="go-back" to="/">Go Back</Link>
                </div>
                <div className="col lost-image">
                    <img src="../../../images/Timi-lost.png" alt="Timi"/>
                </div>
            </div>
        </div>
    )
}

export default Error404
