import './Button.css'

const Button = (props) => {
	const { classes, style, type, disabled, onClick, title, children } = props
	return (
		<button
			className={`button ${classes}`}
			style={style}
			type={type}
			disabled={disabled}
			onClick={onClick}>
			{title && title}
			{children && children}
		</button>
	)
}
export default Button
