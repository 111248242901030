import { Link, useHistory } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import SubjectIcon from '@material-ui/icons/Subject'
import nimdee_logo from '../../../images/nimdee-02-removebg-resized.png'
import Button from '../../../components/Button/Button'

const TopNav = ({ toggleSidebar }) => {
	const history = useHistory()

	const logOut = () => {
		sessionStorage.removeItem('login_token')
		sessionStorage.removeItem('teacherRole')
		localStorage.removeItem('Admin Name')
		localStorage.removeItem('refresh')
		history.push('/login')
	}

	return (
		<div className='px-3 py-2 py-sm-2 py-lg-2 position-fixed fixed-top w-100 bg-white border'>
			<Navbar bg='white' expand='lg' className='w-100 align-items-center'>
				<SubjectIcon
					className=''
					style={{ height: '35px', width: '35px' }}
					onClick={toggleSidebar}
				/>
				<Navbar.Brand as={Link} to='/' className='ml-3'>
					{' '}
					<img
						src={nimdee_logo}
						alt='NIMDEE_LOGO'
						height={40}
						width={130}
					/>{' '}
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='d-md-flex flex-fill justify-content-md-center'>
						<Nav.Link
							as={Link}
							to='/school-and-teacher'
							className='px-2 px-sm-2 px-lg-3 py-3'>
							{' '}
							Dashboard{' '}
						</Nav.Link>
						<Nav.Link
							as={Link}
							to='/worksheets'
							className='px-2 px-sm-2 px-lg-3 py-3'>
							{' '}
							Worksheets{' '}
						</Nav.Link>
						<Nav.Link
							as={Link}
							to='/workbooks'
							className='px-2 px-sm-2 px-lg-3 py-3'>
							{' '}
							Workbooks{' '}
						</Nav.Link>
						<Nav.Link
							as={Link}
							to='/books'
							className='px-2 px-sm-2 px-lg-3 py-3'>
							{' '}
							Stories{' '}
						</Nav.Link>
					</Nav>
					<Button
						onClick={logOut}
						title='Log out'
						className='px-2 px-sm-2 px-lg-3 py-3'
					/>
				</Navbar.Collapse>
			</Navbar>
		</div>
	)
}

export default TopNav
