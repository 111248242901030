import { CloudDownload, Description } from "@material-ui/icons"
import { Button } from "react-bootstrap"
import { CSVLink } from "react-csv"


const alertNodataToExport = () => {
    alert("NO DATA TO EXPORT, ADD ATLEAST ONE ITEM TO THE TABLE")
}

export const exportTableAsCSVButton = (arr, exportFunc, filename) => {
    if(Array.isArray(arr)){
        return (
            <Button variant="primary"> <CSVLink data={exportFunc} filename={filename} target="_blank">  <CloudDownload /> <span>Export as CSV</span> </CSVLink> </Button> 
        )
    } else {
        return (
            <Button variant="primary" onClick={alertNodataToExport}> <CloudDownload /> <span>Export as CSV</span> </Button>
        )
    }
}

export const exportAllLoginCodesButton = (arr, exportFunc) => {
    if(Array.isArray(arr)){
        return (
            <button>
                <Description />
                <span><CSVLink data={exportFunc} filename={"ALL_PUPILS_LOGIN_CODES.csv"} target="_blank"> Download Login Codes </CSVLink></span>
            </button>
        )
    } else {
        return (
            <button onClick={alertNodataToExport}>
                <Description />
                <span> Download Login Codes </span>
            </button>
        )
    }
}


export const exportDataAsCSV = (arr, arrStringified) => {
    if (Array.isArray(arr)) {
        if(arrStringified === "activeMyPupils" || arrStringified === "activeAllPupils"){
            return (
                arr?.map(pupil => {
                    const { student_name : NAME, accesscode : LOGIN_CODE, class_description : CLASS_DESCRIPTION } = pupil
                    return (
                        { NAME, LOGIN_CODE, CLASS_DESCRIPTION }
                    )
                })
            )
        } else if (arrStringified === "activeAllClasses") {
            return (
                arr?.map(singleClass => {
                    const { class_name : CLASS_NAME, class_description : CLASS_DESCRIPTION , teacher_name : TEACHER_NAME  } = singleClass
                    return (
                    { CLASS_NAME, CLASS_DESCRIPTION, TEACHER_NAME }
                    )
                })
            )
        } else if (arrStringified === "allPupils") {
            return (
                arr?.map(pupil => {
                    const { student_name : PUPIL_NAME, accesscode : LOGIN_CODE } = pupil
                    return (
                    { PUPIL_NAME, LOGIN_CODE }
                    )
                })
            )
        } else if (arrStringified === "activeAllTeachers") {
            return (
                arr?.map(teacher => {
                    const { teacher_name : TEACHER_NAME, teacher_email: EMAIL } = teacher
                    return (
                    { TEACHER_NAME, EMAIL }
                    )
                })
            )
        }
        
        else {
            return null
        }
    } else {
        return null
    }
}