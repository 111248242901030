import React, {useEffect, useState, useCallback} from 'react'
import {Nav, Row, Tab, Col} from 'react-bootstrap'
import ParentDashboardHeader from '../../layout/ParentDashboardHeader/ParentDashboardHeader';
import { Formik, Form } from "formik";
import { Input } from '../../components/TextField/ParentsTextfield'
import * as Yup from "yup";
import axios from 'axios'
import { config } from '../../Constant'
import './AccountSettings.css'
import Bestoffer from '../../images/Best-offer.png'
import dateFormat from 'dateformat';

const AccountSettings = () => {

    const [Details, setDetails] = useState({});
    const [InitialName, setInitialName] = useState("");
    const [changeName, setchangeName] = useState("");
    const [InitialEmail, setInitialEmail] = useState("");
    const [changeEmail, setchangeEmail] = useState("");
    const [InitialPassword, setInitialPassword] = useState("");
    const [changePassword, setchangePassword] = useState("");
    const [newPassword, setnewPassword] = useState("");
    const [isReadonly, setIsReadonly] = useState(true);
    const [isReadonly2, setIsReadonly2] = useState(true);
    const [isReadonly3, setIsReadonly3] = useState(true);
    const [isLoaded, setLoaded] = useState(false);

    const userInfo = JSON.parse(sessionStorage.getItem("user"));


    useEffect(() => {
        axios
        .post(
            `${config.url.API_URL}/ParentDashboard/parentDashboard.php/accountDetails`,
            {login_token: userInfo}
        )
        .then((res) => {
            if (res.status === 200) {
                setDetails(res.data.message)
                setchangeName(res.data.message.name)
                setInitialName(res.data.message.name)
                setchangeEmail(res.data.message.email)
                setInitialEmail(res.data.message.email)
                setInitialPassword("xxxxxxxxxx")
                setchangePassword("xxxxxxxxxx")
                setLoaded(true)
                defaultBorderIcons()
            } else {
                
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }, [userInfo])

    const validate = Yup.object({
		name: Yup.string()
			.required("Field cannot be empty"),
        email: Yup.string()
			.email("Email is invalid")
			.required("Field cannot be empty"),
        password: Yup.string()
			.min(6, "Password must be at least 6 characters")
			.required("Password is required"),
	});

    const onChangeName = useCallback(
        (e) => {
        setchangeName(e.target.value);
        setInitialName(e.target.value)
        },
        []
    );
    const onChangeEmail = useCallback(
        (e) => {
        setchangeEmail(e.target.value);
        },
        []
    );
    const onChangePassword = useCallback(
        (e) => {
        setchangePassword(e.target.value);
        setnewPassword(e.target.value);
        },
        []
    );
    const defaultBorderIcons = () => {
        document.getElementById("pencil-name").style.display = "block"
        document.getElementById("pencil-edit-email").style.display = "block"
        document.getElementById("pencil-edit-password").style.display = "block"
        document.getElementById("edit-name-input").classList.remove("name-input-class");
        document.getElementById("edit-email-input").classList.remove("name-input-class");
        document.getElementById("edit-password-input").classList.remove("name-input-class");
    }
    const editName = () => {
        setIsReadonly(false)
        var changename = document.getElementById("pencil-name");
        changename.style.display = "none";
        document.getElementById("edit-name-input").classList.add("name-input-class");
    }
    const editEmail = () => {
        setIsReadonly2(false)
        var changename = document.getElementById("pencil-edit-email");
        changename.style.display = "none";
        document.getElementById("edit-email-input").classList.add("name-input-class");
    }
    const editPassword = () => {
        setIsReadonly3(false)
        var changename = document.getElementById("pencil-edit-password");
        changename.style.display = "none";
        document.getElementById("edit-password-input").classList.add("name-input-class");
    }

    var isClose = true;

	function toggle() {
		let sideBar = document.querySelector(".sidebar");
		let closeBtn = document.querySelector(".closebtn");
		let hambuger = document.querySelector(".hambuger");
		if (isClose) {
			sideBar.style.display = "block";
			closeBtn.style.display = "block"
			hambuger.style.display = "none"
			isClose = false

		} else {
			sideBar.style.display = "none";
			closeBtn.style.display = "none"
			hambuger.style.display = "block"
			isClose = true
		}
	}

    return (
        <div>
        {isLoaded ? (    
            <Formik
            enableReinitialize
            initialValues={{ 
                name:  InitialName ? InitialName : changeName,
                email: InitialEmail ? InitialEmail : changeEmail,
                password: InitialPassword ? InitialPassword : changePassword
            }}
            
            validationSchema={validate}
            onSubmit={(values) => {
                    values.login_token = userInfo;
                    values.name = changeName;
                    values.email = changeEmail;
                    values.password = newPassword;
                    axios
                        .post(
                            `${config.url.API_URL}/ParentDashboard/parentDashboard.php/updateAccountDetails`,
                            values
                        )
                        .then((res) => {
                            if (res.status === 200) {
                                setDetails(res.data.message)
                                setchangeName(res.data.message.name)
                                setInitialName(res.data.message.name)
                                setchangeEmail(res.data.message.email)
                                setInitialEmail(res.data.message.email)
                                defaultBorderIcons()
                            } else {
                                
                            }
                        })
                        .catch(function (error) {
                        
                            console.log(error);
                        });
                        
                }}
            >
            {(formik) => (
                <div>
                    <ParentDashboardHeader/>
                    <div className="container">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                {/* Start of Small Screen side bar */}
                                <svg className="hambuger ml-4" viewBox="0 0 100 80" width="25" height="25" fill="#000" stroke="#000" onClick={()=>toggle()}>
                                    <rect width="100" height="10" rx="8"></rect>
                                    <rect y="30" width="100" height="10" rx="8"></rect>
                                    <rect y="60" width="100" height="10" rx="8"></rect>
                                </svg>
                                {/* End of small screen side bar */}
                                <Col sm={3} className="nav-keys-sm sidebar">
                                {/* Start of close button for small screen */}
                                <div className="closebtn">
                                    <a href onClick={()=>toggle()}>&times;</a>
                                </div>
                                {/* End of close button for small screen*/}
                                
                                <Nav variant="pills" className="flex-column" style={{marginTop:"5rem"}}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Account</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Plan Details</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Notification settings</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                </Col>
                                <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                    <Form>
                                        <div className="mt-5 d-flex justify-content-between">
                                            <h2 className="d-flex">Account</h2>
                                            <button type="submit" className="acct-button flex-end">Save</button>
                                        </div>
                                        <div className="mt-5">
                                            <h4 className="pl-3">Name</h4>
                                           
                                                <Input
                                                    name="name"
                                                    type="text"
                                                    placeholder="Enter your name"
                                                    className="mx-auto pl-3 mt-2 name"
                                                    id="edit-name-input"
                                                    onChange={onChangeName}
                                                    value={changeName}
                                                    readOnly={isReadonly}
                                                    // show={showEditIconName}
                                                /> 
                                                <i className="fa fa-pencil pencil" id="pencil-name" aria-hidden="true"
                                                    style={{cursor:"pointer"}}
                                                    onClick={()=> editName()}
                                                ></i>
                                            
                                        </div>
                                        <div className="mt-5">
                                            <h4 className="pl-3">Email Address</h4>
                                            {/* <Form> */}
                                                <Input
                                                    name="email"
                                                    type="text"
                                                    placeholder="Enter your email"
                                                    className="mx-auto pl-3 mt-2 name"
                                                    onChange={onChangeEmail}
                                                    id="edit-email-input"
                                                    value={changeEmail}
                                                    readOnly={isReadonly2}
                                                    
                                                /> 
                                                <i className="fa fa-pencil pencil-email" id="pencil-edit-email" aria-hidden="true"
                                                    style={{cursor:"pointer"}}
                                                    onClick={()=> editEmail()}
                                                ></i>   
                                            {/* </Form> */}
                                        </div>
                                        <div className="mt-5">
                                            <h4 className="pl-3">Password</h4>
                                            {/* <Form> */}
                                                <Input
                                                    name="password"
                                                    type="password"
                                                    id="edit-password-input"
                                                    placeholder="Enter your password"
                                                    className="mx-auto pl-3 mt-2 name"
                                                    readOnly={isReadonly3}
                                                    value={changePassword}
                                                    onChange={onChangePassword}
                                                />    
                                                <i className="fa fa-pencil pencil-password" id="pencil-edit-password" aria-hidden="true"
                                                    style={{cursor:"pointer"}}
                                                    onClick={()=> editPassword()}
                                                ></i> 
                                            {/* </Form> */}
                                        </div>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="mt-5 d-flex justify-content-between">
                                            <h2 className="d-flex">Plan Details</h2>
                                            {/* <button className="plan-details-button flex-end">Save</button> */}
                                        </div>
                                        <div className="mt-5">
                                            <div className="d-flex justify-content-between">
                                                <h4 className="pl-3 font-weight-bold">
                                                    {/* {console.log(Details)} */}
                                                    {Details.plan==="Monthly" ? 
                                                        <span>
                                                            {Details.plan}
                                                        </span>
                                                         : 
                                                        <span>
                                                            {Details.plan==="Annual" ? 
                                                                <span>Premium ({Details.plan}) <img src={Bestoffer} alt="best-offer" className="best-offer"></img></span> :
                                                                <span>Premium ({Details.plan})</span>
                                                            }
                                                        </span>
                                                        
                                                    }
                                                </h4> 
                                                <button className="details-btn">Change Plan</button>
                                            </div>
                                            <div className="separator pl-3 mt-4">
                                                <div className="d-flex justify-content-between"> 
                                                    <p>Plan Includes 4 Childen Account & Unlimited Worksheets</p>
                                                    <button className="details-btn">Renew Plan</button>
                                                </div>
                                                    <p>Started at: {dateFormat(Details.create_date.split(" ")[0], "mmmm dS, yyyy")}
                                                    </p>
                                            </div>
                                            <div className="d-flex justify-content-between mt-4">
                                                <p className="pl-3">Next recurring date: {dateFormat(Details.expiry_date == null ? Details.expiry_date : Details.expiry_date.split(" ")[0], "mmmm dS, yyyy")}</p>
                                                <button className="details-btn">Purchase History</button>
                                            </div>
                                            <div className="d-flex justify-content-between mt-4">
                                                <p className="pl-3">Payment method: <i className="fa fa-cc-visa" aria-hidden="true"></i> ***002</p>
                                                <button className="details-btn">Update payment info</button>
                                            </div>
                                            <div className="pl-3 mt-5">
                                                <p>Cancel plan? <button className="cancel">Cancel <i className="fa fa-times-circle-o" style={{color:"red"}} aria-hidden="true"></i></button></p> 
                                                
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div></div>
                                    </Tab.Pane>
                                </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>   
                </div>
            )}
        </Formik>
        ) : (
            <div className="text-center">
                <img
                    className="img-fluid text-center"
                    src="../../images/WorkInProgress.gif"
                    alt="Work In Progress"
                ></img>
            </div>
        )}
        </div>
    )
}

export default AccountSettings
