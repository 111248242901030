import {useState} from 'react';
import axios from "axios";
import "./ResetPassword.css";
import { Formik, Form } from "formik";
import { Input } from "../TextField/TextField";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import Header from "../../layout/Header/Header";
import { config } from '../../Constant'
import { ErrorToast, SuccessToast } from "../Toast/Toast";
import Button from "../Button/Button";
import LoadingModal from '../../layout/LoadingModal/LoadingModal';
import { useParams} from "react-router-dom";

const ResetPassword = () => {
	const { token } = useParams();
	const [modalShow, setModalShow] = useState(false);
	const history = useHistory();
	const validate = Yup.object({
		newpassword: Yup.string()
			.min(6, "Password must be at least 6 characters")
			.required("Password is required"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref("newpassword"), null], "Password must match")
			.required("Confirm password is required"),
	});

	if (token) {
		history.push("/");
	}
	return (
		<Formik
			initialValues={{
				newpassword: "",
				confirmPassword: "",
			}}
			validationSchema={validate}
			onSubmit={(values) => {
				setModalShow(true);
				values.token = token;
				axios
					.post(
						`${config.url.API_URL}/Auth/auth.php/UpdatePassword`,
						values
					)
					.then((res) => {
						setModalShow(false);
						if (res.status === 200) {
							SuccessToast(res.data.message);
							history.push("/parentslogin");
						} else {
							ErrorToast(res.data.error);
						}
					})
					.catch(function (error) {
						ErrorToast(error.res.data.error);
						console.log(error);
					});
			}}
		>
			{(formik) => (
				<>
					<Header />
					<LoadingModal show={modalShow} onHide={() => setModalShow(false)} />
					<div className="h-100 reset-password-section justify-content-center align-items-center">
						<div className="container">
							<h2 className="bold mt-3 text-center">
								Reset Password
							</h2>

							<div className="row mt-4 reset-password">
								<Form className="reset_password-form">
									<div className="mb-4 labelStyle">
										<Input
											label="Password"
											name="newpassword"
											type="password"
											id="form-input"
											placeholder="Please enter your password"
										/>
									</div>
									<div className="mb-4 labelStyle">
										<Input
											label="Confirm Password"
											name="confirmPassword"
											type="password"
											id="form-input"
											placeholder="Please confirm your password"
										/>
									</div>

									<div className="button-div m-auto text-center">
										<Link to="/pricing">
											<Button type='button' title='Back' className="btn mr-3"/>
										</Link>
										<Button type='submit' title='submit' className="btn"/>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</>
			)}
		</Formik>
	);
};

export default ResetPassword;