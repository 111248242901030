import axios from "axios";
import { ErrorToast, SuccessToast } from "../components/Toast/Toast";
import { config } from '../Constant';

const URL = `${config.url.API_URL}/AdminDashboard/admindashboard.php`

export const onDelete = (item, item_id, item_name, URLExtension, dispatchAction) => {
    const login_token = JSON.parse(sessionStorage.getItem('login_token')) ? JSON.parse(sessionStorage.getItem('login_token')) : "";

    const value = () => {
        if (item === "pupil"){
            return {
                "login_token": login_token,
                "student_id" : item_id
            }
        } else if (item === "teacher"){
            return {
                "login_token": login_token,
                "teacher_id" : item_id
            }
        } else if (item === "class"){
            return {
                "login_token": login_token,
                "class_id" : item_id
            }
        }
    }

    if(window.confirm("Are you sure you want to delete " + item_name + " ?")){
        axios.post(`${URL}/${URLExtension}`, value())
        .then((res) => {
            dispatchAction()
            SuccessToast(res.data.message.toUpperCase())
        })
        .catch(error => {
            console.log(error.message)
            ErrorToast(error.message.toUpperCase());
        })

    } else {
        return
    }
} 