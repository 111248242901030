// import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSchool, orderByName, searchAllTeachers } from '../../../features/redux/actionsCreators/school';
import { InputGroup, FormControl, Table } from 'react-bootstrap';
import { FileCopyOutlined, DeleteOutlineOutlined, EditOutlined, Search, DoneAll, Add, ArrowDropUp, ArrowDropDown, ErrorOutline, Loop } from '@material-ui/icons';
import { ContentContainer, ContentWrapper, TopBanner, BannerText, SearchField, ExportArea, TableWrapper, TeachersTable, Alter, Notification } from '../styles/styles'
import AddTeacher from './AddTeacher';
import IconButton from '@material-ui/core/IconButton';
import Pagination from '../TablePagination'
import CopyToClipboard from 'react-copy-to-clipboard';
import '../styles/styles.css'
import { exportTableAsCSVButton, exportDataAsCSV } from '../../../Helpers/ExportSchoolData';
import { onDelete } from '../../../Helpers/DeleteSchoolItem'
import usePrevious from '../../../hooks/usePrevious';


const AllTeachers = () => {

    // const URL = `${config.url.API_URL}/AdminDashboard/admindashboard.php`
    const dispatch = useDispatch()
    const AdminName = JSON.parse(localStorage.getItem("Admin Name"))
    const [ orderType, setOrderType ] = useState("ascending")
    const [ modalShow, setModalShow ] = useState(false)
    const [ isEditing, setIsEditing ] = useState(false)
    const [ searchInput, setSearchInput ] = useState("")
    const [ isCopied, setIsCopied ] = useState(false)
    const [ copiedId, setCopiedId ] = useState(0)
    const [ isEditedTeacher, setIsEditedTeacher ] = useState({})
    const { currentPage, itemsPerPage, activeAllTeachers, allPupils, 
        // allClasses, 
        error, loading } = useSelector(state => state.school)

    const previousEditedTeacher = usePrevious(isEditedTeacher)

    useEffect(() => {
        dispatch(fetchSchool())
    }, [dispatch])

    const onCopyText = (val) => {
        setCopiedId(val)
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    };
    
    const handleSearchInput = (e) => {
        setSearchInput(e.target.value)
        dispatch(searchAllTeachers(e.target.value))
    }

    const handleSort = () => {
        dispatch(orderByName(orderType))
        orderType === "ascending" ?  setOrderType("descending") : setOrderType("ascending")
    }
    
    const onEdit = (teacher_name, teacher_email, teacher_id) => {
        setModalShow(true)
        setIsEditing(true)
        setIsEditedTeacher({
            teacher_name,
            teacher_email,
            teacher_id
        })
    }

    //pagination resolutes
    const indexOfLastItem = (currentPage * itemsPerPage) - 1;
    const indexOfFirstItem = indexOfLastItem - (itemsPerPage - 1);
    const currentAllTeachers = activeAllTeachers && activeAllTeachers?.slice(indexOfFirstItem, (indexOfLastItem + 1))

    return (
        <ContentContainer
            initial={{opacity: 0.8}} 
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
        >
            <ContentWrapper>
                <TopBanner>
                    <BannerText>
                        <h4>ALL TEACHERS</h4>
                        <button onClick={() => {
                            setModalShow(true)
                            setIsEditing(false)
                            }}
                        > 
                                <Add /> 
                            <span>add teacher</span>               
                        </button>
                    </BannerText>
                    
                </TopBanner>

                <TableWrapper>
                    <SearchField>
                        <InputGroup className="w-100">
                            <FormControl
                                placeholder="Search teachers by name..."
                                aria-label="SearchPerson"
                                aria-describedby="search-icon"
                                value={searchInput}
                                onChange={ handleSearchInput }
                                className="border border-dark"
                            />
                            <InputGroup.Text id="search-icon"><Search /></InputGroup.Text>
                        </InputGroup>
                    </SearchField>

                    <ExportArea>
                         {exportTableAsCSVButton(activeAllTeachers, exportDataAsCSV(activeAllTeachers, "activeAllTeachers"), "ALL_TEACHERS.csv")}
                    </ExportArea>

                    <TeachersTable>
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th style={{cursor: "pointer" }} onClick={handleSort} > NAME { orderType === "ascending" ?  <ArrowDropUp  /> : <ArrowDropDown /> }</th>
                                    <th>SCHOOL EMAIL</th>
                                    {/* <th>CLASS</th> */}
                                    {/* <th>PASSWORD</th> */}
                                    <th># OF PUPILS</th>
                                    <th>LAST ACTIVITY</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(currentAllTeachers) && currentAllTeachers.length > 0 && currentAllTeachers.map((teacher, index) => {
                                    return (
                                        <tr key={index}>
                                            <td> { AdminName === teacher.teacher_name ? teacher.teacher_name + " (Admin)" : teacher.teacher_name} </td>
                                            <td>
                                                <CopyToClipboard text={teacher.teacher_email} onCopy={() => onCopyText(index)}>
                                                    <span className="copy-area">
                                                        <IconButton>
                                                            <FileCopyOutlined />
                                                        </IconButton>
                                                        <span className={`copy-feedback ${isCopied && index === copiedId ? "active" : ""}`}>
                                                            <DoneAll /> Copied
                                                        </span>
                                                    </span>
                                                </CopyToClipboard>
                                                {teacher.teacher_email}
                                            </td>
                                            {/* <td>{allClasses.find(singleClass => singleClass.teacher_name === teacher.teacher_name).class_name}</td> */}
                                            {/* <td>{ teacher.teacher_name.split(" ")[0] }</td> */}
                                            <td style={{textAlign: "center"}}>{ Array.isArray(allPupils) && allPupils.length > 0 ? allPupils.filter(pupil => pupil.teacher_id === teacher.teacher_id).length : "none"}</td>
                                            <td>
                                                { Math.floor(Math.random() * 4) + 2} days ago
                                                    <Alter>
                                                        <IconButton onClick={() => onEdit(teacher.teacher_name, teacher.teacher_email, teacher.teacher_id)}>
                                                            <EditOutlined  />
                                                        </IconButton>
                                                    {
                                                        AdminName === teacher.teacher_name ? "" :
                                                        <IconButton onClick={() => onDelete("teacher", teacher.teacher_id, teacher.teacher_name, "deleteSingleTeacher", () => dispatch(fetchSchool()))}>
                                                            <DeleteOutlineOutlined />
                                                        </IconButton>
                                                    }
                                                    </Alter>
                                                
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </TeachersTable>

                    {
                        !Array.isArray(currentAllTeachers) &&
                        <Notification> 
                            <ErrorOutline /> <p> NO DATA FOUND, ADD SINGLE ITEMS OR USE THE BULK UPLOAD </p> 
                        </Notification>
                    }

                    {
                        loading && 
                        <Notification> 
                            <Loop /> <p> LOADING DATA... PLEASE WAIT A SECOND </p> 
                        </Notification> 
                    }

                    {
                        error && 
                        <Notification> 
                            <ErrorOutline /> <p>{error}</p> 
                        </Notification>
                    }

                    {
                        activeAllTeachers?.length === 0 && 
                        <Notification> 
                            <ErrorOutline /> <p>OOPS!!! NO MATCHES FOR YOUR SEARCH</p> 
                        </Notification>
                    }

                    {
                        Array.isArray(activeAllTeachers) && activeAllTeachers?.length ? <Pagination people={activeAllTeachers} /> : ""
                    }
                    
                </TableWrapper>
            </ContentWrapper>

            <AddTeacher
                show={modalShow}
                isEditing={isEditing}
                isEditedTeacher={isEditedTeacher}
                previousEditedTeacher={previousEditedTeacher}
                setIsEditedTeacher={setIsEditedTeacher}
                setModalShow={setModalShow}
                onHide={() => setModalShow(false)}
            />
            
        </ContentContainer>
    )
}

export default AllTeachers