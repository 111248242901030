import {Link} from 'react-router-dom';
import styled from 'styled-components'
import Button from '../../components/Button/Button';
import ammenaWithBooks from '../../images/Ameena-with-books-and-blue-background.svg'
import jamalWithSuitcase from '../../images/Jamal-with-suitcase-and-yellow-background.svg';
import Header from '../../layout/Header/Header';

const Login = () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
	const child = JSON.parse(localStorage.getItem("childDetails"));
	const login_token = JSON.parse(sessionStorage.getItem('login_token'))

	const ParentLogInCheck = (user) => {
		if (user === undefined || user === null) {
			return false;
		} else {
			return true;
		}
	};

	const SchoolAdminLogInCheck = (login_token) => {
		if (login_token === undefined || login_token === null) {
			return false;
		} else {
			return true;
		}
	};

	const ChildLogInCheck = (child) => {
		if (child === undefined || child === null) {
			return false;
		} else {
			return true;
		}
	};

	const ParentLogIn = ParentLogInCheck(user);
	const ChildLogIn = ChildLogInCheck(child);
	const SchoolAdminLogIn = SchoolAdminLogInCheck(login_token);

	const ParentButton = () => {
		if (ParentLogIn) {
			return (
                <Link to="/parentdashboard">
                    <Button type='submit' title='Parent Dashboard'></Button>
                </Link>
			);
		} else if (SchoolAdminLogIn) {
			return (
                <Link to="/school-and-teacher">
                    <Button type='submit' title='School Dashboard'></Button>      
                </Link>
			);
		} else {
			return (
                <Link to="/parentslogin">
                    <Button type='submit' title='Parent/Teacher'></Button>      
                </Link>
			);
		}
	};

	const ChildButton = () => {
		if (ChildLogIn) {
			return (
                <Link to="/childjourney">
                    <Button type='submit' title='Child Dashboard'></Button>
                </Link>	
			);
		} else {
			return (
                <Link to="/childlogin">
                    <Button type='submit' title='Child/Student'></Button>
                </Link>
			);
		}
	};
    return (
        <div>
            <Header Login/>
			<LoginContainer>
				<h1>Log in</h1>
				<LoginButtons>
					<ImageButton>
						<div>
							<img src={jamalWithSuitcase} alt="jamal-with-suitcase" />
						</div>
                       {ParentButton()}
					</ImageButton>

					<ImageButton>
						<div>
                        	<img src={ammenaWithBooks} alt="ameena-with-books" />
						</div>
                        {ChildButton()}
                    </ImageButton>
				</LoginButtons>
			</LoginContainer>
        </div>
    )
}
export default Login

const LoginContainer = styled.div`
	text-align: center;
	width: 90vw;
	max-width: 1650px;
	margin: 0 auto;
	margin-top: 55px;

	> h1 {
		font-size: 25px;
		font-weight: 700;
		color: #444;
		text-transform: uppercase;
	}

	@media screen and (min-width: 768px){
		> h1 {
			font-size: 30px;
		}
	}
`

const LoginButtons = styled.div`
	@media screen and (min-width: 768px){
		display: flex;
		text-align: center;
		justify-content: center;
	}
`

const ImageButton = styled.div`
	display: grid;
	place-items: center;
	margin: 10px 0;
	padding: 20px 10px;
	border-radius: 7px;

	> div {
		max-height: 400px;
		max-width: 400px;
		margin: 10px 0;

		> img {
			object-fit: fill;
			height: 90%;
			width: 90%;
		}
	}

	@media screen and (min-width: 768px){
		/* border-top: 2px solid #ddd; */

		> div {
			height: 400px;
			width: 400px;
		}
	}
`