import axios from 'axios'
import * as ACTION from '../constants/school'
import { config } from '../../../Constant'
// import { useEffect } from 'react';

let URL = `${config.url.API_URL}/AdminDashboard/admindashboard.php/adminDashboard`
const login_token = JSON.parse(sessionStorage.getItem('login_token')) ? JSON.parse(sessionStorage.getItem('login_token')) : "";

// useEffect(() => {
    // window.addEventListener('storage', JSON.parse(sessionStorage.getItem('login_token')))
    // return () => {
	// 		window.removeEventListener('storage', JSON.parse(sessionStorage.getItem('login_token')))
	// 	}
// }, []);

const value = {
    "login_token": login_token
}

export const fetchSchool = () => async (dispatch) => {
    dispatch({type: ACTION.FETCH_LOADING})

    try {
        const response = await axios.post(URL, JSON.stringify(value))
        dispatch({type: ACTION.FETCH_DASHBOARD_SUCCESS, payload: response.data.data})
        dispatch({type: ACTION.FETCH_ALL_TEACHERS_SUCCESS, payload: response.data.all_teachers})
        dispatch({type: ACTION.FETCH_ALL_PUPILS_SUCCESS, payload: response.data.all_pupils})
        dispatch({type: ACTION.FETCH_ALL_CLASSES_SUCCESS, payload: response.data.all_classes})
        dispatch({type: ACTION.FETCH_MY_PUPILS_SUCCESS, payload: response.data.all_pupils_for_admin})
        dispatch({type: ACTION.FETCH_MY_CLASSES_SUCCESS, payload: response.data.all_classes_for_admin})

        if(response.data.data){
            localStorage.setItem("Admin Name", JSON.stringify(response.data.data.school_admin_teacher))
        } else {
           return
        }

    } catch (error) {
        dispatch({type: ACTION.FETCH_FAILED, payload: error.response && error.response.data.message ? error.response.data.message : error.message})
    }   
}

export const searchAllTeachers = (teacherName) => {
    return({type: ACTION.SEARCH_ALL_TEACHERS_BY_NAME, payload: teacherName})
}

export const searchAllPupils = (pupilName) => {
    return({type: ACTION.SEARCH_ALL_PUPILS_BY_NAME, payload: pupilName})
}

export const searchAllClasses = (className) => {
    return({type: ACTION.SEARCH_ALL_CLASSES_BY_NAME, payload: className})
}

export const searchMyPupils = (pupilName) => {
    return({type: ACTION.SEARCH_MY_PUPILS_BY_NAME, payload: pupilName})
}

export const searchMyClasses = (className) => {
    return({type: ACTION.SEARCH_MY_CLASSES_BY_NAME, payload: className})
}

export const orderByName = (orderType) => {
    return({type: ACTION.SORT_ITEMS_BY_NAME_ORDER, payload: orderType})
}