import { Card } from "react-bootstrap";

const Cards = (props) => {
    const {title, text, colour} =props;
    //width: '20rem'
    return (
        <Card style={{backgroundColor: `#${colour}`, color: 'white' }}>
            <Card.Body>
                <Card.Title><h1 style={{fontWeight: "900"}}>{title}</h1></Card.Title>
                <Card.Text>
                    {text}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default Cards