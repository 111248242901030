import React, { useState, useEffect } from "react";
import "./UpgradePayment.css";
import Header from "../../layout/Header/Header";
import { useHistory } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { config } from '../../Constant'
import { SelectPaymentContainer, SubscriptionPlansWrapper, TogglerContainer } from "../Pricing/styles";
import { ToggleOff, ToggleOn } from "@material-ui/icons";
import Footer from "../../layout/Footer";
import { cardText } from "../Pricing/CardText";
import PricingCard from '../../layout/PricingCard'

toast.configure();

const UpgradePayment = () => {
	var url = config.url.API_URL
	const history = useHistory()

	const [value, setValue] = useState(false);
	const [details, setDetails] = useState({});
	const user = JSON.parse(sessionStorage.getItem("user"));

	useEffect(() => {
		axios
			.post(
				`${url}/ParentDashboard/parentDashboard.php/parentdashboard`,
				{login_token: user}
			)
			.then((res) => {
				// console.log(res.data.data);
				setDetails({
					...res.data.data,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, [url, user]);

	const upgradeSuccess = () =>
		toast.success("Upgrade Successful", {
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
		});
	const upgradeFail = () =>
		toast.error("Upgrade Failed...try again", {
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
		});

	const notify = (msg) =>
		toast.success(msg, {
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
		});
	const errorStuff = (msg) =>
		toast.error(msg, {
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
		});

	const configMonthly = {
		email: details.email,
		amount: "250000",
		publicKey: "pk_live_0138508685298f9efdd67b23d91f798d48bd8609",
		currency: "NGN",
		// publicKey: "pk_test_4e331046a3e4ffdddac742a4670ae0b1b98c0b3f",
		plan: "PLN_c3gb5mhflxz4g7z",
	};
	const configAnnual = {
		email: details.email,
		amount: "1500000",
		publicKey: "pk_live_0138508685298f9efdd67b23d91f798d48bd8609",
		currency: "NGN",
		// publicKey: "pk_test_4e331046a3e4ffdddac742a4670ae0b1b98c0b3f",
		plan: "PLN_65bo1g6hrj4bd16",
	};

	let values = {
		accountid: details.accountid,
		amount: "0",
	};

	const upgradeRequest = (values) => {
		axios
			.post(
				`${url}/Parent/payment.php/UpdatePayment`,
				values
			)
			.then((res) => {
				upgradeSuccess();
				// console.log(res.data);

				// localStorage.setItem("user", JSON.stringify(res.data.data));
				history.push('/parentdashboard')
			})
			.catch(function (error) {
				upgradeFail();
			});
	};

	const onSuccess = (reference) => {
		const newValues = { ...values, ...reference };
		// console.log(newValues);
		axios
			.post(
				`${url}/Parent/payment.php/verifyPayment`,
				JSON.stringify(reference)
			)
			.then((res) => {
				if (res.data.data.status === "success") {
					notify("Payment Verification Successful");
					// console.log(res.data.data);
					upgradeRequest(JSON.stringify(newValues));
				} else {
					errorStuff("Payment Verification Failed");
					errorStuff("Upgrade Failed");
					// console.log(res.data.data);
				}
			})
			.catch((error) => {
				errorStuff("Payment Verification Failed");
				errorStuff("Upgrade Failed");
			});
	};
	const onClose = (error) => {
		upgradeFail();
	};

	const initializeMonthlyPayment = usePaystackPayment(configMonthly);
	const initializeAnnualPayment = usePaystackPayment(configAnnual);

	const upgradeToMonthly = () => {
		values = { ...values, amount: 2500 };
		initializeMonthlyPayment(onSuccess, onClose);
	};

	const upgradeToAnnual = () => {
		values = { ...values, amount: 15000 };
		initializeAnnualPayment(onSuccess, onClose);
	};

	const submitPlan = (plan) => {
		console.log(plan)
		if (plan === 'Monthly') {
			upgradeToMonthly()
		} 
		else if (plan === 'Annual') {
			upgradeToAnnual()
		}
		else {
			return
		}
	};

	return (
		<div>
			<Header />
			{
                <SelectPaymentContainer upgradePlan>
                    <h1>Select Plan</h1>

                    <TogglerContainer onClick={() => setValue(!value)}>
                            <span>(₦)</span>
                                {value ? <ToggleOn /> :  <ToggleOff />}
                            <span>($)</span>
                    </TogglerContainer>

                    <SubscriptionPlansWrapper upgradePlan>
                        {cardText.slice(1).map((card, index) => <PricingCard key={index} {...card} value={value} submitPlan={() => submitPlan(card.plan)} />)}
                    </SubscriptionPlansWrapper>
                </SelectPaymentContainer>
            }
            <Footer />
			</div>
	);
};

export default UpgradePayment;
