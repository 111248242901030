import * as ACTION from '../../constants/childJourneyHeaderDetails'

const initialState = {
    avatarUrl: '',
    coins: 0,
    allBooks: [],
    loading: true
}

const childJourneyHeaderDetails = (state= initialState, {type, payload}) => { 
    switch (type) {
    
        case ACTION.FETCH_LOADING:
            return { ...state }

        case ACTION.GET_USER_COINS:
            return {
                ...state,
                loading: false,
                coins: payload
            }

        case ACTION.FETCH_AVATAR_URL:
            return {
                ...state,
                loading: false,
                avatarUrl: payload
            }

        case ACTION.GET_ALL_STORYBOOKS:
            return {
                ...state,
                loading: false,
                allBooks: payload
            }

        case ACTION.FETCH_FAILED:
            return {
                loading: false,
                error: payload
            }

        default:
            return state
    }
}

export default childJourneyHeaderDetails;