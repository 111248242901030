import {ContentContainer, ContentWrapper, TopBanner, BannerText,TableWrapper} from '../styles/styles'
// import {Row, Col } from "react-bootstrap";
import Cards from './Cards';
import VerticalTable from './VerticalTable'
import styled from 'styled-components'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSchool } from '../../../features/redux/actionsCreators/school';

const Dashboard = () => {

    const dispatch = useDispatch()
    const { dashboard, allClasses, allTeachers, allPupils } = useSelector(state => state.school)

    const count = (arr) => {
        if(Array.isArray(arr)){
            return arr?.length
        } else {
            return 0
        }
    }

    useEffect(() => {
        dispatch(fetchSchool())
    }, [dispatch])

    const difference = (date1, date2) => {  
        const date1utc = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const date2utc = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
        let day = 1000*60*60*24;
        return Math.ceil((date2utc - date1utc)/day)
      }

    const acctAge = difference(new Date(dashboard?.created_at), new Date());

    const data = [
        { 
            key: 'Name of School', 
            value: dashboard?.school_name 
        },
        { 
            key: 'Admin Name', 
            value: dashboard?.school_admin_teacher 
        },
        { 
            key: 'Admin Email', 
            value: dashboard?.school_email
        },
        { 
            key: 'Account age', 
            value: acctAge + " days"
        },
        { 
            key: 'Subscription', 
            value: 'Annual'
        },
        { 
            key: 'End of subscription', 
            value: '24, July 2022' 
        },
    ]

    return (
        <ContentContainer
            initial={{opacity: 0.8}} 
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
        >
            <ContentWrapper>
                <TopBanner primary>
                    <BannerText>
                        <h4>Dashboard</h4>
                    </BannerText>
                    <Row>
                        <Col>
                            <Cards title={count(allPupils)} text="Number of Students" colour="39315D"/>
                        </Col>
                        <Col>
                        <Cards title={count(allClasses)} text="Number of Classes" colour="F7A800"/>
                        </Col>
                        <Col>
                            <Cards title={count(allTeachers)} text="Number of Teachers" colour="4EC1E5"/>
                        </Col>
                    </Row>
                </TopBanner>
                <TableWrapper>
                    <VerticalTable data={data} />
                </TableWrapper>
            </ContentWrapper>
        </ContentContainer>
    )
}

export default Dashboard

const Row = styled.div`
    display: grid;
    /* grid-template-columns: repeat(3, 1fr); */
    gap: 10px;
    margin: 10px 0;

    @media screen and (min-width: 800px){
        grid-template-columns: repeat(3, 1fr);
    }
`

const Col = styled.div`
    /* margin: 0 5px; */
    border: 3px solid white;
    border-radius: 5px;
    text-align: center;
`