// import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSchool, orderByName, searchAllClasses } from '../../../features/redux/actionsCreators/school';
import { InputGroup, FormControl, Table } from 'react-bootstrap';
import IconButton from '@material-ui/core/IconButton';
import { Search, Add, ArrowDropUp, ArrowDropDown, EditOutlined, DeleteOutlineOutlined, ErrorOutline, Loop } from '@material-ui/icons';
import { ContentContainer, ContentWrapper, TopBanner, BannerText, SearchField, ExportArea, TableWrapper, ClassesTable, ButtonContainer, Alter, Notification } from '../styles/styles'
import AddClass from './AddClass';
import Pagination from '../TablePagination'
import { exportTableAsCSVButton, exportAllLoginCodesButton, exportDataAsCSV } from '../../../Helpers/ExportSchoolData';
import { onDelete } from '../../../Helpers/DeleteSchoolItem'


const AddClasses = () => {

    // const URL = `${config.url.API_URL}/AdminDashboard/admindashboard.php`
    const dispatch = useDispatch()
    const [ orderType, setOrderType ] = useState("ascending")
    const [ modalShow, setModalShow ] = useState(false)
    const [ isEditing, setIsEditing ] = useState(false)
    const [ searchInput, setSearchInput ] = useState("")
    const [ isEditedClass, setIsEditedClass ] = useState({})
    const [ assignedTeacher, setAssignedTeacher ] = useState("")
    const { currentPage, itemsPerPage, activeAllClasses, allPupils, 
        // allClasses, 
        error, loading } = useSelector(state => state.school)

    // let uniqueClasses = allClasses?.map(singleClass => singleClass.class_description)
    // uniqueClasses = [...new Set(uniqueClasses)]

    useEffect(() => {
        dispatch(fetchSchool())
    }, [dispatch])

    const handleSearchInput = (e) => {
        setSearchInput(e.target.value)
        dispatch(searchAllClasses(e.target.value))
    }

    const handleSort = () => {
        dispatch(orderByName(orderType))
        orderType === "ascending" ?  setOrderType("descending") : setOrderType("ascending")
    }

    const onEdit = (class_id, class_name, class_description, teacher_name) => {
        setModalShow(true)
        setIsEditing(true)
        setIsEditedClass({
            class_id,
            class_name,
            class_description,
            teacher_name
        })
    }

    const indexOfLastItem = (currentPage * itemsPerPage) - 1;
    const indexOfFirstItem = indexOfLastItem - (itemsPerPage - 1);
    const currentAllClasses = activeAllClasses && activeAllClasses?.slice(indexOfFirstItem, (indexOfLastItem + 1))

    return (
        <ContentContainer
            initial={{opacity: 0.8}} 
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
        >
            <ContentWrapper>
                <TopBanner>
                    <BannerText>
                        <h4>ALL CLASSES</h4>
                        <button onClick={() => {
                            setModalShow(true)
                            setIsEditing(false)
                            }}
                        > 
                            <Add /> 
                            <span>add class</span>               
                        </button>
                    </BannerText>

                    <ButtonContainer>
                        {exportAllLoginCodesButton(allPupils, exportDataAsCSV(allPupils, "allPupils"))}
                    </ButtonContainer>
                    
                </TopBanner>

                <TableWrapper>
                    <SearchField>
                        <InputGroup className="w-100">
                            <FormControl
                                placeholder="Search classes by name..."
                                aria-label="SearchPerson"
                                aria-describedby="search-icon"
                                value={searchInput}
                                onChange={ handleSearchInput }
                                className="border border-dark"
                            />
                            <InputGroup.Text id="seacrh-icon"><Search /></InputGroup.Text>
                        </InputGroup>
                    </SearchField>

                    
                    <ExportArea>
                        {exportTableAsCSVButton(activeAllClasses, exportDataAsCSV(activeAllClasses, "activeAllClasses"), "ALL_CLASSES.csv")}
                    </ExportArea>

                    <ClassesTable>
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th style={{cursor: "pointer" }} onClick={handleSort} > CLASS NAME { orderType === "ascending" ?  <ArrowDropUp  /> : <ArrowDropDown /> }</th>
                                    <th>DESCRIPTION</th>
                                    <th># OF PUPILS</th>
                                    <th>TEACHER</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(currentAllClasses) && currentAllClasses.length > 0 && currentAllClasses?.map((singleClass, index) => {
                                    return (
                                        <tr key={index}>
                                            <td> { singleClass.class_name }</td>
                                            <td> { singleClass.class_description }</td>
                                            <td style={{textAlign: "center"}}> { Array.isArray(allPupils) && allPupils.length > 0 ? allPupils.filter(pupil => pupil.class_id === singleClass.class_id).length : "none" }</td>
                                            <td>
                                                { singleClass.teacher_name ? singleClass.teacher_name : <p style={{color: "brown", fontWeight: "bold"}}>Please add a teacher to this class</p>}
                                                <Alter>
                                                    {/* {
                                                        singleClass.teacher_name && 
                                                        <Button variant="outline-primary">
                                                            Print letters
                                                            <CloudDownload style={{marginLeft: "10px"}} />
                                                        </Button>
                                                    } */}
                                                    <IconButton onClick={() => onEdit(singleClass.class_id, singleClass.class_name, singleClass.class_description, singleClass.teacher_name)}>
                                                        <EditOutlined />
                                                    </IconButton>
                                                    <IconButton onClick={() => onDelete("class", singleClass.class_id, singleClass.class_name, "deleteSingleClass", () => dispatch(fetchSchool()))}>
                                                        <DeleteOutlineOutlined />
                                                    </IconButton>
                                                </Alter>

                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </ClassesTable>

                    {
                        !Array.isArray(currentAllClasses) &&
                        <Notification> 
                            <ErrorOutline /> <p> NO DATA FOUND, ADD SINGLE ITEMS OR USE THE BULK UPLOAD </p> 
                        </Notification>
                    }

                    {
                        loading && 
                        <Notification> 
                            <Loop /> <p> LOADING DATA... PLEASE WAIT A SECOND </p> 
                        </Notification> 
                    }

                    {
                        error && 
                        <Notification> 
                            <ErrorOutline /> <p>{error}</p> 
                        </Notification>
                    }

                    {
                        activeAllClasses?.length === 0 && 
                        <Notification> 
                            <ErrorOutline /> <p>OOPS!!! NO MATCHES FOR YOUR SEARCH</p> 
                        </Notification>
                    }

                    {
                        Array.isArray(activeAllClasses) && activeAllClasses?.length > 0 ? <Pagination people={activeAllClasses} /> : ""
                    }
                    
                </TableWrapper>
            </ContentWrapper>

            <AddClass
                show={modalShow}
                assignedTeacher={assignedTeacher}
                setAssignedTeacher={setAssignedTeacher}
                isEditing={isEditing}
                isEditedClass={isEditedClass}
                setIsEditedClass={setIsEditedClass}
                setModalShow={setModalShow}
                onHide={() => {
                    setModalShow(false)
                    setAssignedTeacher("")
                }}
            />
            
        </ContentContainer>
    )
}

export default AddClasses