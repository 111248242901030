import styled from "styled-components"
// import { TableOnly } from '../styles/styles'

const VerticalTable = ({data}) => {
    return (
        <>
        <Table>
            {data.map((item, index)=> { 
                return (
                    <tr key={index}>
                        <Th>{item.key}</Th>
                        <Th>{item.value}</Th>
                    </tr>
            )})}
        </Table>
        </>
    )
}

export default VerticalTable

const Table = styled.table`
    border: 1px solid #bbb;
    /* border-radius: 10px; */
    th, td {
        border-collapse: collapse;
        padding: 1rem;
        /* border: 1px solid #757474; */
        border: 1px solid #bbb;
        font-weight: 400;
    }

    tr > th:first-of-type {
        font-weight: 700;
    }

    tr:nth-child(even){
        background-color: #f2f2f2
    }
    tr:hover {
        background-color: #ddd;
    }
    width:100%;
`
const Th = styled.th`
 td {
    padding: 5px;
    text-align: left;
  }
`