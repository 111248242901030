import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { cardText } from './CardText'
import { ToggleOff, ToggleOn } from '@material-ui/icons'
import {
	SelectPaymentContainer,
	TogglerContainer,
	SubscriptionPlansWrapper,
} from './styles'
import Header from '../../layout/Header/Header'
import Footer from '../../layout/Footer'
import PricingCard from '../../layout/PricingCard'
import { Breadcrumb } from 'react-bootstrap'

const SelectPayment = () => {
	const history = useHistory()
	const [value, setValue] = useState(false)

	const submitPlan = (plan) => {
		if (value) {
			localStorage.setItem(
				'plans',
				JSON.stringify({
					plan: plan,
					currency: 'dollars',
				})
			)
		} else {
			localStorage.setItem(
				'plans',
				JSON.stringify({
					plan: plan,
					currency: 'naira',
				})
			)
		}
		history.push('/parentsignup')
	}

	return (
		<>
			<Header />

			<Breadcrumb>
				<Breadcrumb.Item className='py-2 text-success'>
					<Link to='/'>Home</Link>
				</Breadcrumb.Item>
				<Breadcrumb.Item className='py-2'>
					<Link to='/pricing'>Pricing</Link>
				</Breadcrumb.Item>
			</Breadcrumb>

			{
				<SelectPaymentContainer>
					<h2>Select Plan</h2>

					<TogglerContainer onClick={() => setValue(!value)}>
						<span>(₦)</span>
						{value ? <ToggleOn /> : <ToggleOff />}
						<span>($)</span>
					</TogglerContainer>

					<SubscriptionPlansWrapper>
						{cardText.map((card, index) => (
							<PricingCard
								key={index}
								{...card}
								value={value}
								submitPlan={submitPlan}
							/>
						))}
					</SubscriptionPlansWrapper>
				</SelectPaymentContainer>
			}
			<Footer />
		</>
	)
}

export default SelectPayment
