import { Modal } from "react-bootstrap";

const LoadingModal = (props) => {
	const {loader} = props
	return (
		
		<div>
			{
				loader ? 
					<div className="text-center">
						<img
							className="img-fluid text-center"
							src="../../images/WorkInProgress.gif"
							alt="Work In Progress"
						></img>
					</div> : 
				<Modal
					{...props}
					
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Body>
						<img
							className="img-fluid"
							src="../../images/WorkInProgress.gif"
							alt="Work In Progress"
						></img>
					</Modal.Body>
				</Modal>
		}
			
		</div>
	);
};

export default LoadingModal;
