// import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSchool, orderByName, searchAllPupils } from '../../../features/redux/actionsCreators/school';
import { InputGroup, FormControl, Table } from 'react-bootstrap';
import { FileCopyOutlined, DeleteOutlineOutlined, ErrorOutline, EditOutlined, Search, DoneAll, Add, ArrowDropDown,  ArrowDropUp, Loop } from '@material-ui/icons';
import { ContentContainer, PupilsTable, ContentWrapper, TopBanner, BannerText, SearchField, ExportArea, TableWrapper, Alter, Notification } from '../styles/styles';

import AddPupil from './AddPupil';
import IconButton from '@material-ui/core/IconButton';
import Pagination from '../TablePagination'
import CopyToClipboard from 'react-copy-to-clipboard';
// import '../styles/styles.css'
// import { config } from '../../../Constant';
import { exportTableAsCSVButton, exportDataAsCSV } from '../../../Helpers/ExportSchoolData';
import { onDelete } from '../../../Helpers/DeleteSchoolItem'
// import Loading_Spinner from '../../../images/Loading_Spinner.gif'


const AllPupils = () => {

    // const URL = `${config.url.API_URL}/AdminDashboard/admindashboard.php`
    const dispatch = useDispatch()
    const [ orderType, setOrderType ] = useState("ascending")
    const [ modalShow, setModalShow ] = useState(false)
    const [ isEditing, setIsEditing ] = useState(false)
    const [ searchInput, setSearchInput ] = useState("")
    const [ isCopied, setIsCopied ] = useState(false)
    const [ copiedId, setCopiedId ] = useState(0)
    const [ isEditedPupil, setIsEditedPupil ] = useState({})
    const [ assignedClass, setAssignedClass ] = useState("")
    const { currentPage, itemsPerPage, activeAllPupils, error, loading } = useSelector(state => state.school)

    useEffect(() => {
        dispatch(fetchSchool())
    }, [dispatch])

    const onCopyText = (val) => {
        setCopiedId(val)
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    };

    const onEdit = (student_id, student_name, class_id, teacher_name, class_description) => {
        setModalShow(true)
        setIsEditing(true)
        setIsEditedPupil({
            student_id, 
            student_name,
            class_id,
            teacher_name,
            class_description
        })     
    }

    const handleSearchInput = (e) => {
        setSearchInput(e.target.value)
        dispatch(searchAllPupils(e.target.value))
    }

    const handleSort = () => {
        dispatch(orderByName(orderType))
        orderType === "ascending" ?  setOrderType("descending") : setOrderType("ascending")
    }
        
    const indexOfLastItem = (currentPage * itemsPerPage) - 1;
    const indexOfFirstItem = indexOfLastItem - (itemsPerPage - 1);
    const currentAllPupils = activeAllPupils && activeAllPupils?.slice(indexOfFirstItem, (indexOfLastItem + 1))

    return (
        <ContentContainer
            initial={{opacity: 0.8}} 
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
        >
            <ContentWrapper>
                <TopBanner>
                    <BannerText>
                        <h4>ALL PUPILS</h4>
                        <button onClick={() => {
                            setModalShow(true)
                            setIsEditing(false)
                            // setAssignedClass("")
                            }}
                        > 
                                <Add /> 
                            <span>add pupil</span>               
                        </button>
                    </BannerText>
                </TopBanner>

                <TableWrapper>
                    <SearchField>
                        <InputGroup className="w-100">
                            <FormControl
                                placeholder="Search pupils by name..."
                                aria-label="SearchPerson"
                                aria-describedby="search-icon"
                                value={searchInput}
                                onChange={ handleSearchInput }
                                className="border border-dark"
                            />
                            <InputGroup.Text id="seacrh-icon"><Search /></InputGroup.Text>
                        </InputGroup>
                    </SearchField>

                    <ExportArea>
                        {exportTableAsCSVButton(activeAllPupils, exportDataAsCSV(activeAllPupils, "activeAllPupils"), "ALL_PUPILS.csv")} 
                    </ExportArea>

                    <PupilsTable>
                    <Table responsive bordered hover>
                        <thead>
                            <tr>
                                <th style={{cursor: "pointer" }} onClick={handleSort} >NAME { orderType === "ascending" ?  <ArrowDropUp  /> : <ArrowDropDown /> }</th>
                                <th>TEACHER NAME</th>
                                <th>CLASS DESC.</th>
                                <th>LOGIN CODE</th>
                                <th>POINTS</th>
                                <th>LAST ACTIVITY</th>
                                <th>CREATED AT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(currentAllPupils) && currentAllPupils.length > 0 && currentAllPupils.map((pupil, index) => {
                                return (
                                    <tr key={ index }>
                                        <td>{ pupil.student_name }</td>
                                        <td>{ pupil.teacher_name ? pupil.teacher_name : <p style={{color: "brown", fontWeight: "bold"}}> Pupil has no teacher </p> }</td>
                                        <td>{ pupil.class_description ? pupil.class_description : <p style={{color: "brown", fontWeight: "bold"}}> Pupil has no class, please assign </p> }</td>
                                        <td>
                                            <CopyToClipboard text={ pupil.accesscode } onCopy={() => onCopyText(index)}>
                                                <span className="copy-area">
                                                    <IconButton>
                                                        <FileCopyOutlined />
                                                    </IconButton>
                                                    <span className={`copy-feedback ${isCopied && index === copiedId ? "active" : ""}`}>
                                                        <DoneAll /> Copied
                                                    </span>
                                                </span>
                                            </CopyToClipboard>
                                            { pupil.accesscode }
                                         </td>
                                        <td> 0 points </td>
                                        <td> {pupil.last_activity && pupil.last_activity !== null ? pupil.last_activity : "No activity yet"} </td>
                                        <td>
                                            {pupil.created_at.split(" ")[0]}
                                            <Alter>
                                                <IconButton onClick={() => onEdit(pupil.student_id, pupil.student_name, pupil.class_id, pupil.teacher_name, pupil.class_description)}>
                                                    <EditOutlined/>
                                                </IconButton>
                                                <IconButton onClick={() => onDelete("pupil", pupil.student_id, pupil.student_name, "deleteSinglePupil", () => dispatch(fetchSchool()))}>
                                                    <DeleteOutlineOutlined />
                                                </IconButton>
                                            </Alter>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </Table>
                    </PupilsTable>

                    {
                        !Array.isArray(currentAllPupils) &&
                        <Notification> 
                            <ErrorOutline /> <p> NO DATA FOUND, ADD SINGLE ITEMS OR USE THE BULK UPLOAD </p> 
                        </Notification>
                    }

                    {
                        loading && 
                        <Notification> 
                            <Loop />
                            {/* <img src={Loading_Spinner} alt="loading" /> */}
                            <p> LOADING DATA... PLEASE WAIT A SECOND </p> 
                        </Notification> 
                    }

                    {
                        error && 
                        <Notification> 
                            <ErrorOutline /> <p>{error}</p> 
                        </Notification>
                    }

                    {
                        activeAllPupils?.length === 0 && 
                        <Notification> 
                            <ErrorOutline /> <p>OOPS!!! NO MATCHES FOR YOUR SEARCH</p> 
                        </Notification>
                    }

                    {
                        Array.isArray(activeAllPupils) && activeAllPupils?.length > 0 ? <Pagination people={ activeAllPupils } /> : ""
                    }
                    
                </TableWrapper>
            </ContentWrapper>

            <AddPupil
                show={modalShow}
                assignedClass={assignedClass}
                setAssignedClass={setAssignedClass}
                isEditing={isEditing}
                isEditedPupil={isEditedPupil}
                setIsEditedPupil={setIsEditedPupil}
                setModalShow={setModalShow}
                onHide={() => {
                    setModalShow(false)
                    setAssignedClass("")
                }}
            />
            
        </ContentContainer>
    )
}

export default AllPupils