/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import './ParentDashHeader.css'
import UpgradePrompt from '../UpgradePrompt/UpgradePrompt'
import Dropdown from 'react-multilevel-dropdown'
import axios from 'axios'
import { config } from '../../Constant'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { pageNumberClick } from '../../features/redux/actionsCreators/pagination'

// axios error fixing
// https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
const ParentDashboardHeader = ({ message, referlink }) => {
	var url = config.url.API_URL
	const dispatch = useDispatch()
	const [UpgradeModal, setUpgradeModal] = useState(false)
	const [details, setDetails] = useState({})
	const [Notifications, setNotifications] = useState(message)

	const userInfo = JSON.parse(sessionStorage.getItem('user'))

	const logOutFunction = () => {
		window.location.href = '/'
		sessionStorage.setItem('user', null)
	}

	const values = {
		login_token: userInfo,
	}

	const fetchDetails = () => {
		axios
			.post(`${url}/ParentDashboard/parentDashboard.php/fetchDetails`, values)
			.then((res) => {
				setDetails({
					...res.data.data,
					headerName: res.data.data.name.split(' ')[0],
				})
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const getMessages = () => {
		axios
			.post(`${url}/ParentDashboard/parentDashboard.php/getMessages`, values)
			.then((res) => {
				res.data.data.map((item) => {
					const status = item.status
					if (status === 'Unread') {
						setNotifications(status)
					}
				})
			})
			.catch((err) => {
				console.log(err.response)
			})
	}

	useEffect(() => {
		fetchDetails()
		getMessages()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const createProfileLink = () => {
		if (details.plan === 'Basic' && details.haschild) {
			return (
				<Link className='nav-link p-3' onClick={() => setUpgradeModal(true)}>
					Create Profile
				</Link>
			)
		} else {
			return (
				<Link className='nav-link p-3' to='/childprofile'>
					Create Profile
				</Link>
			)
		}
	}

	return (
		<>
			<header className='position-sticky sticky-top'>
				<nav className='navbar navbar-expand-xl navbar-light bg-light'>
					<div className='container-fluid'>
						<Link to='/' className='navbar-brand'>
							<img src='../../images/nimdee-02 1.svg' alt='Nimdee Logo' />
						</Link>
						<button
							className='navbar-toggler'
							type='button'
							data-toggle='collapse'
							data-target='#navbarMenu'
							aria-controls='navbarMenu'
							aria-expanded='false'
							aria-label='Toggle navigation'>
							<span className='navbar-toggler-icon'></span>
						</button>

						<div className='collapse navbar-collapse'></div>
						<div className='collapse navbar-collapse container' id='navbarMenu'>
							<ul className='navbar-nav m-auto'>
								<li className='nav-item active'>
									<Link className='nav-link p-3' to='/parentdashboard'>
										Dashboard
									</Link>
								</li>
								<li className='nav-item'>
									<Link
										className='nav-link p-3'
										onClick={() => dispatch(pageNumberClick(1))}
										to='/workbooks'>
										Workbook
									</Link>
								</li>
								<li className='nav-item'>
									<Link
										className='nav-link p-3'
										onClick={() => dispatch(pageNumberClick(1))}
										to='/worksheets'>
										Worksheet
									</Link>
								</li>
								<li className='nav-item'>
									<Link
										className='nav-link p-3'
										onClick={() => dispatch(pageNumberClick(1))}
										to='/books'>
										Stories
									</Link>
								</li>
								<li className='nav-item'>{createProfileLink()}</li>
							</ul>
							<form className='form-inline dropdown nav-dropdown p-3'>
								<a
									className='nav-dropdown2 mr-2 dropdown-toggle'
									id='navbarDropdown'
									role='button'
									data-toggle='dropdown'
									aria-haspopup='true'
									aria-expanded='false'
									href>
									Welcome {details.headerName}
								</a>

								<div
									className='dropdown-menu nav-dropdown'
									aria-labelledby='navbarDropdown'>
									<a className='dropdown-item border-btm' href>
										<Dropdown.Item>
											<Link to='/account'>
												<span>
													<i className='fa fa-cog pr-3' aria-hidden='true'></i>
												</span>
												Account settings
											</Link>
										</Dropdown.Item>
									</a>

									{referlink ? (
										<a className='dropdown-item border-btm' href>
											<Dropdown.Item>
												<Link to='/refer-a-friend'>
													<span>
														<i
															className='fa fa-cog pr-3'
															aria-hidden='true'></i>
													</span>
													Refer a friend
												</Link>
											</Dropdown.Item>
										</a>
									) : (
										''
									)}
									<a className='dropdown-item border-btm' href>
										<Dropdown.Item>
											{/* <Link to=""> */}
											<span>
												<i class='fa fa-eye pr-3'></i>
											</span>
											View children code
											{/* </Link> */}
										</Dropdown.Item>
									</a>
									<a className='dropdown-item border-btm' href>
										<Dropdown.Item>
											<Link to='/childlogin'>
												<span>
													<i
														className='fa fa-user-o pr-3'
														aria-hidden='true'></i>
												</span>
												Child Portal
											</Link>
										</Dropdown.Item>
									</a>
									<a className='dropdown-item' href onClick={logOutFunction}>
										<Dropdown.Item>
											<span style={{ paddingLeft: '2rem' }}>Sign Out</span>
										</Dropdown.Item>
									</a>
								</div>
							</form>
							<form className='form-inline dropdown bell-notify  pl-3 pr-3 mr-2 '>
								<Link to='/notifications' style={{ textDecoration: 'none' }}>
									{Notifications === 'Unread' ? (
										<div className='icon'>
											<i className='fa fa-bell-o' aria-hidden='true'></i>
											<div className='txt'></div>
										</div>
									) : (
										<i className='fa fa-bell-o' aria-hidden='true'></i>
									)}
								</Link>
							</form>
							<form className='form-inline dropdown  pl-3 pr-3 pdhNavLink mr-2 m-3'>
								<p className='text-center my-auto'> {details.plan} </p>
							</form>
						</div>
					</div>
				</nav>
				<UpgradePrompt
					show={UpgradeModal}
					onHide={() => setUpgradeModal(false)}
				/>
			</header>
		</>
	)
}

export default ParentDashboardHeader
