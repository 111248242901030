import { useDispatch, useSelector } from "react-redux";
import { Modal, Button as BootstrapButton } from "react-bootstrap";
import { Input } from "../../../components/TextField/ParentsTextfield";
import { fetchSchool } from '../../../features/redux/actionsCreators/school';
import { Formik, Form } from "formik";
import { SuccessToast, ErrorToast } from '../../../components/Toast/Toast';
import Button from '../../../components/Button/Button'
import DropDown from "./DropDown";
import * as Yup from "yup";
import axios from "axios";
import { config } from '../../../Constant'

const AddClasses = (props) => {

  const { isEditing, assignedTeacher,  setAssignedTeacher, isEditedClass, setIsEditedClass, setModalShow } = props
  const URL = `${config.url.API_URL}/AdminDashboard/admindashboard.php`
  const dispatch = useDispatch()
  const { allTeachers } = useSelector(state => state.school)

  const teachersNames = Array.isArray(allTeachers) ? allTeachers?.map(teacher => teacher.teacher_name) : null

  const validate = Yup.object({
    class_name: Yup.string()
      .max(34, "Must be 34 characters or less")
			.required("Class name is required"),
    class_description: Yup.string()
      .max(34, "Must be 34 characters or less")
			.required("Class description is required"),
	});
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>

        <Modal.Header className="bg-grey">
          <Modal.Title id="contained-modal-title-vcenter">
            { isEditing ? "EDIT CLASS" : "ADD CLASS" }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Formik
          initialValues={{
            class_name: isEditing ? isEditedClass.class_name : "",
            class_description: isEditing ? isEditedClass.class_description : "",
          }}
          validationSchema={validate}
          onSubmit={(values) => {
            const login_token = JSON.parse(sessionStorage.getItem('login_token'));
            values.login_token = login_token
           
            // setModalShow(true);

            if(!isEditing){
              setIsEditedClass({})
              values.class_id = null
              values.class_teacher = assignedTeacher || null
              // console.log(values)

              axios.post(
                `${URL}/addSingleClass`,
                values
              )
              .then((res) => {
                dispatch(fetchSchool())
                SuccessToast(res.data.message)
              })
              .catch(error => {
                console.log(error.response)
                ErrorToast(error.response && error.response.data.message ? error.response.data.message : error.message);
              })
            } else {
              values.class_id = isEditedClass.class_id
              values.class_teacher = isEditedClass.teacher_name || assignedTeacher
              // console.log(values)

              axios.post(
                `${URL}/addSingleClass`,
                values
              )
              .then((res) => {
                dispatch(fetchSchool())
                SuccessToast(res.data.message)
              })
              .catch(error => {
                console.log(error.response)
                ErrorToast(error.response && error.response.data.message ? error.response.data.message : error.message);
              })
            }
            setModalShow(false)
          }}
        >
          {(formik) => (
            <div>
              <h5>{isEditing ? "Edit class information" : "Complete class information"} </h5>
              <Form className="my-2">
                  <Input
                    label="Class Name"
                    name="class_name"
                    type="text"
                    placeholder="Enter class name"
										/>
      
                  <div className="my-3"></div>

                  <Input
                    label="Class Description"
                    name="class_description"
                    type="text"
                    placeholder="Enter class description"
										/>

                  <div className="my-3"></div>

                  <DropDown legend={isEditing ? isEditedClass.teacher_name : "SELECT TEACHER"} options={teachersNames} assignedTeacher={assignedTeacher} setAssignedTeacher={setAssignedTeacher} isEditedClass={isEditedClass} setIsEditedClass={setIsEditedClass} />

                  <div className="my-3"></div>

            
                <Button type="submit" className="py-2 px-5 mt-2" title="Save" />
             
              </Form>
            </div>
            )}
            </Formik>
        </Modal.Body>
        <Modal.Footer className="bg-grey">
          <BootstrapButton variant="outline-dark" onClick={props.onHide} className="py-2 px-5" style={{borderRadius: "20px"}}>Cancel</BootstrapButton>
        </Modal.Footer>
      </Modal>
    );
  }

  export default AddClasses