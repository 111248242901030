import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { Backlay, VideoContainer, VideoWrapper } from './styles'
import CancelIcon from '@material-ui/icons/Cancel'

const DemoVideos = ({ videoLink, show, setShow }) => {
	const location = useLocation()

	//prevent page scroll when video is showing
	useEffect(() => {
		if (show) {
			document.body.style.overflow = 'hidden'
		}
		return () => {
			document.body.style.overflow = 'unset'
		}
	}, [show])

	const onClose = () => {
		setShow(false)
		location.pathname === '/childjourney' &&
			localStorage.setItem('watchDemoOnceOnLogin', true)
	}

	//component starts here....
	if (!show) {
		return null
	}

	return (
		<Backlay>
			<VideoContainer
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 1.0 }}>
				<VideoWrapper className='rounded'>
					<CancelIcon onClick={onClose} />
					<iframe
						title='demo'
						width='100%'
						height='100%'
						src={videoLink}
						frameBorder='1'
						allow='autoplay; encrypted-media'
						allowFullScreen={false}
						className='rounded'
						// ref={ frame => this.video = frame }
					/>
				</VideoWrapper>
			</VideoContainer>
		</Backlay>
	)
}

export default DemoVideos
