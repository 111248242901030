import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormContainer } from './styles' 
import { config } from '../../Constant'
import Button from '../Button/Button';

const NewsletterForm = () => {

    const url = config.url.API_URL

    const notifySucces = (msg) =>
        toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
    });

    const notifyError = (msg) =>
        toast.error(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
    });

    const handleSubscribe = (e) => {
        e.preventDefault();
        axios
          .post(
            `${url}/subscibeMailchimp`,
            JSON.stringify({ email: e.target.email.value })
        )
        .then((res) => {
            if (res.data.status === 'subscribed') {
              // Successful Toast
              notifySucces('Subscribed To Newsletter');
            } else if (res.data.status === 400) {
              if (res.data.title === 'Member Exists') {
                // Already Subscribed
                notifyError('Already Subscribed');
              } else {
                // Not Successful
                notifyError('Subscription Failed');
              }
            } else {
              // Not Successful
              notifyError('Subscription Failed');
            }
        })
        .catch((err) => {
            // Not Successful
            notifyError('Subscription Failed');
        });
    }

    return (
        <FormContainer>
            <h2>Subscribe to Our Newsletter</h2>
            <form onSubmit={handleSubscribe}>
                <input 
                    type='email' 
                    name='email'
                    id='email'
                    placeholder='Enter your Email' 
                    required
                />
                <Button type='submit' title='Subscribe' />
            </form>  
        </FormContainer>
    )
}

export default NewsletterForm