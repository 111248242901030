import { useState } from 'react';
import axios from 'axios';
import './ChildLogin.css';
// import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { SuccessToast, ErrorToast } from '../Toast/Toast';
import { config } from '../../Constant';
import { Input } from '../TextField/ParentsTextfield';
// import { getChildDashboardHeaderValues } from '../../features/redux/actionsCreators/childJourneyHeaderDetails';
import * as Yup from 'yup';
import Header from '../../layout/Header/Header';
import { Link,
	 useHistory 
	} from 'react-router-dom';
import LoadingModal from '../../layout/LoadingModal/LoadingModal';
import Button from '../../components/Button/Button'

const Childlogin = () => {
	const history = useHistory();
	const [modalShow, setModalShow] = useState(false);
	const validate = Yup.object({
		number: Yup.string()
			.min(5, 'Code is invalid should not be less than 5')
			.max(6, 'Code is invalid cannot be more than 6')
			.required('Put in a code to continue'),
	});

	return (
		<Formik
			initialValues={{ number: '' }}
			validationSchema={validate}
			onSubmit={(values) => {
				setModalShow(true);
				axios
					.post(`${config.url.API_URL}/Auth/auth.php/childlogin`, values)
					.then((res) => {
						setModalShow(false);
						if (res.status === 200) {
							SuccessToast(res.data.message );
							localStorage.setItem('childDetails', JSON.stringify(res.data.data));
							// dispatch(getChildDashboardHeaderValues())
							localStorage.setItem('PopUpStreak', JSON.stringify({ popUp: true }));
							
							history.push('/childjourney')
							
						} else {
							ErrorToast(res.data.error)
						}
					})
					.catch(function (error) {
						setModalShow(false);
						if (error.response) {
							ErrorToast(error.response.data.error)
						} else {
							ErrorToast("Opps an Error Occurred");
						}
					});
			}}
		>
			{(formik) => (
				<div>
					<Header childLoginPage='childLoginPage' />
					<LoadingModal show={modalShow} onHide={() => setModalShow(false)} />
					<div className="container justify-content-center my-5">
						<div className="container login-container">
							<div className="childlogin-image container">
								<img
									className="mx-auto d-block text-center img-fluid"
									src="../../../images/login-img-lola.png"
									alt=""
								/>
								<h2 className="text-center mt-4 code-login">PUT IN YOUR CODE TO LOG IN</h2>
							</div>
							<Form>
								<div className="mb-2 login-input-style">
									<Input
										name="number"
										id="login-form-input"
										type="number"
										placeholder="Enter your code"
										className="mx-auto pl-3"
										childLogin
									/>
								</div>
								<div className="button-div container mt-3">
									<Link to="/" className="mr-2">
										<Button className="btn" type="button" title="Back"/>
									</Link>
									<Button className="btn" type="submit" title="Login" style={{paddingBottom: '10px'}}/>
								</div>
							</Form>
						</div>
					</div>
				</div>
			)}
		</Formik>
	);
};
export default Childlogin;