import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Backlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
`

export const VideoContainer = styled(motion.div)`
    display: grid;
    place-items: center;
    margin: auto;
`

export const VideoWrapper = styled.div`
    position: absolute;
    top: 150px;
    height: 60vh;
    /* max-height: 600px; */
    width: 90vw;
    max-width: 1000px;
    z-index: 1;
    margin-right: 10px;

    .MuiSvgIcon-root {
        position: absolute;
        z-index: 2;
        top: -15px;
        right: -15px;
        font-size: 35px;
        color: #999;
        cursor: pointer;
        border: 3px solid;
        border-radius: 50%;
        transition: 300ms;

        :hover {
            color: white;
        }

        @media screen and (min-width: 700px){
            font-size: 50px;
            top: -25px;
            right: -25px;
        }
    }
`