import { Link } from 'react-router-dom'
import { useState } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import nimdee_logo from '../../images/nimdee-02-removebg-resized.png'
import Button from '../../components/Button/Button'
import DemoVideos from '../DemoVideos'

const Header = ({ Login, childLoginPage, Parentlogin }) => {
	const [videoLink, setVideoLink] = useState('')
	const [show, setShow] = useState(false)

	const demoVideos = [
		{
			title: 'Creating an account',
			link: 'https://www.youtube.com/embed/hzPRQEvP4ck',
		},
		{
			title: 'Downloading worksheets & reading stories',
			link: 'https://www.youtube.com/embed/_8wAuzF2DM0',
		},
		{
			title: 'Buying Avatars',
			link: 'https://www.youtube.com/embed/I8cfhj_aMzk',
		},
	]

	const handleShownVideo = (link) => {
		setShow(true)
		setVideoLink(link)
	}

	return (
		<div className='px-3 py-3 py-sm-3 py-lg-1 position-sticky sticky-top bg-white border'>
			<Navbar bg='white' expand='xl' className='w-100'>
				<Navbar.Brand as={Link} to='/'>
					<img src={nimdee_logo} alt='NIMDEE_LOGO' height={45} />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='d-md-flex flex-fill justify-content-md-center'>
						<Nav.Link as={Link} to='/' className='px-2 px-sm-2 px-lg-3 py-2'>
							Home
						</Nav.Link>
						<Nav.Link
							as={Link}
							to='/worksheets'
							className='px-2 px-sm-2 px-lg-3 py-2'>
							Worksheets
						</Nav.Link>
						<Nav.Link
							as={Link}
							// disabled
							to='/workbooks'
							className='px-2 px-sm-2 px-lg-3 py-2'>
							Workbooks
						</Nav.Link>
						<Nav.Link
							as={Link}
							to='/books'
							className='px-2 px-sm-2 px-lg-3 py-2'>
							Storybooks
						</Nav.Link>
						<Nav.Link
							as={Link}
							to='/pricing'
							className='px-2 px-sm-2 px-lg-3 py-2'>
							Pricing
						</Nav.Link>
						<Nav.Link as={Link} to='/faq' className='px-2 px-sm-2 px-lg-3 py-2'>
							FAQs
						</Nav.Link>

						<NavDropdown
							title='Demo'
							id='navbarScrollingDropdown'
							className='px-2 px-sm-2 px-lg-3'>
							{demoVideos.map((video, index) => {
								let textWrap = ''

								if (index && video.title.length >= 20) {
									textWrap = 'text-wrap'
								}

								return (
									<>
										<NavDropdown.Item
											key={index}
											className={textWrap}
											onClick={() => handleShownVideo(video.link)}>
											{video.title}
										</NavDropdown.Item>
										<NavDropdown.Divider />
									</>
								)
							})}
						</NavDropdown>

						<Nav.Link
							href='https://blog.nimdee.com'
							target='_blank'
							rel='noopener noreferrer'
							className='px-2 px-sm-2 px-lg-3 py-2'>
							Blog
						</Nav.Link>
					</Nav>
					<div className='px-2 px-sm-2 px-lg-3 py-2'>
						{Login || childLoginPage || Parentlogin ? (
							<Link to='/signup'>
								<Button title='Sign up' />
							</Link>
						) : (
							<Link to='/login'>
								<Button title='Log in' />
							</Link>
						)}
					</div>
				</Navbar.Collapse>
			</Navbar>

			<DemoVideos videoLink={videoLink} show={show} setShow={setShow} />
		</div>
	)
}

export default Header
