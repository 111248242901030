import React from 'react';
import Header from '../../layout/Header/Header';
import Footer from '../../layout/Footer';
import { FaqData } from './FaqData';
import Accordion from '../../Helpers/Accordion';

const Faq = () => {
  return (
    <div>
      <Header />
      <div className='container'>
        <h3 className='text-center mt-4 mb-4'>
          Read through our FAQs below. If you need further assistance,
          <a href='mailto:support@nimdee.com'> support@nimdee.com</a>
        </h3>
        <div className="accordion">
              {FaqData.map(({ title, list1, list2, list3, list4, list5, Ans1, Ans2, Ans3, Ans4, Ans5, list1sublist ,list2sublist ,list3sublist ,list4sublist ,list5sublist }) => (
                <Accordion title={title} list1sublist={list1sublist} list2sublist={list2sublist} list3sublist={list3sublist} list4sublist={list4sublist} list5sublist={list5sublist} list1={list1} list2={list2} list3={list3} list4={list4} list5={list5} Ans1={Ans1} Ans2={Ans2} Ans3={Ans3} Ans4={Ans4} Ans5={Ans5}/>
              ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faq;