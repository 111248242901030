import titi from '../../images/Titi-Adewusi.jpg';
import yeside from '../../images/Yeside-ogunremi.png';
import dayo from '../../images/Dayo-Amusu.jpg';
import fisayo from '../../images/fisayo.jpg';

export const members = [
    {
        name: 'Titi Adewusi',
        role: 'strategy and marketing',
        image: titi,
        about: 'Titi is a seasoned Management Consultant with over 18 years working experience. She started working in the banking sector before she joined one of the Big Four Multinational Audit and Consulting firm where she worked for over 10years providing business solutions to clients spanning a number industries – Telecom, Financial Services, Oil and Gas, etc. Titi rose to Senior Manager level before joining a multi million dollar fast food restaurant as a member of the Executive Management team. She founded Nimdee with two of her sisters with a vision of developing learning games and resources that make learning fun.'
    },
    {
        name: 'Yeside Ogunremi',
        role: 'game design and development',
        image: yeside,
        about: 'Yeside is a project management professional (PMP) based in Lagos Nigeria. She started out with IBFC Agusto Training and moved on to Accenture one of the leading global consulting firms and eventually became an entrepreneur. She has over 10 years consulting experience covering deployment of enterprise resource planning (ERP) solutions, process optimization and improvement, market analytics, performance management as well as training. She has worked with clients from different industries within and outside the country before going to start her own IT solutions company. She is one of the founding members of Nimdee.'
    },
    {
        name: 'Dayo Amusu',
        role: 'platform and marketplace',
        image: dayo,
        about: 'Dayo is a UK based Entrepreneur, Startup Mentor and Technology Advisor. She started her career as a Computer Engineer with International Computer Limited (ICL) in Nigeria before relocating to the UK. She has over 30 years of management experience spanning innovation, data analytics, artificial intelligence, digital transformation and product development with organisations such as Coca-Cola, ITV, South-Eastern Train and Network Rail. She is one of the founding members of Nimdee.'
    },
    {
        name: 'Fisayo Ogundipe',
        role: 'learning and development',
        image: fisayo,
        about: 'Fisayo heads the Science Department at a British school based in Dubai. She started her career in banking in Nigeria where she worked for over 7 years managing and providing tailored services for high net worth individuals before relocating to Dubai. She is keen about innovation and mentoring linked to teaching and learning which lead to her retraining to become a teacher and has risen in her career to head of science at her school. She mentors teacher trainees from universities in Dubai and also carries out in house staff training linked to innovation and best teaching practices.'
    },
    
]