// import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { orderByName, searchMyClasses, fetchSchool } from '../../../features/redux/actionsCreators/school';
import { InputGroup, FormControl, Table } from 'react-bootstrap';
import {Search, ArrowDropDown, ArrowDropUp,
    //  EditOutlined, DeleteOutlineOutlined, 
     ErrorOutline, Loop } from '@material-ui/icons';
import { ContentContainer, ContentWrapper, TopBanner, BannerText, SearchField, TableWrapper,
    //  ExportArea, ButtonContainer, 
     Alter, Notification, ClassesTable } from '../styles/styles'
import Pagination from '../TablePagination'
import '../styles/styles.css'

const Class = () => {

    const dispatch = useDispatch()
    const [ orderType, setOrderType ] = useState("ascending")
    const [ searchInput, setSearchInput ] = useState("")
    const { currentPage, itemsPerPage, activeMyClasses, myPupils, error, loading } = useSelector(state => state.school)
    
    useEffect(() => {
        dispatch(fetchSchool())
    }, [dispatch])

    const handleSearchInput = (e) => {
        setSearchInput(e.target.value)
        dispatch(searchMyClasses(e.target.value))
    }

    const handleSort = () => {
        dispatch(orderByName(orderType))
        orderType === "ascending" ?  setOrderType("descending") : setOrderType("ascending")
    }

    const indexOfLastItem = (currentPage * itemsPerPage) - 1;
    const indexOfFirstItem = indexOfLastItem - (itemsPerPage - 1);
    const currentMyClasses = activeMyClasses && activeMyClasses?.slice(indexOfFirstItem, (indexOfLastItem + 1))

    return (
        <ContentContainer
            initial={{opacity: 0.8}} 
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
        >
            <ContentWrapper>
                <TopBanner>
                    <BannerText>
                        <h4>CLASSES</h4>
                    </BannerText>
                </TopBanner>

                <TableWrapper>
                    <SearchField>
                        <InputGroup className="w-100">
                            <FormControl
                                placeholder="Search classes by name..."
                                aria-label="SearchPerson"
                                aria-describedby="search-icon"
                                value={searchInput}
                                onChange={ handleSearchInput }
                                className="border border-dark"
                            />
                            <InputGroup.Text id="search-icon"><Search /></InputGroup.Text>
                        </InputGroup>
                    </SearchField>

                    
                    {/* <ExportArea>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                Export/Download
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => exportToXlsx(activeMyClasses, "My Classes")}> Export as XLSX </Dropdown.Item>
                                <Dropdown.Item> Export as PDF </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ExportArea> */}

                    <ClassesTable>
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th style={{cursor: "pointer" }} onClick={handleSort} > CLASS NAME { orderType === "ascending" ?  <ArrowDropUp  /> : <ArrowDropDown /> }</th>
                                    <th>DESCRIPTION</th>
                                    <th># OF PUPILS</th>
                                    <th>TEACHER</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(currentMyClasses) && currentMyClasses.length > 0 && currentMyClasses?.map((singleClass, index) => {
                                    return (
                                        <tr key={ index }>
                                        <td> { singleClass.class_name }</td>
                                        <td> { singleClass.class_description }</td>
                                        <td style={{textAlign: "center"}}> {Array.isArray(myPupils) ? myPupils.length : "none" } </td>
                                        <td>
                                                { singleClass.teacher_name ? singleClass.teacher_name : <p style={{color: "brown"}}>Please add a teacher to this class</p>}
                                                <Alter>
                                                    {/* {
                                                        singleClass.teacher_name && 
                                                        <Button variant="outline-primary">
                                                            Print letters
                                                            <CloudDownload style={{marginLeft: "10px"}} />
                                                        </Button>
                                                    } */}
                                                    {/* <IconButton>
                                                        <EditOutlined />
                                                    </IconButton>
                                                    <IconButton>
                                                        <DeleteOutlineOutlined />
                                                    </IconButton> */}
                                                </Alter>

                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </ClassesTable>

                    {
                        !Array.isArray(currentMyClasses) &&
                        <Notification> 
                            <ErrorOutline /> <p> NO DATA FOUND, ADD SINGLE ITEMS OR USE THE BULK UPLOAD </p> 
                        </Notification>
                    }

                    {
                        loading && 
                        <Notification> 
                            <Loop /> <p> LOADING DATA... PLEASE WAIT A SECOND </p> 
                        </Notification> 
                    }

                    {
                        error && 
                        <Notification> 
                            <ErrorOutline /> <p>{error}</p> 
                        </Notification>
                    }

                    { 
                        activeMyClasses?.length === 0 && 
                        <Notification> 
                            <ErrorOutline /> <p>OOPS!!! NO MATCHES FOR YOUR SEARCH</p> 
                        </Notification>
                    }

                    {
                        Array.isArray(activeMyClasses) && activeMyClasses?.length ? <Pagination people={ activeMyClasses } /> : ""
                    }
                    
                </TableWrapper>
            </ContentWrapper>
        </ContentContainer>
    )
}

export default Class