import { useDispatch } from 'react-redux'
import { Modal, Button as BootstrapButton } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { fetchSchool } from '../../../features/redux/actionsCreators/school'
import { Input } from '../../../components/TextField/ParentsTextfield'
import { SuccessToast, ErrorToast } from '../../../components/Toast/Toast'
import Button from '../../../components/Button/Button'
import * as Yup from 'yup'
import '../styles/styles.css'
import axios from 'axios'
import { config } from '../../../Constant'

function AddTeacher(props) {
	const {
		isEditing,
		isEditedTeacher,
		// previousEditedTeacher,
		// setIsEditedTeacher,
		setModalShow,
	} = props
	const URL = `${config.url.API_URL}/AdminDashboard/admindashboard.php`
	const dispatch = useDispatch()

	const validate = Yup.object({
		first_name: Yup.string()
			.max(34, 'Must be 34 characters or less')
			.required('Name is required'),
		last_name: Yup.string()
			.max(34, 'Must be 34 characters or less')
			.required('Name is required'),
		email: Yup.string().email('Email is invalid').required('Email is required'),
	})

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header className="bg-grey">
				<Modal.Title id="contained-modal-title-vcenter">
					{isEditing ? 'EDIT TEACHER' : 'ADD TEACHER'}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Formik
					initialValues={{
						first_name: isEditing
							? isEditedTeacher.teacher_name.split(' ')[0]
							: '',
						last_name: isEditing
							? isEditedTeacher.teacher_name.split(' ')[1]
							: '',
						email: isEditing ? isEditedTeacher.teacher_email : '',
					}}
					validationSchema={validate}
					onSubmit={(values) => {
						console.log(values)
						const login_token = JSON.parse(
							sessionStorage.getItem('login_token')
						)
						values.login_token = login_token

						if (!isEditing) {
							values.teacher_id = null
							axios
								.post(`${URL}/addSingleTeacher`, values)
								.then((res) => {
									dispatch(fetchSchool())
									SuccessToast(res.data.message)
								})
								.catch((error) => {
									console.log(error.response)
									ErrorToast(
										error.response && error.response.data.message
											? error.response.data.message
											: error.message
									)
								})
						} else {

              values.teacher_id = isEditedTeacher.teacher_id

              // if(isEditedTeacher.teacher_name.split(' ')[0] === previousEditedTeacher.teacher_name.split(' ')[0] && isEditedTeacher.teacher_name.split(' ')[1] === previousEditedTeacher.teacher_name.split(' ')[1] && isEditedTeacher.teacher_email === previousEditedTeacher.teacher_email){
              //     ErrorToast("Nothing has changed")
              //     return null
              // }

                axios
                  .post(`${URL}/addSingleTeacher`, values)
                  .then((res) => {
                    dispatch(fetchSchool())
                    SuccessToast(res.data.message)
                  })
                  .catch((error) => {
                    console.log(error.response)
                    ErrorToast(
                      error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message
                    )
                  })
						}
						setModalShow(false)
					}}
				>
					{(formik) => (
						<div>
							<h5>
								{' '}
								{props.isEditing
									? "Edit teacher's details"
									: "Complete teacher's details"}
							</h5>
							<Form className="my-2">
								<Input
									label="First Name"
									name="first_name"
									type="text"
									placeholder="Enter teacher's first name"
								/>

								<div className="my-3"></div>

								<Input
									label="Last Name"
									name="last_name"
									type="text"
									placeholder="Enter teacher's last name"
								/>

								<div className="my-3"></div>

								<Input
									label="Email Address"
									name="email"
									type="email"
									placeholder="Enter teacher's school mail address"
								/>

								<div className="my-3"></div>

								<Button type="submit" className="py-2 px-5 mt-2" title="Save" />
							</Form>
						</div>
					)}
				</Formik>
			</Modal.Body>
			<Modal.Footer className="bg-grey">
				<BootstrapButton
					variant="outline-dark"
					onClick={props.onHide}
					className="py-2 px-5"
					style={{ borderRadius: '20px' }}
				>
					Cancel
				</BootstrapButton>
			</Modal.Footer>
		</Modal>
	)
}

export default AddTeacher
