export const FaqData = [
    {
        title: 'GENERAL QUESTIONS',
        list1: 'What is Nimdee and how do I sign up?', 
        Ans1: `Nimdee is an education technology business whose mission is to deliver fun learning resources to everyone. New to Nimdee? It's so easy to join us. Create a free account and you'll have instant access to all of our free resources. Take your time and have a look around. We've got a great range of taster packs to give you a flavour of our resources. Want to unlock all our great content? Sign up for a subscription. Once your payment has been received and processed, we will convert your account to premium and you can begin enjoying all our content.`,
        list2: 'I am not writing any WAEC, JAMB, NECO, BECE, or any regional or national exams. Is Nimdee still suitable for me?',
        Ans2: `Absolutely! While Nimdee’s vast library of national and regional past question papers and solutions makes it an ideal product in helping to prepare a learner for critical exams, our service is much more than a test preparation tool. Our thousands of curriculum relevant video tutorials and live lessons are designed to provide learners with a better and richer learning experience by personalising the way they learn and making the process fun and enriching.`,
    },
    {
        title: 'PRODUCT / SERVICES QUESTIONS',
        list1: `How much for a Nimdee subscription?`,
        Ans1: `We have got a range of subscriptions, so you will be sure to find the right package for you. Our subscription levels are Free, Monthly and our best value Annual. With the paid subscriptions (Monthly and Annual), will have access to all the resources available for up to 4 child profiles and year groups.
        
        You can see the latest prices and purchase your subscription
        
        Sign up to the free plan to download 2 worksheets from over 300 resources available, so there is lots to discover.

        We also offer free resources in our weekly newsletter and competitions as long as you are subscribed to our email alerts.`,

        list2: `What are the benefits of using Nimdee? There are many benefits to using Nimdee, key amongst these are:`,
        list1sublist: `Access to thousands of high-quality curriculum-relevant resources to help a learner grasp critical - and sometimes complex – concepts in a fun and memorable way;`,
        list2sublist: `Access to live lessons where students can engage in real-time with students and teachers;`,
        list3sublist: `The ability of a learner to learn at his/her own pace using the curriculum map;`,
        list4sublist: `Rich data analytics that allow a learner, as well as his/her parents/guardians, to monitor and assess progress across subjects and/or specific topics;`,
        list5sublist: `A fun and exciting new way to learn, which complements more traditional forms of teaching.`,

        list3: `Do I need a tablet to use Nimdee?`,
        Ans3: `You can use any desktop or laptop which runs on Windows or a tablet with a browser.`,
        list4: `Does using the Nimdee app mean I have to use my data?`,
        Ans4: `You need internet connectivity to use Nimdee online interactive activities`,
        list5: `What methods of payments does Nimdee accept?`,
        Ans5: `You can pay for your Nimdee subscription via the following methods - Debit/Credit card on Nimdee’s website (Nimdee.com)`
    },
    {
        title: 'ACCOUNT MANAGEMENT QUESTIONS',
        list1: 'How do I confirm that my online payment was received and processed?',
        Ans1: `If you paid online or in the app with your debit or credit card, your subscription will be automatically activated. If, however, you choose to pay via a bank transfer, your subscription will be activated as soon as your payment has cleared. If your subscription has not been automatically activated and/or you want to confirm the status of your online payment, please email support@Nimdee.com to inquire and one of our representatives will be able to help you.`,

        list2: 'Why has my account not been upgraded?',
        Ans2: `All accounts are automatically upgraded following payment confirmation. If after 24 hours of payment, your account is still not upgraded, please contact our customer service department by email on support@Nimdee.com, and we will look into this request for you.`,

        list3: 'Can I cancel my subscription and get a refund?',
        Ans3: `You are entitled to a refund/return request within 7 days. After the expiry of this period, any refund/return request will not be processed. If you are still within the allowed refund/return request window, contact our customer service department by email on support@Nimdee.com and we will process this request immediately. Please allow five (5) business days for your money to be returned to you.`,

        list4: 'Can I change my phone number?',
        Ans4: `To change your phone number, go to the Dashboard to change your mobile details.`
    },
    {
        title: 'RECURRING PAYMENTS',
        list1: 'How do I cancel my installment plan?',
        Ans1: `You currently can not cancel your installment plan. Subscribing to this plan means you are signing up for an annual plan (12 months) with the option to pay monthly, in installments. Opt-ing out of the plan means you will lose access to your subscription and lose all the extra benefits including the data gifting and the extra 1 month at the end of your subscription. If you still want to opt-out, kindly send an email to our customer service team at support@Nimdee.com.`,

        list2: 'How long will I be charged for the monthly installment plan?',
        Ans2: `12 months.`,

        list3: 'Which payment methods are available for the installmental plan?',
        Ans3: `Debit/Credit card`
    },
]