import styled from "styled-components"

export const SelectPaymentContainer = styled.div`
    width: 95vw;
    max-width:  ${props => props.upgradePlan ? "1000px" : "1400px"};
    margin: 0 auto;
    margin-bottom: 150px;

    > h2 {
        text-align: center;
        margin: 30px 0;
        text-transform: uppercase;
        font-family: 'Russo One', sans-serif;
        font-size: 24px;
    }

    > p {
        text-align: center;
        font-size: 16px;
        width: 95%;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    > a {
        text-decoration: none;
    }

    @media screen and (min-width: 530px){
        width: 90vw;

        > h2 {
                font-size: 30px;
        }

        > p {
                font-size: 22px;
                width: 80%;
        }
    }

    @media screen and (min-width: 590px){
        width: 65vw;
    }

    @media screen and (min-width: 900px){
        width: 95vw;
    }
`

export const TogglerContainer = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    > span {
        font-size: 25px;
        font-weight: 900;
        margin: 0 20px
    }

    > .MuiSvgIcon-root {
        font-size: 70px;
    }

    @media screen and (min-width: 530px){
        > span {
            font-size: 33px;
        }

        > .MuiSvgIcon-root {
            font-size: 95px;
        }
    }
`

export const SubscriptionPlansWrapper = styled.div`

    /* @media screen and (min-width: 900px){
        display: grid;
        gap: 25px;
        grid-template-columns: ${props => props.upgradePlan ? "repeat(2, 1fr)" : "repeat(3, 1fr)"};
    } */

    @media screen and (min-width: 900px){
        display: grid;
        gap: 25px;
        grid-template-columns: ${props => props.upgradePlan ? "repeat(2, 1fr)" : "repeat(2, 1fr)"};
    }

    @media screen and (min-width: 1200px){
        grid-template-columns: ${props => props.upgradePlan ? "repeat(2, 1fr)" : "repeat(3, 1fr)"};
        padding: 0 30px;
    }
`

export const Button = styled.button`
    border-radius: 30px;
    border: none;
    padding: 20px 30px;
    margin: 50px 0;
    background-color: #EF4871;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
    font-weight: 900;
    font-size: 20px;
    text-transform: capitalize;

    :hover {
        background-color: #CD4871;
    }
`