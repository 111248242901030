import styled from 'styled-components'

export const PaginationContainer = styled.div`
    margin: 20px auto;
    display: flex;
    align-items: center;
    width: fit-content;

    > ul {
        list-style: none;
        flex-wrap: wrap;
        margin: 5px;

        > li {
            border: 1px solid #222;
            border-radius: 3px;
            color: #222;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-right: 3px;
            font-size: 12px;
        }
    }

    > button {
        display: flex;
        text-transform: capitalize;
        border: none;
        outline: none;
        background-color: transparent;

        :disabled {
            color: #bbb !important;
            background-color: transparent !important;
        }

        @media screen and (min-width: 700px){
            font-size: 13px !important;
            padding: 12px 20px !important;
        }
    }
`

export const ScreenPagination = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 900px){
        /* display: none; */
    }
`

export const WideScreenPagination = styled.ul`
    display: none;
    list-style: none;
    flex-wrap: wrap;

    @media screen and (min-width: 900px){
        display: flex;
        align-items: center;
        justify-content: center;
    }
`