import styled from 'styled-components'

export const FooterContainer = styled.div`
    background-color: #363561;
    padding: 70px 10px;
`
export const FooterColumns = styled.div`
    /* padding: 30px 0; */
    width: 90vw;
    max-width: 1300px;
    margin: 0 auto;
    margin-top: 30px;
    display: grid;
    gap: 10px;

    > ul {
        list-style-type: none;
        padding: 0;
        color: white;
        padding: 10px;

        > h3 {
           font-size: 14px;
           font-weight: 600;
           text-transform: uppercase;
        }

        > li > a {
            color: white;
            text-decoration: none;
            text-transform: capitalize;
            font-size: 14px;
        }
    }

    @media screen and (min-width: 600px){
        grid-template-columns: repeat(2, 1fr);

        > ul {
            > h3, li {
                font-size: 16px;
            }
        }
    }

    
    @media screen and (min-width: 1000px){
        grid-template-columns: repeat(4, 1fr);

        > ul {
            /* justify-self: center; */
        }

        /* > ul {
            > h3, li {
                font-size: 20px;
            }
        } */
    }
`