import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	Details: [],
}

const ParentDashboardSlice = createSlice({
	name: 'childrenDetails',
	initialState,
	reducers: {
		saveChildrenDetails: (state, { payload }) => {
			state.Details = payload
		},
	},
})

export const { saveChildrenDetails } = ParentDashboardSlice.actions
export const details = (state) => state.childrenDetails.Details
export default ParentDashboardSlice.reducer
