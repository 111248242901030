import * as ACTION from '../../constants/school'
import * as PAGINATION from '../../constants/pagination'

const sortDynamically = (property,order) => {
    return function (a, b){
        // a should come before b in the sorted order
        if(order === "ascending" && (a[property] < b[property])){
            return -1;
        } 
        else if (order === "ascending" && (a[property] > b[property])){
            return 1;
        }
        // a should come after b in the sorted order
        else if(order === "descending" && (a[property] > b[property])){
            return -1;
        } 
        else if (order === "descending" && (a[property] < b[property])){
            return 1;
        }
        // a and b are the same
        else {
            return 0;
        }
    }
}

const initialState = {
    dashboard: {},
    pupils: [],
    allTeachers: [],
    allClasses: [],
    myPupils: [],
    myClasses: [],
    loading: true,
    currentPage: 1,
    itemsPerPage: 15
}

const schoolReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case ACTION.FETCH_LOADING:
            return { ...state }


        case ACTION.FETCH_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                dashboard: payload
            }
        
        case ACTION.FETCH_ALL_TEACHERS_SUCCESS:
            return {
                ...state,
                loading: false,
                allTeachers: payload,
                activeAllTeachers: payload,
                currentPage: 1,
            }

            
        case ACTION.FETCH_ALL_PUPILS_SUCCESS:
            return {
                ...state,
                loading: false,
                allPupils: payload,
                activeAllPupils: payload,
                currentPage: 1,
            }

        case ACTION.FETCH_ALL_CLASSES_SUCCESS:
            return {
                ...state,
                loading: false,
                allClasses: payload,
                activeAllClasses: payload,
                currentPage: 1,
            }

        case ACTION.FETCH_MY_CLASSES_SUCCESS:
            return {
                ...state,
                loading: false,
                myClasses: payload,
                activeMyClasses: payload,
                currentPage: 1,
            }

        case ACTION.FETCH_MY_PUPILS_SUCCESS:
            return {
                ...state,
                loading: false,
                myPupils: payload,
                activeMyPupils: payload,
                currentPage: 1,
            }
    
        case ACTION.FETCH_FAILED:
            return {
                loading: false,
                error: payload
            }

        case ACTION.SEARCH_ALL_TEACHERS_BY_NAME:
            let searchedAllTeachers = Array.isArray(state.allTeachers) && state.allTeachers?.filter(teacher => teacher.teacher_name.toLowerCase().includes(payload.toLowerCase()));

            return {
                ...state,
                activeAllTeachers: searchedAllTeachers,
                currentPage: 1
            }
        
        case ACTION.SEARCH_ALL_PUPILS_BY_NAME:
            let searchedAllPupils = Array.isArray(state.allPupils) && state.allPupils?.filter(pupil => pupil.student_name.toLowerCase().includes(payload.toLowerCase()));

            return {
                ...state,
                activeAllPupils: searchedAllPupils,
                currentPage: 1
            }

        case ACTION.SEARCH_ALL_CLASSES_BY_NAME:
            let searchedAllClasses = Array.isArray(state.allClasses) && state.allClasses?.filter(singleClass => singleClass.class_name.toLowerCase().includes(payload.toLowerCase()) || singleClass.teacher_name.toLowerCase().includes(payload.toLowerCase()));

            return {
                ...state,
                activeAllClasses: searchedAllClasses,
                currentPage: 1
            }


        case ACTION.SEARCH_MY_PUPILS_BY_NAME:
            let searchedMyPupils = Array.isArray(state.myPupils) && state.myPupils?.filter(pupil => pupil.student_name.toLowerCase().includes(payload.toLowerCase()));

            return {
                ...state,
                activeMyPupils: searchedMyPupils,
                currentPage: 1
            }

        case ACTION.SEARCH_MY_CLASSES_BY_NAME:
            let searchedMyClasses = Array.isArray(state.myClasses) && state.myClasses?.filter(singleClass => singleClass.class_name.toLowerCase().includes(payload.toLowerCase()) || singleClass.teacher_name.toLowerCase().includes(payload.toLowerCase()));

            return {
                ...state,
                activeMyClasses: searchedMyClasses,
                currentPage: 1
            }
        

        case ACTION.SORT_ITEMS_BY_NAME_ORDER:
            let sortedAllPupilsByName = Array.isArray(state.allPupils) && state.allPupils?.sort(sortDynamically("student_name", payload.toLowerCase()))
            let sortedAllTeachersByName = Array.isArray(state.allTeachers) && state.allTeachers?.sort(sortDynamically("teacher_name", payload.toLowerCase()))
            let sortedAllClassesByName = Array.isArray(state.allClasses) && state.allClasses?.sort(sortDynamically("class_name", payload.toLowerCase()))
            let sortedMyPupilsByName = Array.isArray(state.myPupils) && state.myPupils?.sort(sortDynamically("student_name", payload.toLowerCase()))
            let sortedMyClassesByName = Array.isArray(state.myClasses) && state.myClasses?.sort(sortDynamically("class_name", payload.toLowerCase()))

            return {
                ...state,
                activeAllPupils: sortedAllPupilsByName,
                activeAllTeachers: sortedAllTeachersByName,
                activeAllClasses: sortedAllClassesByName,
                activeMyPupils: sortedMyPupilsByName,
                activeMyClasses: sortedMyClassesByName

            };
        
        //PAGINATION CONCERNS
        case PAGINATION.NEXT_PAGE:
            return {
                ...state,
                currentPage: state.currentPage + 1
            }

        case PAGINATION.PREVIOUS_PAGE:
            return {
                ...state,
                currentPage: state.currentPage - 1
            }
    
        default:
            return state
    }
}

export default schoolReducer