import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css'

toast.configure();

export const SuccessToast = (data) => {
    return (
      <>
        { Array.isArray(data) ?
          data.map((item)=> {
            return (
              toast.success(item.toUpperCase(), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                className: 'toast_success_override',
              })
            )
          })
             : 
            toast.success(data.toUpperCase(), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            className: 'toast_success_override',
          })
        }
      </>
    )
}

export const ErrorToast = (data) => {
    return (
      <>
      { Array.isArray(data) ?
        data.map((item)=> {
          return (
            toast.error(item.toUpperCase(), {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              className: "toast_error_override"
            })
          )
        })
           : 
          toast.error(data.toUpperCase(), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          className: "toast_error_override"
        })
      }
    </>
    )
}