import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { orderByName, searchMyPupils, fetchSchool } from '../../../features/redux/actionsCreators/school';
import { InputGroup, FormControl, Table } from 'react-bootstrap';
import { FileCopyOutlined, DeleteOutlineOutlined, EditOutlined, Search, DoneAll, Add, ArrowDropUp, ArrowDropDown, ErrorOutline, Loop } from '@material-ui/icons';
import { ContentContainer, ContentWrapper, TopBanner, BannerText, SearchField, ExportArea, TableWrapper, MyPupilsTable,
    // ButtonContainer,
Alter, Notification } from '../styles/styles'
import IconButton from '@material-ui/core/IconButton';
import Pagination from '../TablePagination'
import CopyToClipboard from 'react-copy-to-clipboard';
import AddPupil from './AddPupil';
import '../styles/styles.css'
import { exportTableAsCSVButton, exportDataAsCSV } from '../../../Helpers/ExportSchoolData';
import { onDelete } from '../../../Helpers/DeleteSchoolItem'

const Pupils = () => {

    // const URL = `${config.url.API_URL}/AdminDashboard/admindashboard.php`
    const dispatch = useDispatch()
    const [ orderType, setOrderType ] = useState("ascending")
    const [ modalShow, setModalShow ] = useState(false)
    const [ isEditing, setIsEditing ] = useState(false)
    const [ searchInput, setSearchInput ] = useState("")
    const [ isCopied, setIsCopied ] = useState(false)
    const [ copiedId, setCopiedId ] = useState(0)
    const [ assignedClass, setAssignedClass ] = useState("")
    const [ isEditedPupil, setIsEditedPupil ] = useState({})
    const { currentPage, itemsPerPage, activeMyPupils, error, loading } = useSelector(state => state.school)

    useEffect(() => {
        dispatch(fetchSchool())
    }, [dispatch])

    const onCopyText = (val) => {
        setCopiedId(val)
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    };

    const onEdit = (student_id, student_name, class_id, teacher_name, class_description) => {
        setModalShow(true)
        setIsEditing(true)
        setIsEditedPupil({
            student_id, 
            student_name,
            class_id,
            teacher_name,
            class_description
        }) 
    }

    const handleSearchInput = (e) => {
        setSearchInput(e.target.value)
        dispatch(searchMyPupils(e.target.value))
    }
    
    const handleSort = () => {
        dispatch(orderByName(orderType))
        orderType === "ascending" ?  setOrderType("descending") : setOrderType("ascending")
    }

    const indexOfLastItem = (currentPage * itemsPerPage) - 1;
    const indexOfFirstItem = indexOfLastItem - (itemsPerPage - 1);
    const currentMyPupils = activeMyPupils && activeMyPupils?.slice(indexOfFirstItem, (indexOfLastItem + 1))

    return (
        <ContentContainer
            initial={{opacity: 0.8}} 
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
        >
            <ContentWrapper>
                <TopBanner>
                    <BannerText>
                        <h4>PUPILS</h4>
                        <button onClick={() => {
                            setModalShow(true)
                            setIsEditing(false)
                            }}
                        > 
                                <Add /> 
                            <span>add pupil</span>               
                        </button>
                    </BannerText>
                    
                </TopBanner>

                <TableWrapper>
                    <SearchField>
                        <InputGroup className="w-100">
                            <FormControl
                                placeholder="Search pupils by name..."
                                aria-label="SearchPerson"
                                aria-describedby="search-icon"
                                value={searchInput}
                                onChange={ handleSearchInput }
                                className="border border-dark"
                            />
                            <InputGroup.Text id="seacrh-icon"><Search /></InputGroup.Text>
                        </InputGroup>
                    </SearchField>

                    <ExportArea>
                        {exportTableAsCSVButton(activeMyPupils, exportDataAsCSV(activeMyPupils, "activeMyPupils"), "MY_PUPILS.csv")} 
                    </ExportArea>

                    <MyPupilsTable>
                        <Table responsive bordered hover>
                            <thead>
                                <tr>
                                    <th style={{cursor: "pointer" }} onClick={handleSort} > NAME { orderType === "ascending" ?  <ArrowDropUp /> : <ArrowDropDown /> }</th>
                                    <th>LOGIN CODE</th>
                                    <th>POINTS</th>
                                    <th>LAST ACTIVITY</th>
                                    <th>CREATED AT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(currentMyPupils) && currentMyPupils.length && currentMyPupils?.map((pupil, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{pupil.student_name}</td>
                                            <td>
                                                <CopyToClipboard text={pupil.accesscode} onCopy={() => onCopyText(index)}>
                                                    <div className="copy-area">
                                                        <IconButton>
                                                            <FileCopyOutlined />
                                                        </IconButton>
                                                        <span className={`copy-feedback ${isCopied && index === copiedId ? "active" : ""}`}>
                                                            <DoneAll /> Copied
                                                        </span>
                                                    </div>
                                                </CopyToClipboard>
                                                {pupil.accesscode}
                                            </td>
                                            <td> 0 points</td>
                                            <td> {pupil.last_activity && pupil.last_activity !== null ? pupil.last_activity : "No activity yet"} </td>
                                            <td>
                                                2 years ago
                                                <Alter>
                                                    <IconButton onClick={() => onEdit(pupil.student_id, pupil.student_name, pupil.class_id, pupil.teacher_name, pupil.class_description)}>
                                                        <EditOutlined/>
                                                    </IconButton>
                                                    <IconButton onClick={() => onDelete("pupil", pupil.student_id, pupil.student_name, "deleteSinglePupil", () => dispatch(fetchSchool()))}>
                                                        <DeleteOutlineOutlined />
                                                    </IconButton>
                                                </Alter>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </MyPupilsTable>

                    {
                        !Array.isArray(currentMyPupils) &&
                        <Notification> 
                            <ErrorOutline /> <p> NO DATA FOUND, ADD SINGLE ITEMS OR USE THE BULK UPLOAD </p> 
                        </Notification>
                    }

                    {
                        loading && 
                        <Notification> 
                            <Loop /> <p> LOADING DATA... PLEASE WAIT A SECOND </p> 
                        </Notification> 
                    }

                    {
                        error && 
                        <Notification> 
                            <ErrorOutline /> <p>{error}</p> 
                        </Notification>
                    }

                    {
                        activeMyPupils?.length === 0 && 
                        <Notification> 
                            <ErrorOutline /> <p>OOPS!!! NO MATCHES FOR YOUR SEARCH</p> 
                        </Notification>
                    }

                    {
                        Array.isArray(activeMyPupils) && activeMyPupils?.length ? <Pagination people={activeMyPupils} /> : ""
                    }
                    
                </TableWrapper>
            </ContentWrapper>

            <AddPupil
                show={modalShow}
                isEditing={isEditing}
                assignedClass={assignedClass}
                setAssignedClass={setAssignedClass}
                isEditedPupil={isEditedPupil}
                setIsEditedPupil={setIsEditedPupil}
                setModalShow={setModalShow}
                onHide={() => {
                    setModalShow(false)
                    setAssignedClass("")
                }}
            />

        </ContentContainer>
    )
}

export default Pupils