import { PaginationContainer, ScreenPagination } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { nextPage, previousPage, pageNumberClick } from '../../../features/redux/actionsCreators/pagination'
import { ChevronRightOutlined, ChevronLeftOutlined } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton';
import '../styles/styles.css'
import { styles } from '../styles/styles'

const Pagination = ({people}) => {

    const dispatch = useDispatch()
    const { currentPage, itemsPerPage } = useSelector(state => state.school)

    const pages = [];
    for (let i = 1; i <= Math.ceil(people?.length / itemsPerPage); i++) {
        pages.push(i);
    }

    const handlePageNumberClick = (e) => {
        dispatch(pageNumberClick(Number(e.target.id)))
    };

    const handleNextbtn = () => {
        dispatch(nextPage())
    };
    
    const handlePrevbtn = () => {
        dispatch(previousPage())
    };

    return (
        <>
        <PaginationContainer>

            < IconButton onClick={handlePrevbtn} disabled={currentPage === pages[0] ? true : false}>
                <ChevronLeftOutlined style={{fontSize: "30px"}} />
            </IconButton>

            <ScreenPagination>
                <li>{currentPage}</li>
                <li style={styles.unStyledPageListItem}>of</li>
                <li style={styles.unStyledPageListItem} id={pages.length} onClick={handlePageNumberClick}>{pages.length}</li>
            </ScreenPagination>

            < IconButton onClick={handleNextbtn} disabled={currentPage === pages.length ? true : false}>
                <ChevronRightOutlined style={{fontSize: "30px"}} />
            </IconButton>
           
        </PaginationContainer>

        </>
    )
}

export default Pagination