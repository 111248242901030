import styled from "styled-components"

export const SinglePricingCardContainer = styled.div`
    margin: 40px auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 3px solid white;

    @media screen and (min-width: 900px){
        margin: 0 auto;
    }
`

export const SinglePricingHeading = styled.div`
    text-align: center;
    color: white;
    padding: 50px 0;
    padding-bottom: 70px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 14.3rem;

    > h2, h3 {
        margin: 0;
        font-weight: 900;
        font-size: 23px;
    }

    > h2 {
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media screen and (min-width: 530px){
        > h2, h3 {
            font-size: 28px;
        }
    }
`

export const SinglePricingImage = styled.div`
    text-align: center;
    border: 15px solid;
    border-radius: 50%;
    background-color: white;
    height: 120px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    > img {
        height: 65%;
        width: 65%;
        object-fit: contain;
    }

    @media screen and (min-width: 530px){
        height: 150px;
        width: 150px;
    }
`

export const SinglePricingList = styled.ul`
    list-style-type: none;
    width: 85%;
    margin: 0 auto;
    padding: 20px 0;
    padding-bottom: 50px;

    > li {
        padding: 7px 0;
        user-select:none;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;

        > img {
            margin-right: 20px;
        }
    }

    @media screen and (min-width: 530px){
       > li {
            font-size: 16px;
       }
    }
`

export const SinglePricingDetails = styled.div`
    position: relative;
    margin: 0;
    -webkit-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
    -khtml-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);

   > div:first-of-type {
        position: relative;
        height: 100px;
      
        ::after {
            content: "";
            position: absolute;
            border-top-left-radius: 95% 100%;
            border-top-right-radius: 95% 100%;
            width: 100%;
            height: 85%;
            bottom: 0;
            left: 0;
            background-color: white;
            z-index: 1;
        }
    }
`

export const SinglePricingButton = styled.div`
    padding: 20px;
    color: white;
    text-transform: capitalize;
    text-align: center;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content:  center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    user-select: none;
    font-weight: 900;
    font-size: 16px;
    transition: 250ms cubic-bezier(0.39, 0.575, 0.565, 1);
    border: 3px solid white;
    transform: scale(1.04);

    :hover {
        -webkit-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
        -khtml-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
    }

    @media screen and (min-width: 530px){
        font-size: 20px;
    }
`