import React, { useState } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import { Input } from "../TextField/ParentsTextfield";
import "./ParentsLogin.css";
import Header from "../../layout/Header/Header";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import Forgotpassword from "../ForgotPassword/Forgotpassword";
import LoadingModal from "../../layout/LoadingModal/LoadingModal";
import { config } from '../../Constant'
import Button from '../../components/Button/Button'
import {SuccessToast, ErrorToast} from '../Toast/Toast';

const Parentslogin = (props) => {

	var url = config.url.API_URL
	const history = useHistory()
	const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
	const [modalShow, setModalShow] = useState(false);

	const validate = Yup.object({
		email: Yup.string()
			.email("Email is invalid")
			.required("Email cannot be blank"),
		password: Yup.string()
			.min(6, "Password must have at least 6 characters")
			.required("Password cannot be blank"),
	});

	return (
		<Formik
			initialValues={{
				email: "",
				password: "",
			}}
			validationSchema={validate}
			onSubmit={(values) => {
				setModalShow(true);
				axios
					.post(
						`${url}/Auth/auth.php/parentslogin`,
						values
					)
					.then((res) => {
						setModalShow(false);
						if (res.status === 200) {
							SuccessToast(res.data.message)
							if (res.data.role === 'Parent') {
								sessionStorage.setItem(
									"user",
									JSON.stringify(res.data.data)
								);
								if (res.data.haschild) {
									history.push("/parentDashboard");
								} else {
									history.push("/childprofile");
								}
							}
							else if (res.data.role === 'School admin' || 'Teacher') {
								sessionStorage.setItem(
									"login_token",
									JSON.stringify(res.data.login_token)
								);
								sessionStorage.setItem(
									"teacherRole",
									JSON.stringify(res.data.role)
								);
								window.location.replace("/school-and-teacher");
								// history.push("/school-and-teacher");
							}
							
						} else {
							ErrorToast(res.data.error);
						}
					})
					.catch(function (error) {
						setModalShow(false);
						if (error.response) {
							ErrorToast(error.response.data.error);
						} else {
							ErrorToast("An error occurred")
						}
						// console.log(error.response);
					});
			}}
		>
			{(formik) => (
				<div>
					<Forgotpassword
						show={forgotPasswordModal}
						onHide={() => setForgotPasswordModal(false)}
					/>
					<Header Parentlogin="Parentlogin" />
					<LoadingModal
						show={modalShow}
						onHide={() => setModalShow(false)}
					/>
					<div className="container justify-content-center h-100">
						<div className="container">
							<div className="login-image container mt-5">
								<div className="row">
									<div className="col lola">
										<img
											className="mx-auto d-block text-center pt-4 img-fluid"
											src="../../../images/login-img-lola.png"
											alt=""
										/>
									</div>
									<div className="col align-items-center justify-content-center second-login">
										<h3 className="text-center mt-5">
											PLEASE LOGIN
										</h3>
										<Form className="center-form mt-5">
											<div className="mb-4">
												<Input
													label="Email"
													name="email"
													id="form-input-2"
													type="email"
													placeholder="Please enter your email"
												/>
											</div>
											<div className="mb-4">
												<Input
													label="Password"
													name="password"
													type="password"
													id="form-input-2"
													placeholder="Please enter your password"
												/>
												{/* <Link
													href="true"
													data-toggle="modal"
													data-target="#forgotpassword"
													className="text-danger pb-4 forgotpasswordBtn"
													onClick={() =>
														setForgotPasswordModal(
															true
														)
													}
												>
													forgot password?
												</Link> */}
											</div>
											<div className="button-div m-auto text-center">
												<Link to="/pricing" className="mr-2">
													<Button className="btn mt-3" type="button" title="Back"></Button>
												</Link>
												<Button className="btn mt-3" type="submit" title="Login"></Button>
											</div>

											<div className="my-4 text-center">
												Forgot your password?,&nbsp;
												<Link
													href="true"
													data-toggle="modal"
													data-target="#forgotpassword"
													className="text-danger pb-4"
													onClick={() =>
														setForgotPasswordModal(
															true
														)
													}
												>
													Click here 
												</Link>
												&nbsp;to resolve
											</div>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row mt-4"></div>
				</div>
			)}
		</Formik>
	);
};

export default Parentslogin;