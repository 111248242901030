import {useState} from 'react'
import { Modal } from "react-bootstrap";
import Button from '../../components/Button/Button'
// import AnimalsAndTheirHomes from "../../images/03-01-02-01-Animals and their homes_185x185.svg";
// import Pronouns from "../../images/Christ-is-born.jpeg";
// import CountingIn10s from "../../images/Christmas-Carol.jpeg";
// import savingImage from "../../images/Spot-the-difference.jpeg";
import LolaAndTheWiseMen from '../../images/lola-with-animation.gif'
import Header from '../../layout/Header/Header';
import '../../layout/Benefit/Benefit.css'
import './styles.css'
import Footer from '../../layout/Footer';
import { Link } from 'react-router-dom';

const ModalShow = (props) => {
	return (
        <div>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body style={{padding: '2rem'}}>
                    <h3>Great!!! You have successfully downloaded the Christmas Worksheet</h3>
                    <p className="pt-4 pb-4">Check out our other worksheets in Maths, English, Science and Financial Literacy</p>
                    <div style={{textAlign: 'center'}}>
                        <Link to="/">
                            <Button className="btn" type="button" title="View"></Button>
                        </Link>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
	);
};

const XmasEve = () => {
    const [showModal, setShowModal] = useState(false);

    const Download = () => {
        setShowModal(true);
    }
    
    return (
        <div>
            <Header />
            
            <ModalShow
                show={showModal}
                onHide={() => setShowModal(false)}
            />
            <div className="container mt-5">
                <div className="row">
                    <div className="col-sm-6">
                        <img src={LolaAndTheWiseMen} alt="Lola-and-the-wise-men" className="img-fluid"/>
                    </div>
                    <div className="col-sm-6 m-auto">
                        <h1 className="fw-bolder">OUR GIFT TO YOU</h1>
                        <p>Let the kids have fun this Christmas with 8 selected Christmas worksheets with answers</p>
                        <a href="https://nimdee.com/pdf/Christmas_Worksheet_Easy_and_Hard.pdf" download target="_blank" rel="noreferrer" className='download' onClick={()=>Download()}> Download </a>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-sm-6 m-auto">
                        <h3 className="fw-bolder">Learning should not be boring</h3>
                        <p>A sneak peep at some of our colorful worksheets</p>
                    </div>
                    <div className="col-sm-6">
                    <div className="spelling-img">
                            <div
                                id="carouselExampleIndicators"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <ol className="carousel-indicators">
                                    <li
                                        data-target="#carouselExampleIndicators"
                                        data-slide-to="0"
                                        className="active"
                                    ></li>
                                    <li
                                        data-target="#carouselExampleIndicators"
                                        data-slide-to="1"
                                    ></li>
                                    <li
                                        data-target="#carouselExampleIndicators"
                                        data-slide-to="2"
                                    ></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img
                                            className=" w-100"
                                            // src={Pronouns}
                                            alt="First slide"
                                        />
                                    </div>
                                    <div className="carousel-item">
                                        <img
                                            className="w-100"
                                            // src={savingImage}
                                            alt="Second slide"
                                        />
                                    </div>
                                    {/* <div className="carousel-item">
                                        <img
                                            className="w-100"
                                            src={AnimalsAndTheirHomes}
                                            alt="Second slide"
                                        />
                                    </div> */}
                                    <div className="carousel-item">
                                        <img
                                            className="w-100"
                                            // src={CountingIn10s}
                                            alt="Third slide"
                                        />
                                    </div>
                                </div>
                                <a
                                    className="carousel-control-prev"
                                    href="#carouselExampleIndicators"
                                    role="button"
                                    data-slide="prev"
                                >
                                    <span
                                        className="carousel-control-prev-icon"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a
                                    className="carousel-control-next"
                                    href="#carouselExampleIndicators"
                                    role="button"
                                    data-slide="next"
                                >
                                    <span
                                        className="carousel-control-next-icon"
                                        aria-hidden="true"
                                    ></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 mb-5 text-center">
                    <h1 className="fw-bolder">HOW TO DOWNLOAD</h1>
                    <p>Download workbook in 3 easy steps: </p>
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5 mt-3">
                            <div class="card">
                                <div class="card-body">
                                    <p class="card-text">Click to download for ages 5-7</p>
                                    {/* <Button className="btn" type="button" title="Download Workbook" onClick={()=> Download(2)}/> */}
                                    <a href="https://nimdee.com/pdf/Bible_Challenge_Worksheet_Easy.pdf" download target="_blank" rel="noreferrer" className='download' onClick={()=>Download()}> Download </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 mt-3">
                            <div class="card">
                                <div class="card-body">
                                    <p class="card-text">Click to download for ages 8-12</p>
                                    {/* <Button className="btn" type="button" title="Download Workbook" onClick={()=> Download(3)}/> */}
                                    <a href="https://nimdee.com/pdf/Bible_Challenge_Worksheet_Hard_2.pdf" download target="_blank" rel="noreferrer" className='download' onClick={()=>Download()}> Download </a>
                                </div>
                            </div>
                        </div> 
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default XmasEve
