import styled from 'styled-components'
import { Add, Description } from '@material-ui/icons';
import { ContentContainer, ContentWrapper, TopBanner, BannerText, ButtonContainer } from '../styles/styles'


const SpreadsheetImporter = () => {

    return (
        <ContentContainer
            initial={{opacity: 0.8}} 
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
        >
            <ContentWrapper>
                <TopBanner>
                    <BannerText>
                        <h4>SPREADSHEET IMPORTER</h4>
                    </BannerText>

                    <ButtonContainer>
                        <button>
                            <Description />
                            <a
							href="/"
							target="_blank"
							rel="noreferrer"
							download
							className="text-white"
						>
                            <span>download template</span>
						</a>
                        </button>

                        <button>
                            <Add />
                            <span>upload spreadsheet</span>
                        </button>
                    </ButtonContainer>
                    
                </TopBanner>

                <HistoryWrapper>
                    <h4>Your import history: </h4>
                    
                </HistoryWrapper>
            </ContentWrapper>
            
        </ContentContainer>
    )
}

export default SpreadsheetImporter

export const HistoryWrapper = styled.div`
    padding: 15px;
    margin: 15px;
    margin-top: 80px;
    /* margin-top: 250px; */
    background-color: white;
    border: 2px solid  rgb(54, 53, 97);

    > h4 {
        font-weight: 700;
    }
`