import { combineReducers } from 'redux'
import worksheetsFilterSlice from '../../worksheetsFilterSlice'
import downloadWkshtSlice from '../../downloadWkshtSlice'
import ParentDashboardSlice from '../../ParentDashboardSlice'
import schoolReducer from './school'
import childJourneyHeaderDetailsReducer from './childJourneyHeaderDetails'
import booksReducer from './books'

const reducers = combineReducers({
	worksheets: worksheetsFilterSlice,
	downloadWkshtSlice: downloadWkshtSlice,
	ParentDashboardSlice: ParentDashboardSlice,
	school: schoolReducer,
	childJourneyHeaderDetails: childJourneyHeaderDetailsReducer,
	books: booksReducer,
})

export default reducers
