import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { fetchSchool } from "../../features/redux/actionsCreators/school"
import styled from 'styled-components'
import AllPupils from "../../layout/School/AllPupils"
import AllTeachers from "../../layout/School/AllTeachers"
import Dashboard from "../../layout/School/Dashboard"
import AllClasses from "../../layout/School/AllClasses"
import MyClasses from "../../layout/School/MyClasses"
import SideNav from "../../layout/School/SideNav"
import TopNav from "../../layout/School/TopNav"
import MyPupils from "../../layout/School/MyPupils"
import ContactUs from "../../layout/School/ContactUs"
import SpreadsheetImporter from "../../layout/School/SpreadsheetImporter"

const School = () => {

    const login_token = JSON.parse(sessionStorage.getItem('login_token'))
    const teacherRole = JSON.parse(sessionStorage.getItem('teacherRole'))
    const [isOpen, setIsOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(teacherRole === 'Teacher' ? 1 : 0)

    const history = useHistory()
    const dispatch = useDispatch()

    const toggleSidebar = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        if (!login_token) {
           history.push('/parentslogin') 
        }

        dispatch(fetchSchool())
    }, [dispatch, history, login_token])

    const pages = [
        {
            id: 0,
            title: "Dashboard",
            page: <Dashboard />,
        },
        {
            id: 1,
            title: "Classes",
            page: <MyClasses />,
        },
        {
            id: 2,
            title: "Pupils",
            page: <MyPupils />,
        },
        {
            id: 3,
            title: "All Teachers",
            page: <AllTeachers />
        },
        {
            id: 4,
            title: "All classes",
            page: <AllClasses />,
        },
        {
            id: 5,
            title: "All Pupils",
            page: <AllPupils />
        },
        {
            id: 6,
            title: "Spreadsheet importer",
            page: <SpreadsheetImporter />,
        },
        {
            id: 7,
            title: "contact us",
            page: <ContactUs />,
        }
    ]
    
    return (
        <SchoolDashboardWrapper>
            <TopNav toggleSidebar={toggleSidebar} />
            <SideNav isOpen={isOpen}  setIsOpen={setIsOpen} setCurrentPage={setCurrentPage} />
            {
                pages.map(page => currentPage === page.id ? page.page : "")
            }
        </SchoolDashboardWrapper>
    )
}

export default School

const SchoolDashboardWrapper = styled.div`
    /* min-width: 1200px;
    overflow-x: scroll; */
`