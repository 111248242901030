import React from 'react'
import ParentDashboardHeader from '../../layout/ParentDashboardHeader/ParentDashboardHeader';
import {Link} from 'react-router-dom'

const Notifications = () => {
    return (
        <div>
            <ParentDashboardHeader/>
            <div className="container">
                <div className="mt-3">
                    <Link to="/notifications"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</Link>
                </div>
                <div className="mt-5">
                    This is some text within a card body. This is some text within a card body. This is some text within a card body. This is some text within a card body. This is some text within a card body. This is some text within a card body. This is some text within a card body. This is some text within a card body. This is some text within a card body. This is some text within a card body. This is some text within a card body
                </div>
            </div>
        </div>
    )
}

export default Notifications
