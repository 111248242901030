import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import Notifications from './pages/Notifications/Notifications';
import AccountSettings from './pages/AccountSettings/AccountSettings';
import Workinprogress from '../src/images/WorkInProgress.gif';
import useGaTracker from './useGaTracker';
import Error404 from './pages/Error404/Error404';
import Login from './pages/Login/Login';
import School from './pages/School';
import ResetPassword from './components/ResetPassword/ResetPassword';
import SetPassword from './components/SetPassword/SetPassword';
import TeamNimdee from './pages/TeamNimdee'
import SchoolSignUp from './components/SchoolSignUp/SchoolSignUp';
import ParentSignup from './components/ParentSignUp/ParentSignUp';
import Signup from './pages/Signup/Signup';
import LoadingModal from './layout/LoadingModal/LoadingModal';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';
import TermsAndConditions from './pages/TermsAndCondition/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Faq from './pages/Faq/Faq';
import Parentslogin from './components/ParentsLogin/ParentsLogin';
import Childlogin from './components/ChildLogin/ChildLogin';
import Pricing from './pages/Pricing';
import UpgradePayment from './pages/UpgradePayment/UpgradePayment';
import XmasEve from './pages/XmasEve'
const LandingPage = lazy(() => import('./pages/LandingPage/LandingPage'));
const Worksheets = lazy(() => import('./pages/Worksheets'));
const WorksheetDetails = lazy(() => import('./pages/WorksheetDetailsNew'));
const Workbooks = lazy(() => import('./pages/Workbooks/Workbooks'));
const WorkbookDetails = lazy(() => import('./pages/WorkbookDetails'));
const NotificationMessages = lazy(() => import('./pages/NotificationMessages/NotificationMessages'));
const ChildProfile = lazy(() => import('./components/ChildProfile/ChildProfile'));
const Stemfest = lazy(() => import('./pages/Stemfest/Stemfest'));
const StemfestError = lazy(() => import('./pages/StemfestError/StemfestError'));
const StemfestThanks = lazy(() => import('./pages/StemfestThanks/StemfestThanks'));
const ChildJourney = lazy(() => import('./pages/ChildJourney/ChildJourney'));
const ParentDashboard = lazy(() => import('./pages/ParentDashboard/ParentDashboard'));
const AvatarShop = lazy(() => import('./pages/AvatarShop/AvatarShop'));
const Wardrobe = lazy(() => import('./pages/Wardrobe/Wardrobe'));
const ChildworksheetDetails = lazy(() => import('./pages/ChildWorksheetDetails/ChildWorksheetDetails'));
const ChildActivities = lazy(() => import('./pages/ChildActivities/ChildActivities'));
const ReferAFriend = lazy(() => import('./pages/ReferAFriend/ReferAFriend'));
const LearningPathMap = lazy(() => import('./layout/LearningPathMap/LearningPathMap'));
const InteractiveBook = lazy(() => import('./layout/InteractiveStorybook/InteractiveBook'));
const BookList = lazy(() => import('./pages/BookList/BookList'));

// Guide for Protected Routes
// https://codedaily.io/tutorials/Create-a-ProtectedRoute-for-Logged-In-Users-with-Route-Redirect-and-a-Render-Prop-in-React-Router
// Guide for App Deployment

const App = () => {
	useGaTracker();
	//Start of Google Analytics
	const [userInfo, setUserInfo] = useState('');
	const [childInfo, setChildInfo] = useState({});
	const [loading, setLoading] = useState(false);


	useEffect(() => {
		const checkUserData = () => {
			setLoading(true)
			const parent = JSON.parse(sessionStorage.getItem('user'));
			const schoolTeacher = JSON.parse(sessionStorage.getItem('login_token'))
			const childDetails = JSON.parse(localStorage.getItem('childDetails'))
			const getUserInfo = () => {
				if (parent) {
					return parent
				} else if(schoolTeacher) {
					return schoolTeacher
				} else if (childDetails) {
					setChildInfo(JSON.parse(localStorage.getItem('childDetails')))
				} else {
					return null
				}
			}
			setLoading(false)
			setUserInfo(getUserInfo())
		}
		window.addEventListener('storage', checkUserData)
		// window.addEventListener('childDetails', setChildInfo(JSON.parse(localStorage.getItem('childDetails'))))
		return () => {
			window.removeEventListener('storage', checkUserData)
			// window.removeEventListener('childDetails', setChildInfo(JSON.parse(localStorage.getItem('childDetails'))))
		}
		
	}, [])
	const ProtectedRoute = ({ component: Comp, userInfo, path, ...rest }) => {
		return (
			<Route
				exact
				path={path}
				{...rest}
				render={(props) => {
					if (userInfo === undefined || userInfo === null) {
						return <Redirect to="/" />
					} else {
						return <Comp {...props} />
					}
				}}
			/>
		);
	};


	return (
		<div className="App">
			<LoadingModal 
				show={loading}
				onHide={() => setLoading(false)}
			/>
			<Router>
				<Suspense
					fallback={
						<div className="text-center">
							<img className="text-center img-fluid" src={Workinprogress} alt="loading" />
						</div>
					}
				>
					<Switch>
						<Route exact path="/" component={LandingPage} />
						<Route exact path="/christmas-campaign" component={XmasEve} />
						<Route exact path="/login" component={Login} />
						<Route exact path="/worksheets" component={Worksheets} />
						<Route path="/worksheets/details" exact component={WorksheetDetails}/>
						<Route exact path="/workbooks" component={Workbooks} />
						<Route path="/workbook/details" exact component={WorkbookDetails}/>
						<Route exact path="/signup" component={Signup} />
						<Route exact path="/parentsignup" component={ParentSignup} />
						<Route exact path="/schoolsignup" component={SchoolSignUp} />
						<Route exact path="/childlogin" component={Childlogin} />
						<Route exact path="/avatarshop" component={AvatarShop} />
						<Route exact path="/wardrobe" component={Wardrobe} />
						<Route exact path="/resetpassword/:token" component={ResetPassword} />
						<Route exact path="/school-and-teacher/setpassword/:token" component={SetPassword} />
						<Route exact path="/parentslogin" component={Parentslogin} />
						<Route exact path="/pricing" component={Pricing} />
						<Route exact path="/map" component={LearningPathMap} />
						<Route exact path="/teamnimdee" component={TeamNimdee} />
						<Route exact path="/privacypolicy" component={PrivacyPolicy} />
						<Route exact path="/faq" component={Faq} />
						<Route exact path="/termsandconditions" component={TermsAndConditions} />
						<ProtectedRoute path="/childjourney" userInfo={childInfo} component={ChildJourney} />
						<ProtectedRoute path="/childactvity" component={ChildActivities} userInfo={childInfo} />
						<ProtectedRoute path="/childprofile" component={ChildProfile} userInfo={userInfo}/>
						<ProtectedRoute path="/parentdashboard" component={ParentDashboard} userInfo={userInfo}/>
						<ProtectedRoute path="/account" userInfo={userInfo} component={AccountSettings} />
						<ProtectedRoute path="/upgradeplan" component={UpgradePayment} userInfo={userInfo} />
						<ProtectedRoute path="/refer-a-friend" userInfo={userInfo} component={ReferAFriend} />
						<ProtectedRoute path="/notifications" userInfo={userInfo} component={NotificationMessages} />
						<ProtectedRoute path="/messages" userInfo={userInfo} component={Notifications} />
						<ProtectedRoute path="/school-and-teacher" component={School} userInfo={userInfo}/>
						<ProtectedRoute
							path="/childworksheetdetails"
							component={ChildworksheetDetails}
							userInfo={childInfo}
						/>
						<Route exact path="/books" component={BookList}/>
						<Route
							exact
							path="/books/:title"
							component={InteractiveBook}
							userInfo={childInfo}
						/>
						<Route
							exact
							path="/learningpath/:subject"
							component={LearningPathMap}
							userInfo={childInfo}
						/>
						<Route exact path="/verifyemail/:token" component={VerifyEmail} />
						<Route exact path="/steamfest" component={Stemfest} />
						<Route exact path="/steamfesterror" component={StemfestError} />
						<Route exact path="/steamfesthanks" component={StemfestThanks} />

						<Route component={Error404} />
					</Switch>
				</Suspense>
			</Router>
		</div>
	);
}

export default App;