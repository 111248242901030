import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import "./UpgradePrompt.css";

const UpgradePrompt = (props) => {
	// const [setUpgradeModal] = useState(true);
	// const handleClose = () => setUpgradeModal(false);
	return (
		<div>
			<Modal
				{...props}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				size="lg"
			>
				<Modal.Title id="contained-modal-title-vcenter">
					<h3 className="forgot-password">
						<span onClick={props.onHide} className="closeimg">
							<img
								src="../../../images/close-btn.png"
								alt="close-button"
								style={{float: "right", cursor:"pointer", marginTop:"-1.5rem"}}
							></img>
						</span>
					</h3>
				</Modal.Title>
				<Modal.Body>
					<div className="text-center">
						<img
							className="imgstyle mx-auto img-fluid"
							src="../../images/Timmy_Emotions.png"
							alt="upgrade-prompt"
						/>
						<h1>Opps !!!</h1>
						<h3 className="m-4">
							Upgrade to premium to access more features
						</h3>
						<Link to="/upgradeplan">
							<Button title="Upgrade Now" type="button"/>
						</Link>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default UpgradePrompt;
