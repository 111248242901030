import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	wkshtToDownload: {},
}

const downloadWkshtSlice = createSlice({
	name: 'downloads',
	initialState,
	reducers: {
		addwksht: (state, { payload }) => {
			state.wkshtToDownload = payload
		},
	},
})

export const { addwksht } = downloadWkshtSlice.actions

export const selectWkshtToDownload = (state) => state.downloads.wkshtToDownload
export default downloadWkshtSlice.reducer
