import * as ACTION from '../../constants/books'
import * as PAGINATION from '../../constants/pagination'

const initialState = {
	books: [],
	loading: true,
	filterBy: 'All',
	bookInView: {},
	currentPage: 1,
	itemsPerPage: 20,
}

const booksReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ACTION.FETCH_LOADING:
			return { ...state }

		case ACTION.FETCH_BOOKS_SUCCESS:
			return {
				...state,
				loading: false,
				books: payload,
				activeBooks: payload,
				filterBy: 'All',
			}

		case ACTION.FETCH_FAILED:
			return {
				loading: false,
				error: payload,
			}

		case ACTION.SEARCH_BOOKS:
			let searchedBooks = state.books?.filter((book) =>
				book.title.toLowerCase().includes(payload.toLowerCase())
			)

			return {
				...state,
				activeBooks: searchedBooks,
				currentPage: 1,
				filterBy: 'All',
			}

		case ACTION.FILTER_BOOKS_BY_READ:
			let filteredBooks = state.books?.filter((book) =>
				payload === 'All'
					? book
					: payload === 'Read'
					? book.read === true
					: book.read === false
			)

			return {
				...state,
				activeBooks: filteredBooks,
				filterBy: payload,
				currentPage: 1,
			}

		case PAGINATION.NEXT_PAGE:
			return {
				...state,
				currentPage: state.currentPage + 1,
			}

		case PAGINATION.PREVIOUS_PAGE:
			return {
				...state,
				currentPage: state.currentPage - 1,
			}

		case PAGINATION.PAGE_TARGET:
			return {
				...state,
				currentPage: payload,
			}

		default:
			return state
	}
}

export default booksReducer
