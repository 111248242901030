const prod = {
    url: {
        // API_URL: "https://nimdeewksht.sandbox.9ijakids.com/backend/api/v1",
        API_URL: "https://nimdee.com/backend/api/v1",
    }
};
const dev = {
    url: {
        // API_URL: "https://afternoon-ridge-35420.herokuapp.com/https://nimdeewksht.sandbox.9ijakids.com/backend/api/v1",
        // API_URL: "https://nimdee.com/backend/api/v1",
        API_URL: "https://cors-anywhere.herokuapp.com/https://nimdee.com/backend/api/v1"
    }
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;