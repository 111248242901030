import React, { useState } from 'react';
import './Accordion.css';
const Accordion = ({ title, content, content2, content3,list1, list2, list3, list4, list5, list6, list7, list8, list9, contentOnNewLine1, contentOnNewLine2, contentOnNewLine3, contentOnNewLine4, contentOnNewLine5, contentOnNewLine6, contentOnNewLine7, Ans1 ,Ans2 ,Ans3 ,Ans4 ,Ans5 ,list1sublist ,list2sublist ,list3sublist ,list4sublist ,list5sublist }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <h5>{title}</h5>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && 
        <div className="accordion-content pl-5 pr-5">
            {content && <p>{content}</p>}  
            {contentOnNewLine1 && <p>{contentOnNewLine1}</p>}
            {contentOnNewLine2 && <p>{contentOnNewLine2}</p>}
            {contentOnNewLine3 && <p>{contentOnNewLine3}</p>}
            {contentOnNewLine4 && <p>{contentOnNewLine4}</p>}
            {contentOnNewLine5 && <p>{contentOnNewLine5}</p>}
            {contentOnNewLine6 && <p>{contentOnNewLine1}</p>}
            {contentOnNewLine7 && <p>{contentOnNewLine1}</p>}
            <div>
                <ol className='mt-3 pr-5'>
                    {list1 &&
                        <li>
                            {list1}
                            {Ans1 && <p className="pl-4">-{Ans1}</p>}
                        </li>
                    }
                    {list2 &&
                        <li>
                            {list2}
                            {Ans2 && <p className="pl-4">-{Ans2}</p>}
                            {list1sublist &&
                                <ul>
                                    <li>{list1sublist}</li>
                                    <li>{list2sublist}</li>
                                    <li>{list3sublist}</li>
                                    <li>{list4sublist}</li>
                                    <li>{list5sublist}</li>
                                </ul>
                            }
                        </li>
                    }
                    {list3 &&
                        <li>
                            {list3}
                            {Ans3 && <p className="pl-4">-{Ans3}</p>}
                        </li>
                    }
                    {list4 &&
                        <li>
                            {list4}
                            {Ans4 && <p className="pl-4">-{Ans4}</p>}
                        </li> 
                    }
                    {list5 &&
                        <li>
                            {list5}
                            {Ans5 && <p className="pl-4">-{Ans5}</p>}
                        </li> 
                    }
                    {list6 &&
                        <li>{list6}</li> 
                    }
                    {list7 &&
                        <li>{list7}</li> 
                    }
                    {list8&&
                        <li>{list8}</li> 
                    }
                    {list9 &&
                        <li>{list9}</li> 
                    }
                </ol>
                <p>{content2}</p>
                {content3 && <p>{content3}</p> }
            </div>
        </div>}
    </div>
  );
};

export default Accordion;