import React, { useState } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import { Input } from "../TextField/ParentsTextfield";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { config } from '../../Constant'
import Button from '../../components/Button/Button'
import {SuccessToast, ErrorToast} from '../Toast/Toast';

const Forgotpassword = (props) => {

	var url = config.url.API_URL
	const [setForgotPasswordModal] = useState(true);
	const validate = Yup.object({
		email: Yup.string()
			.email("Email is invalid")
			.required("Email is required"),
	});

	return (
		<Formik
			initialValues={{
				email: "",
			}}
			validationSchema={validate}
			onSubmit={(values) => {
				axios
					.post(
						`${url}/Auth/auth.php/forgotPassword`,
						values
					)
					.then((res) => {
						if (res.status === 200) {
							SuccessToast(res.data.message)
							setForgotPasswordModal(false);
						}
					})
					.catch(function (error) {
						if (error) {
							ErrorToast(error.response.data.error)
						} else {
							ErrorToast('An error occurred')
						}
						console.log(error.response.data.error);
					});
			}}
		>
			{(formik) => (
				<div>
					<Modal
						{...props}
						aria-labelledby="contained-modal-title-vcenter"
						centered
					>
						<Modal.Title id="contained-modal-title-vcenter">
							<h3 className="forgot-password">
								Forgot Password
								<span
									onClick={props.onHide}
									className="closeimg"
								>
									<img
										src="../../../images/close-btn.png"
										alt="close-button"
									></img>
								</span>
							</h3>
						</Modal.Title>
						<Modal.Body>
							<Form>
								<p>
									Please type in your email to get a link to
									reset your password
								</p>
								<div className="mb-4">
									<Input
										name="email"
										id="form-input"
										type="email"
										placeholder="Please type in your email"
									/>
								</div>
								<div className="text-center">
									<Button className="btn mt-3" type="submit" title="Send Link"></Button>
								</div>
							</Form>
						</Modal.Body>
						<Modal.Footer>
						</Modal.Footer>
					</Modal>
				</div>
			)}
		</Formik>
	);
};
export default Forgotpassword;