export const cardText =  [
    {
        plan: "Basic",
        price: "Free",
        priceInDollars: "Free",
        color: "rgb(60, 196, 229)",
        planImage: "../../images/free-plan-icon.png",
        checkList: [
            { 	
                "id" : 1,
                "text" : "Access to two FREE worksheets per month",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
            {
                "id" : 2,
                "text" : "Enjoy unlimited access to all worksheets",
                "image" : "../../images/Not-available-icon.png",
                "alt" : "not-available-icon"
            },
            {
                "id" : 3,
                "text" : "Up to four(4) children accounts with unlimited fun avatars",
                "image" : "../../images/Not-available-icon.png",
                "alt" : "not-available-icon"
            },
            {
                "id" : 4,
                "text" : "Parent’s dashboard to track child’s progress",
                "image" : "../../images/Not-available-icon.png",
                "alt" : "not-available-icon"
            },
            {
                "id" : 5,
                "text" : "Child(ren)’s access to tournaments, leader boards and virtual coins",
                "image" : "../../images/Not-available-icon.png",
                "alt" : "not-available-icon"
            },
            {
                "id" : 6,
                "text" : "Access to quizzes and games for subject mastery",
                "image" : "../../images/Not-available-icon.png",
                "alt" : "not-available-icon"
            },
            {
                "id" : 7,
                "text" : "Access to illustrated stories",
                "image" : "../../images/Not-available-icon.png",
                "alt" : "not-available-icon"
            },
        ]
    },
    {
        plan: "Monthly",
        price: 2500,
        priceInDollars: 7,
        color: "rgb(250, 168, 25)",
        planImage: "../../images/monthly-plan-icon.png",
        checkList: [
            { 	
                "id" : 1,
                "text" : "Access to two FREE worksheets per month",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
            {
                "id" : 2,
                "text" : "Enjoy unlimited access to all worksheets",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
            {
                "id" : 3,
                "text" : "Up to four(4) children accounts with unlimited fun avatars",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
            {
                "id" : 4,
                "text" : "Parent’s dashboard to track child’s progress",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
            {
                "id" : 5,
                "text" : "Child(ren)’s access to tournaments, leader boards and virtual coins",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
            {
                "id" : 6,
                "text" : "Access to quizzes and games for subject mastery",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
            {
                "id" : 7,
                "text" : "Access to illustrated stories",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
        ]
    },
    {
        plan: "Annual",
        price: 1300,
        priceInDollars: 4,
        color: "rgb(54, 53, 97)",
        planImage: "../../images/Annual-plan-icon.png",
        checkList: [
            { 	
                "id" : 1,
                "text" : "Access to two FREE worksheets per month",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
            {
                "id" : 2,
                "text" : "Enjoy unlimited access to all worksheets",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
            {
                "id" : 3,
                "text" : "Up to four(4) children accounts with unlimited fun avatars",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
            {
                "id" : 4,
                "text" : "Parent’s dashboard to track child’s progress",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
            {
                "id" : 5,
                "text" : "Child(ren)’s access to tournaments, leader boards and virtual coins",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
            {
                "id" : 6,
                "text" : "Access to quizzes and games for subject mastery",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
            {
                "id" : 7,
                "text" : "Access to illustrated stories",
                "image" : "../../images/Available-icon.png",
                "alt" : "available-icon"
            },
        ]
    }

] 