export const FETCH_LOADING = "FETCH_LOADING"
export const FETCH_FAILED = "FETCH_FAILED"
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS"
export const FETCH_ALL_TEACHERS_SUCCESS = " FETCH_TEACHERS_SUCCESS"
export const FETCH_ALL_PUPILS_SUCCESS = " FETCH_PUPILS_SUCCESS"
export const FETCH_ALL_CLASSES_SUCCESS = "FETCH_ALL_CLASSES_SUCCESS"
export const FETCH_MY_PUPILS_SUCCESS = "FETCH_MY_PUPILS_SUCCESS"
export const FETCH_MY_CLASSES_SUCCESS = "FETCH_MY_CLASSES_SUCCESS"
export const SEARCH_ALL_TEACHERS_BY_NAME = "SEARCH_ALL_TEACHERS_BY_NAME"
export const SEARCH_ALL_PUPILS_BY_NAME = "SEARCH_ALL_PUPILS_BY_NAME"
export const SEARCH_ALL_CLASSES_BY_NAME = "SEARCH_ALL_CLASSES_BY_NAME"
export const SEARCH_MY_PUPILS_BY_NAME = "SEARCH_MY_PUPILS_BY_NAME"
export const SEARCH_MY_CLASSES_BY_NAME = "SEARCH_MY_CLASSES_BY_NAME"
export const SORT_ITEMS_BY_NAME_ORDER = "SORT_ITEMS_BY_NAME_ORDER"