import styled from 'styled-components'

export const FormContainer = styled.div`
	text-align: center;
	width: 90vw;
	max-width: 900px;
	margin: 0 auto;

	> h2 {
		font-size: 20px;
		text-transform: uppercase;
		font-family: 'Russo One', sans-serif;
		font-weight: 400;
		color: white;
	}

	> form {
		padding: 10px 2px;
		display: flex;
		flex-direction: column;

		> input {
			border-radius: 10px;
			outline: none;
			border: none;
			height: 35px;
            padding: .5rem 3rem;
            margin-bottom: 10px;
			flex: 0.8;
		}

		> button {
			flex: 0.1;
		}
	}

	@media screen and (min-width: 768px) {
		> h2 {
			font-size: 25px;
		}

        > form {
			flex-direction: row;
			> input {
				height: 42px;
                margin: 0 10px 10px 0;
			}
		}
	}
`
