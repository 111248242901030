import { createSlice } from '@reduxjs/toolkit'

export const worksheetFilters = createSlice({
	name: 'worksheetFilterVariables',
	initialState: {
		filterByLevel: '',
		filterBySubject: '',
		filterByTopic: '',
		filterByInteractive: '',
		worksheetInView: {},
	},
	reducers: {
		filterWorksheetsByLevel: (state, { payload }) => {
			state.filterByLevel = payload
		},
		filterWorksheetsBySubject: (state, { payload }) => {
			state.filterBySubject = payload
		},
		filterWorksheetsByTopic: (state, { payload }) => {
			state.filterByTopic = payload
		},
		filterWorksheetsByInteractive: (state, { payload }) => {
			state.filterByInteractive = payload
		},
		viewWorksheetDetails: (state, { payload }) => {
			state.worksheetInView = payload
		},
	},
})

export const {
	filterWorksheetsByLevel,
	filterWorksheetsBySubject,
	filterWorksheetsByTopic,
	filterWorksheetsByInteractive,
	viewWorksheetDetails,
} = worksheetFilters.actions

export default worksheetFilters.reducer
