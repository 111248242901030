import React, { useState } from "react";
import axios from "axios";
import "./ParentSignUp.css";
import { Formik, Form, Field } from "formik";
import { Input } from "../TextField/TextField";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import Header from "../../layout/Header/Header";
import { usePaystackPayment } from "react-paystack";
import LoadingModal from "../../layout/LoadingModal/LoadingModal";
import { config } from '../../Constant'
import Button from '../Button/Button'
import {SuccessToast, ErrorToast} from '../Toast/Toast';

var url = config.url.API_URL

// How to add conditional rendering
// https://www.digitalocean.com/community/tutorials/7-ways-to-implement-conditional-rendering-in-react-applications

// Paystack PostMan
// https://documenter.getpostman.com/view/2770716/paystack-api/7187aMn?version=latest
// https://paystack.com/docs/api/#transaction-verify

const ParentSignup = () => {

	const [modalShow, setModalShow] = useState(false);
	const history = useHistory();
	const validate = Yup.object({
		fullName: Yup.string()
			.max(34, "Must be 34 characters or less")
			.required("Full Name is Required"),
		email: Yup.string()
			.email("Email is invalid")
			.required("Email is required"),
		password: Yup.string()
			.min(6, "Password must be at least 6 characters")
			.required("Password is required"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref("password"), null], "Password must match")
			.required("Confirm your password"),
	});

	const query = new URLSearchParams(window.location.search);
	const referrals = query.get("referral");

	const signUpRequest = (values) => {
		values.referral = referrals;
		axios
			.post(
				`${url}/Auth/auth.php/signup`,
				values
			).then((res) => {
				setModalShow(false);
				setConfig({ ...config, email: values.email });
				if (res.status === 200) {
					SuccessToast(res.data.message.slice(0, 2));
					if (res.data.message[2] !== null || undefined) {
						if (
							res.data.message[2] ===
							"The voucher code does not exist"
						) {
							ErrorToast(res.data.message[2]);
						} else if (res.data.message[2]) {
							SuccessToast(res.data.message[2]);
						}
					}
					setTimeout(() => {
						history.push("/parentslogin");
					}, 2000);
				} else {
					setModalShow(false);
					ErrorToast('An error occurred');
				}
			})
			.catch(function (error) {
				ErrorToast(error.response.data.error);
				setModalShow(false);
				console.log(error.response.data)
			});
	};
	const planAmount = () => {
		const plan = JSON.parse(localStorage.getItem("plans"));
		if (plan === undefined || plan === null) {
			return [0, "NGN", ""];
		} else if (plan.plan === "Monthly" && plan.currency === "naira") {
			return [250000, "NGN", "PLN_c3gb5mhflxz4g7z"];
		} else if (plan.plan === "Monthly" && plan.currency === "dollars") {
			return [250000, "USD", "PLN_c3gb5mhflxz4g7z"];
		} else if (plan.plan === "Annual" && plan.currency === "dollars") {
			return [1500000, "USD", "PLN_65bo1g6hrj4bd16"];
		} else if (plan.plan === "Annual" && plan.currency === "naira") {
			return [1500000, "NGN", "PLN_65bo1g6hrj4bd16"];
		} else if (plan.plan === "Basic") {
			return [0, "NGN", ""];
		}
	};

	const [config, setConfig] = useState({
		email: "",
		amount: planAmount()[0],
		publicKey: "pk_live_0138508685298f9efdd67b23d91f798d48bd8609",
		currency: "NGN",
		// publicKey: "pk_test_4e331046a3e4ffdddac742a4670ae0b1b98c0b3f",
		plan: planAmount()[2],
	});
	const initializePayment = usePaystackPayment(config);
	const getSelectedplan = JSON.parse(localStorage.getItem("plans"));

	return (
		<Formik
			initialValues={{
				fullName: "",
				email: "",
				password: "",
				confirmPassword: "",
				Voucher: "",
				plan: getSelectedplan ? getSelectedplan.plan : "Annual",
			}}
			validationSchema={validate}
			onSubmit={(values) => {
				setConfig({ ...config, email: values.email });
				if (values.plan === "Basic") {
					setModalShow(true);
					signUpRequest(values);
				} else {
					const onSuccess = (reference) => {
						// Implementation for whatever you want to do with reference and after success call.
						reference.amount = config.amount;
						reference.email = config.email;
						const newValues = { ...values, ...reference };
						axios
							.post(
								`${url}/Auth/auth.php/verifyemail`,
								JSON.stringify(reference)
							)
							.then((res) => {
								if (res.data.data.status === "success") {
									SuccessToast("Payment Verification Successful");
									signUpRequest(newValues);
								} else {
									ErrorToast("Payment Verification Failed");
									values = {
										...newValues,
										plan: "Basic",
										status: "unverified",
									};
									signUpRequest(values);
								}
							})
							.catch((error) => {
								ErrorToast("Payment Verification Failed");
								ErrorToast("Sign Up Failed");
							});
					};
					// you can call this function anything
					const onClose = (error) => {
						// implementation for  whatever you want to do when the Paystack dialog closed.
						console.log(error);
						ErrorToast('Payment Failed...try again');
						values.plan = "Basic";

						signUpRequest(values);
					};
					initializePayment(onSuccess, onClose);
				}
			}}
		>
			{(formik) => (
				<div>
					<Header />
					<LoadingModal
						show={modalShow}
						onHide={() => setModalShow(false)}
					/>
					<div className="container justify-content-center my-5">
						<div className="container">
							<h2 className="bold mt-3 text-center text-uppercase">Parent Sign Up</h2>
							<div className="center-icon m-auto">
								<p className="parent-signup m-auto"></p>
							</div>
							<div className="row mt-4">
								<Form className="center-form">
									<div className="mb-4 labelStyle">
										<Input
											label="Full Name"
											id="form-input"
											name="fullName"
											type="text"
											placeholder="Please enter your name"
										/>
									</div>
									<div className="mb-4 labelStyle">
										<Input
											label="Email"
											name="email"
											id="form-input"
											type="email"
											placeholder="Please enter your email"
										/>
									</div>
									<div className="mb-4 labelStyle">
										<Input
											label="Password"
											name="password"
											type="password"
											id="form-input"
											placeholder="Please enter your password"
										/>
									</div>
									<div className="mb-4 labelStyle">
										<Input
											label="Confirm Password"
											name="confirmPassword"
											type="password"
											id="form-input"
											placeholder="Please confirm your password"
										/>
									</div>
									<div className="mb-4 labelStyle">
										<Input
											label="Enter Voucher"
											name="Voucher"
											type="text"
											id="form-input"
											placeholder="Please Enter your voucher code if any"
										/>
									</div>
									<div
										role="group"
										aria-labelledby="my-radio-group m-auto"
										className="checkedStyle"
									>
										<label id="basic-label">
											<Field
												type="radio"
												name="plan"
												value="Basic"
												onClick={() =>
													setConfig({
														...config,
														amount: 0,
													})
												}
											/>
											<img
												className="imgstyle mx-auto img-fluid"
												src="../../images/Free_Icon.png"
												alt="basic plan"
												style={{ width: "12rem" }}
											/>
										</label>
										<label id="monthly-label">
											<Field
												type="radio"
												name="plan"
												value="Monthly"
												onClick={() =>
													setConfig({
														...config,
														amount: 250000,
														plan:
															"PLN_c3gb5mhflxz4g7z",
													})
												}
											/>
											<img
												className="imgstyle  mx-auto img-fluid"
												src="../../images/Monthly_Icon.png"
												alt="monthly plan"
												style={{ width: "12rem" }}
											/>
										</label>
										<label id="annual-label">
											<Field
												type="radio"
												name="plan"
												value="Annual"
												onClick={() =>
													setConfig({
														...config,
														amount: 1500000,
														plan:
															"PLN_65bo1g6hrj4bd16",
													})
												}
											/>
											<img
												className="imgstyle  mx-auto img-fluid"
												src="../../images/Annual_Icon.png"
												alt="Annual plan"
												style={{ width: "12rem" }}
											/>
										</label>
									</div>
									<div className="button-div m-auto text-center">
										<Link to="/pricing" className="mr-2">
											<Button className="btn mt-3" type="button" title="Back"></Button>
										</Link>
										<Button className="btn mt-3" type="submit" title="Sign Up" onClick={() =>
												{setConfig({
													...config,
													email: formik.values.email,
												})
												window.fbq('track', 'Lead')
											}}></Button>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			)}
		</Formik>
	);
};

export default ParentSignup;