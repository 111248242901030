export const TermsAndCondtionsData = [
    {
        title: 'INTRODUCTION',
        content: `nimdee.com ("Nimdee Learning Games", “Nimdee”, "Website", "We," "Us," or "Our") is a platform built to host, manage, and deploy fun learning content to learners via smartphones, tablets and computers with marginal concern for internet limitations and costs.`,

        contentOnNewLine1: `The Website is intended to identify and promote the range of services We can offer to Our clients and to provide details as to how We can be contacted. For the purpose of these Terms of Use (“Terms”, or “Agreement”), along with any amendments, “You”, “Your”, or “User” refer to the person visiting, accessing, browsing through and/or using Our Website/Application (“Services”) or using any storage or transmitting device (“Products”) provided by us at any point in time. Please read these Terms and Our privacy notice (“Privacy Notice”) with respect to the access and use of Our Services and Products carefully before using the Services or Products. If You do not agree with the Terms or the Privacy Notice, please do not use Our Services or avail the Products. In the event of any discrepancy between the Terms and any other policies with respect to the Services or Products, the provisions of the Terms shall prevail.`,
        
        contentOnNewLine2: `Also note that the headings of each section in these Terms are only for the purpose of organising the various provisions under these Terms in an orderly manner. These headings shall not be used to interpret the provisions contained under them in any manner. Further, the headings have no legal or contractual value.`
    },
    {
        title: 'MINIMUM AGE',
        content: `By signing up for Our services, You accept and agree to be bound and abide by these Terms and Our Privacy Notice. If You do not want to agree to these Terms and/or the Privacy Notice, You must not sign up for Our services. If you are acting on behalf of a minor, the minor is also bound by these Terms. You are, therefore, advised to read these Terms carefully, as Your use, access, browsing of Our Services or Products, with or without payment and with or without subscription, through any means shall signify Your acceptance of the Terms and Your agreement to be legally bound by the same.`,

        contentOnNewLine1: `When using services offered through Nimdee’s third-party service providers, such as email, You will be subject to Terms posted by these third-party service providers. Registration for any of Our Services or Products signifies Your agreement to those additional Terms.`,

        contentOnNewLine2: `You may receive a written copy of this Agreement by emailing us at: support@Nimdee.com with the Subject: Request for Terms of Service Agreement. In addition, when using particular Nimdee Services, You and Nimdee shall be subject to any posted guidelines or rules applicable to such services that may be posted from time to time. All such guidelines or rules are hereby incorporated by reference into the Terms.`
    },
    {
        title: 'ACCEPTANCE OF TERMS',
        content: `By signing up for Our services, You accept and agree to be bound and abide by these Terms and Our Privacy Notice. If You do not want to agree to these Terms and/or the Privacy Notice, You must not sign up for Our services. If you are acting on behalf of a minor, the minor is also bound by these Terms. You are, therefore, advised to read these Terms carefully, as Your use, access, browsing of Our Services or Products, with or without payment and with or without subscription, through any means shall signify Your acceptance of the Terms and Your agreement to be legally bound by the same.`,

        contentOnNewLine1: `When using services offered through Nimdee’s third-party service providers, such as email, You will be subject to Terms posted by these third-party service providers. Registration for any of Our Services or Products signifies Your agreement to those additional Terms.`,

        contentOnNewLine2 : `You may receive a written copy of this Agreement by emailing us at: support@Nimdee.comwith the Subject: Request for Terms of Service Agreement. In addition, when using particular Nimdee Services, You and Nimdee shall be subject to any posted guidelines or rules applicable to such services that may be posted from time to time. All such guidelines or rules are hereby incorporated by reference into the Terms.`
    },
    {
        title: 'SERVICES OVERVIEW',
        content: `Our Website/Application is a platform where Users can gain access to educational materials. Once Users register themselves via the Website or Application, they gain access to Our materials on a freemium basis, which can be converted to premium at any time. A User can subscribe to one of three plans: free, monthly or annual.`,

        contentOnNewLine1: `Once a subscription is purchased, a customer will be entitled to a refund/return request within 7 days. Nimdee is under no obligations to honor any refund/return request after expiry of the 7 (seven)-day window.`
    },
    {
        title: 'FEES',
        content: `Our services are billed on a subscription basis (the “Subscription”). This means that once Your Subscription expires, You will no longer have access to Our materials. To renew your subscription, send a request to Us via email to support@Nimdee.com or via phone on + 234 803 402 1007. If You provide credit or debit card information to pay for the Service, You represent and warrant that You are authorized to supply such information and that You authorize Us to charge that payment method on a subscription basis to pay the fees as they come due.`,

        contentOnNewLine1: `Every effort has been made to ensure accurate pricing of Our Products—featured on Our Website or offered via some other means—but please be aware that Our prices are subject to change. In the event that a Product is ordered, and the listed price has changed, You will be notified prior to processing Your Order. You agree to pay all applicable taxes relating to Your use of Our Services or Products.`
    },
    {
        title: 'USER’S CONDUCT',
        content: `A User shall use this Website for lawful purposes only. Accordingly, a User shall not post, send or transmit any material which violates or infringes in any way upon the rights of others or perform any act that is unlawful, threatening, abusive, defamatory, invasive of privacy or publicity rights, vulgar, obscene, profane or otherwise objectionable, which encourages conducts that would constitute a criminal offence, give rise to civil liability or otherwise violate any law.`,

        contentOnNewLine1: `Any conduct by a User that, at Nimdee’s sole discretion, restricts or inhibits any other User from using or enjoying this Website is prohibited. Accordingly, no User shall use this Website to advertise or perform any commercial solicitation, including, but not limited to, the solicitation of Users to become subscribers of other on-line information services that are in competition or likely to be in competition with Nimdee.`,

        contentOnNewLine2: `Note that Nimdee reserves the right to deny You access to any of the Services or Products or any portion thereof without notice for the following reasons: (a) immediately by Nimdee for any unauthorized access or use by You (b) immediately by Nimdee if You assign or transfer (or attempt the assignment or transfer of any rights granted to You under this Agreement; (c) immediately, upon a report of Your violation of this Agreement or any other policy accessible on Our platforms.`
    },
    {
        title: 'USE OF SERVICES',
        content: `Your use of Our Product, Website, Application and Services is solely for Your personal and non-commercial use. Any use of the Application, Website, Services or Products or their contents other than for personal purposes is prohibited. The following restrictions apply to Your personal and non-commercial use of Our Application, Website, Products and / or Services:`,

        list1: 'Use any material or information, including images or photographs, which are made available through the Services in any manner that infringes any copyright, trade-mark, patent, trade secret or other proprietary rights of the owner.',

        list2: 'Upload files that contain viruses, malware, worms, corrupted files, or any other similar software or programs that may damage the operation of our systems.',

        list3: 'Falsify or delete any copyright management information, such as author attributions, legal or other proper notices indicating the moral intellectual property right of another or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded to Our Website/Application.',

        list4: 'Use deep-links, page-scrape, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to increase traffic to the Application/Website, to access, acquire, copy or monitor any portion of the Application/Website, or in any way reproduce or circumvent the navigational structure or presentation of the Application/Website, or any content, to obtain or attempt to obtain any content, documents or information through any means not specifically made available through the Application/ Website.',

        list5: 'Restrict or inhibit any other User from using and enjoying the Services or Products.',

        list6: 'Violate any code of conduct or other guidelines, which may be applicable for any particular Service or Product.',

        list7: 'Violate any applicable laws or regulations.',

        list8: 'Use, download or otherwise copy, or provide (whether or not for a fee) to a person or entity any directory of Users of the Services or other User or usage information or any portion thereof.',

        content2: 'We reserve the right to review posts, feedbacks, and updates posted to this Website by any User and to remove any materials that are posted for a prohibited purpose, as well as suspend that User.'
    },
    {
        title: 'INTELLECTUAL PROPERTY',
        content: `All present and future rights in and to trade secrets, patents, copyrights, trade-marks, service marks, know-how, and other proprietary rights of any type under the laws of any governmental authority, domestic or foreign, including rights in and to all applications and registrations relating to the services provided by this Website (the “Intellectual Property Rights”) shall, vest in Nimdee.`,

        contentOnNewLine1: `The content of this Website including but not limited to computer programs, technology, data file, techniques, algorithms, methods, codes, logos, marks, trade secrets, designs, text, graphics, pictures, audio and video files, other data or copyrightable materials or content, and their selection and arrangement, are protected as a collective work or compilation under Nigerian copyright and other relevant conventions and treaties. All individual content, modules, articles, and other elements, including all software available on this Website or used to create and operate this Website is and remains the property of Nimdee, excluding third-party materials used to create contents on our website.`,

        contentOnNewLine2: `All trade-marks and service marks, whether registered or unregistered, as well as product names and company names or logos, displayed or mentioned on this Website are the property of their respective owners. A User may only use such marks with the prior written consent of the owner.`,

        contentOnNewLine3: `Reference to any products, services, processes, or other information, by trade name, trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, sponsorship, or recommendation of it by Nimdee.`,

        contentOnNewLine4: `You agree to abide by all applicable copyright laws, as well as any additional copyright notices or restrictions contained on this Website. You acknowledge that this Website is developed, compiled, prepared, revised, selected, and arranged by Nimdee and its partners and constitute their intellectual property. You agree to protect the proprietary rights of Nimdee and all others having rights in this Website during and after the term of these Terms and to comply with all reasonable written requests made by Nimdee or its suppliers and licensors of contents (“Affiliates”).`,

        contentOnNewLine5: `In a bid to protect Your copyright as well as the copyright of other Users, You agree to notify Nimdee in writing promptly upon becoming aware of any unauthorized access or use of this Website by any individual or entity.`,

        contentOnNewLine6: `All present and future rights in and title to this Website (including the right to exploit this Website and any portions of it over any present or future technology) are reserved to Nimdee for its exclusive use. Except as specifically permitted by these Terms, You may not copy or make any use of this Website or any portion of it, without prior written approval from Nimdee.`,

        contentOnNewLine7: `You shall not upload, post or otherwise make available on this Website, any content protected by copyright, trade-mark or other proprietary rights without the express permission of the owner of the copyright, trade-mark or other proprietary right and the burden of determining that any material is not protected by copyright rests with You. You shall be solely liable for any damage resulting from any infringement of copyrights, proprietary rights, or any other harm resulting from such a submission. By providing any material to this Website, You automatically grant or warrant that the owner of such material has expressly granted You a royalty-free, perpetual, irrevocable right and license to use, reproduce, modify, adapt, publish, translate and distribute such material (in whole or in part) worldwide and/or to incorporate it in other works in any form, media or technology now known or to be developed in the future for the full term of any copyright that may exist in such material.`
    },
    {
        title: 'CHANGED TERMS',
        content: `Nimdee reserves the right to modify these Terms at any time. Such modifications will become part of this Agreement and will be effective once posted by Nimdee on the Website. If a revision is material, we will notify You via electronic mail or via a pop-up or redirection when You log in on Our Website. Still, we recommend that You should review the Website and these Terms from time to time. (The most current version of the Terms can be reviewed by clicking on the "Terms of Use" hypertext link located at the bottom of Our Web pages.) Your continued use of Our Services means that You accept any new or modified Terms that we come up with.`
    },
    {
        title: 'TERMINATION',
        content: `You may terminate or suspend Your subscription at any time through Your profile management page. Upon termination, We will deactivate Your account and delete all the materials uploaded or transmitted to Us in the course of Your use of Our Products and Services. Once We confirm termination, You will not be charged for the next subscription and will not be entitled to a refund of any money already paid for any billing period, except where such request was made within the allowed refund request window.`,
        
        contentOnNewLine1: `We may terminate Your subscription at the end of Your billing period by providing at least 14 days’ written notice to You. Additionally, Nimdee may terminate these Terms at any time before the end of a billing period and without prior notice if:`,

        list1: 'You are in breach of the terms of these Terms',
        list2: 'You are unable to pay your subscription fees for 30 days after the due date',
        list3: 'We are investigating suspected misconduct by You',
        list4: 'You have used the Services in a way that causes legal liability to Us or disrupts the use of Our Services by other users',
        list5: 'You use this Website for any of the prohibited purposes or upload any of the prohibited materials.',
        content2: 'In the event that We limit, suspend, or terminate Our services, We will endeavour to give You advance notice and an opportunity to export a copy of Your Content from Our Website. However, in the event of violation of the provisions of these Terms or any applicable law, We may decide that We need to take immediate action to suspend Your account without notice',
        content3: 'We will use commercially reasonable efforts to narrow the scope and duration of any limitation or suspension implemented in line with these Terms and as is needed to resolve the issue that prompted such action. Nimdee may, subject to the provision of the law as to retention or right to be forgotten, retain Your Content upon termination of Service, but We are not under any obligation to do so.'
    },
    {
        title: 'MEMBER ACCOUNT, PASSWORD, AND SECURITY',
        content: `We are committed to safeguarding the privacy of Your information. When You sign up for Our services, We require that You create an account by providing Us with current, complete and accurate information as prompted by the applicable registration form.`,
        
        contentOnNewLine1: `We have measures in place designed to protect Your information against unauthorized access. But be aware that although we endeavor to provide reasonable security for information we collect and process, we recognize that no security system can prevent all potential security breaches; thus, we cannot guarantee the absolute safety of Your information.`,
        
        contentOnNewLine2: `Please note that You are entirely responsible for any and all activities that occur under Your account and You shall promptly notify Us of any unauthorized use of Your account or any other breach of security. Nimdee will not be liable for any loss that You may incur as a result of third-party access, either with or without Your knowledge.`
    },
    {
        title: 'NOTICE REGARDING SOFTWARE, DOCUMENTS AND SERVICES AVAILABLE ON THIS WEBSITE',
        content: `Disclaimer of warranty and limitation of liability`,
        list1: 'The User expressly agrees that the use of this Website is at the User\'s sole risk. Neither Nimdee, its affiliates nor any of Our respective employees, agents, third-party service providers or licensors warrant that this Website will be uninterrupted or error free; nor do they make any warranty as to the results that may be obtained from the use of this Website, or as to the accuracy, reliability or content of any information and service provided through this Website.',
        list2: 'This Website is provided on an "as is" basis without warranties of any kind, either express or implied, including, but not limited to, warranties of title or implied warranties of merchantability or fitness for any particular purpose, other than those warranties which are implied by and incapable of exclusion, restriction or modification under the laws applicable to these Terms.',
        list3: 'This disclaimer of warranty and limitation of liability applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of record, whether for breach of contract, tortious behaviour, negligence, or under any other cause of action.',
        list4: 'We disclaim liability for any errors or inaccuracy which may occur or be contained in any material despite our best effort to offer the current and correct information in the preparation of Our Application/Website/Services/Products. Neither We nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on Application/Website/Services/Products for any particular purpose.',
        list5: 'We reserve the right to remove, review, edit or delete any content at any time. Also, certain contents in the Application/Services/Website/Products may contain opinions and views. Nimdee shall not be responsible for such opinions or any claims resulting from them. Further, Nimdee makes no warranties or representations whatsoever regarding the quality,content, completeness, or adequacy of such information and data.',
        list6: 'We may contact Our Users through SMS, email, and call, to give information about Our Products as well as notifications on various important updates and/or to seek permission for demonstration of Our products. You agree to receiving these periodic messages',
        list7: 'Upon registration through any means whatsoever, Nimdee may contact You through the registered mobile number or e-mail or any other mobile number or contact number or email provided by You to enable effective provision of Our Services. The User expressly permits Nimdee to contact them and the student(s) utilizing the Services, through the above-mentioned means at any time post-registration.',
        list8: 'Nimdee’s Products and/or Services are compatible only with certain devices/tablets/instruments/hardware. Nimdee shall not be obligated to provide workable products and/or services for any instruments that are not recognized by Nimdee or those instruments that may be purchased from any third-party which are not compatible with Nimdee’s products and services. Also, You agree that any defect relating to any devices/tablets/instruments/hardware used to access Our Services or Products shall be reported to the manufacturer whose details shall be specified on the packaging and Nimdee shall not be responsible for such defect in any manner.',
        list9: 'Minors, un-discharged, or insolvents persons are not eligible to register for Our products or Services. Where a minor wish to use Our products or Services, such use shall be made available to the minor by his/her legal guardian or parents, who has agreed to these Terms. It is assumed that a minor who utilizes Our Services has obtained their legal guardian’s or parent’s consent and that such use is made available by the legal guardian or parents. Nimdee will not be responsible for any consequence that arises as a result of misuse of any kind of Our Application or any of Our products or Services that may occur by virtue of any person including a minor registering for the Services/Products provided. Where it is discovered that a User is under the age of 18 (eighteen) years, Nimdee reserves the right to terminate the User’s subscription and/or refuse to provide the User with access to the Products or Services.',
    },
    {
        title: 'MISCELLANEOUS',
        list1: 'Force majeure - We shall not be responsible for any failure or delay in performance due to circumstances beyond Our control, including, without limitation, to acts of God, war, riot, acts of civil or military authorities, fire, floods, accidents, service outages resulting from equipment and/or software failure and/or telecommunications failures, power failures, network failures, failures of third-party service providers (including providers of internet services and telecommunications). If You are affected by any such event, You shall notify Us within a maximum of seven (7) days from the day You became aware. The performance of these Terms shall then be suspended for as long as any such event shall prevent Us from performing Our obligations under these Terms.',
        list2: 'Third-Party Links - Third-Party links will take You out of this Website. The linked websites are not under the control of Nimdee and We are not responsible for the contents of any linked website or any link contained in a linked website, or any changes or updates to such Websites. Nimdee provides these links to You only as a convenience, and the inclusion of any link does not imply endorsement by Nimdee of the third-party website.',
        list3: 'Applicable Law - These Terms shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria.',
        list4: 'Indemnification - User agrees to defend, indemnify and hold harmless Nimdee, its affiliates and their respective directors, officers, employees and agents from and against all claims and expenses, including attorneys\' fees, arising out of the use of Nimdee by User or User\'s Account.',
        list5: 'References The companies, organizations, products, domain names, email addresses, logos, people and events depicted herein are fictitious. No association with any real company, organization, product, domain name, e-mail address, logo, person, or event is intended or should be inferred.',
        list6: 'Entire Agreement - These terms and conditions, together with Our Privacy Notice constitute the entire agreement between You and Us in relation to Your use of Our website and supersede all previous agreements in respect of Your use of this Website.',
    }
];