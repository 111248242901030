import styled from "styled-components"

export const Overlay = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 95;
`

export const SideBarContainer = styled.div`
    height: 100%;
    width: 280px;
    font-size: 14px;
    background-color: white;
    position: fixed;
    z-index: 100;
    /* left: -280px; */
    transition: 100ms ease-out;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
    -khtml-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.3);

    /* @media screen and (min-width: 1024px){
        left: 0 !important;
    } */

    ::-webkit-scrollbar {
        background-color: white;
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgb(54, 53, 97);
        border-radius: 3px;
        cursor: pointer;
        visibility: hidden;
    }

    :hover {
        ::-webkit-scrollbar-thumb {
            visibility: visible;
        }
    }
`

export const BrandLogo = styled.div`
    background-color: white;
    display: flex;
    align-items: center;
    z-index: 100;
    padding: 15px 25px;
    padding-top: 30px;
    position: sticky;
    top: 0;

    .MuiSvgIcon-root {
        font-size: 30px;
        margin-right: 20px;
    }
`

export const SideBarMenuWrapper = styled.div`
    /* margin-top: 100px; */
    margin-top: 30px;
`

export const SideBarMenu = styled.div`
    margin-bottom: 20px;

    > h2 {
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.7);
        padding: 10px 25px;
        border-bottom: 1px solid #ccc;
        user-select: none;
    }

    > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        > li > a {
            padding: 15px 25px;
            cursor: pointer;
            color: #666;
            width: 100%;
            display: inline-block;
            text-decoration: none;
            position: relative;
            display: flex;
            align-items: center;
            user-select: none;
           
            > span {
                font-weight: 500;
                font-size: 14px;
                margin-left: 15px;
                text-transform: uppercase;
                overflow: hidden;
            }

            ::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
                background-color: rgb(54, 53, 97);
                background-color: rgba(0, 0, 0, 0.075);
                z-index: -1;
                transition: 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
            }

            :hover {
                /* color: white; */
                ::after {
                    width: 100%;
                }
            }
        }
    }

`