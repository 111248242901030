import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
// import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducer from "./features/redux/reducer";
// import { ReactQueryDevtools } from 'react-query/devtools'

//REACT QUERY INCLUSIONS
import {
	QueryClient,
	QueryClientProvider,
} from 'react-query'

const queryClient = new QueryClient()

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)))

// import ReactGA from "react-ga";
// import GlobalContextProvider from './app/context/context';

// ReactGA.initialize("UA-189640286-1");
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<App />
					{/* <ReactQueryDevtools /> */}
				</QueryClientProvider>
			</Provider>
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
