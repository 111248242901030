// import styled from 'styled-components'
// import { useState } from 'react';
import { ContentContainer, ContentWrapper, TopBanner, BannerText } from '../styles/styles'
import { Input } from "../../../components/TextField/TextField";
import { TextArea } from "../../../components/TextField/TextArea";
import { Formik, Form } from "formik";
import { 
    // ContactFormContainer, 
    FormWrapper, FormHeading, WhatsappButton, 
    // DropDownContainer 
} from './styles'
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { SuccessToast, ErrorToast } from '../../../components/Toast/Toast';
// import emailjs from 'emailjs-com'
// import DropDown from './DropDown'
import Button from '../../../components/Button/Button'
import * as Yup from "yup";
import axios from 'axios';
import  { config }  from '../../../Constant';


const ContactUs = () => {
    var url = config.url.API_URL
    // const plans = [ "Free", "Annual", "Monthly", "School" ]
    // const users = [ "Parent", "Teacher", "Student", "Child" ]

    // const [ userType, setUserType ] = useState("none-selected")
    // const [ planType, setPlanType ] = useState("none-selected")

    const validate = Yup.object({
        email: Yup.string()
            .email("Email is invalid")
            .required("Email cannot be blank"),
        subject: Yup.string()
            // .max(34, "Must be 34 characters or less")
            .required("Subject cannot be blank"),
        message: Yup.string()
            .required("Message cannot be blank"),
    });

    return (
        <ContentContainer
            initial={{opacity: 0.8}} 
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
        >
            <ContentWrapper>
                <TopBanner>
                    <BannerText>
                        <h4>contact us</h4>
                    </BannerText>
                </TopBanner>

                <FormWrapper>
                    <FormHeading className="text-center">
                        <h2>Got questions? We are happy to help!</h2>

                        <a target="blank" href="https://wa.me/2349033854783"> <WhatsappButton className="my-3"> CHAT WITH US ON WHATSAPP <WhatsAppIcon /> </WhatsappButton> </a>

                        <h3 className="my-2"> OR </h3>

                        <h4 className="my-3"> Fill out the form below to submit a request </h4>
                    </FormHeading>
                    <hr />
                    <Formik
                        initialValues={{
                            email: "",
                            subject: "",
                            message: "",
                        }}
                        validationSchema={validate}
                        onSubmit={(values) => {
                            // values.userType = userType
                            // values.plan = planType
                            // console.log(values)
                            axios
                                .post(
                                    `${url}/AdminDashboard/admindashboard.php/contactUs`,
                                    values
                                ).then((res) => {
                                    if (res.status === 200) {
                                        SuccessToast(res.data.message);
                                    } else {
                                        ErrorToast(res.data.message);
                                    }
                                })
                                .catch(function (error) {
                                    ErrorToast(error.response.data.message);
                                    console.log(error);
                                });
                        }}
                    >
                        {(formik) => (
                            <div>
                                <Form className="my-2">
                                    <Input
                                        label="Your Email"
                                        name="email"
                                        type="email"
                                        placeholder="Enter your email"
                                    />
                    
                                    <div className="my-3"></div>

                                    <Input
                                        label="Subject"
                                        name="subject"
                                        type="text"
                                        placeholder="Message Subject"
                                    />

                                    <div className="my-3"></div>

                                    <TextArea
                                        label="Detail of issue"
                                        name="message"
                                        type="text"
                                        placeholder="Type your message here"
                                    />

                                    {/* <DropDownContainer>
                                        <DropDown legend="User Type" options={users} name="user-type" setUserType={setUserType} setPlanType={setPlanType} />
                                        
                                        <DropDown legend="Type of plan" options={plans} name="user-plan" setUserType={setUserType} setPlanType={setPlanType} />
                                    </DropDownContainer> */}

                                    <div className="my-5">
                                        <Button type="submit" title="Submit request" />
                                    </div>
                                </Form>
                            </div>
                        )}
                    </Formik>
                </FormWrapper>
            </ContentWrapper>
        </ContentContainer>
    )
}

export default ContactUs