import { useEffect, useState, useRef } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { DropDownContainer, DropDownTitle, DropDownMenu, DropDownItem } from './styles'

const DropDown = ({ legend, options, assignedTeacher, setAssignedTeacher, isEditedClass, setIsEditedClass }) => {
    const DropDownMenuRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    const handleSelect = (e) => {
        setIsEditedClass({
            ...isEditedClass,
            teacher_name: ""
        })
        let selectedOption = e.target.textContent
        setAssignedTeacher(selectedOption.trim())
        setIsOpen(false)
    }

    const openDropDown = (e) => {
        setIsOpen(!isOpen)
    }

    const handleClickOutside = (e) => {
        if (DropDownMenuRef.current  && !DropDownMenuRef.current.contains(e.target)) setIsOpen(false)
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [])

    return (
        <DropDownContainer ref={DropDownMenuRef} >
            <DropDownTitle onClick={openDropDown}> <span> {assignedTeacher ? assignedTeacher : legend} </span> {isOpen ? <ChevronUpIcon style={{height: "20px"}} onClick={openDropDown} /> : <ChevronDownIcon style={{height: "20px"}} onClick={openDropDown} />} </DropDownTitle>
            <DropDownMenu style={{display: isOpen ? "block" : "none"}}>
                {options?.map((link, index) => <DropDownItem key={index} onClick={handleSelect}> {link} </DropDownItem>)}
            </DropDownMenu>     
        </DropDownContainer>
    )
}

export default DropDown