import React from 'react';
import Header from '../../layout/Header/Header';
import Footer from '../../layout/Footer';
import Accordion from "../../Helpers/Accordion"
import { TermsAndCondtionsData } from "./TermsAndConditionsData"
const TermsAndConditions = () => {
  return (
    <div>
      <Header />
      <div className='container'>
        <h1 className='text-center mt-4'>Terms and Conditions</h1>
        <div className="accordion">
            {TermsAndCondtionsData.map(({ title, content, content2, content3, list1, list2, list3, list4, list5, list6, list7, list8, list9, contentOnNewLine1, contentOnNewLine2, contentOnNewLine3, contentOnNewLine4, contentOnNewLine5, contentOnNewLine6, contentOnNewLine7 }) => (
              <Accordion title={title} content={content} content2={content2} content3={content3} list1={list1} list2={list2} list3={list3} list4={list4} list5={list5} list6={list6} list7={list7} list8={list8} list9={list9} contentOnNewLine1={contentOnNewLine1} contentOnNewLine2={contentOnNewLine2} contentOnNewLine3={contentOnNewLine3} contentOnNewLine4={contentOnNewLine4} contentOnNewLine5={contentOnNewLine5} contentOnNewLine6={contentOnNewLine6} contentOnNewLine7={contentOnNewLine7}/>
            ))}
        </div>
        <div className='contact mb-5'>
          <h4>Contact Us</h4>
          <p>
            If You have any questions about this Privacy Notice, please contact
            Us at <a href='mailto:support@nimdee.com'> support@nimdee.com</a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;