import styled from 'styled-components'

export const TeamContainer = styled.div`
    width: 90vw;
    max-width: 1650px;
    margin: 50px auto;

    > h2 {
        text-align: center;
        font-weight: 700;
        font-size: 23px;
        text-transform: uppercase;

        @media screen and (min-width: 850px){
            font-size: 30px;
        } 
    }
`

export const TeamWrapper = styled.div`display: grid;
    margin: 40px 0;
    display: grid;
    gap: 20px;

    @media screen and (min-width: 850px){
        gap: 30px;
        grid-template-columns: repeat(2, 1fr);
    }
`

export const MemberWrapper = styled.div`
    /* border: 1px solid grey; */
    padding: 20px;
    border-radius: 7px;
    -webkit-box-shadow: 0px 5px 10px #ccc;
    -khtml-box-shadow: 0px 5px 10px #ccc;
    -moz-box-shadow: 0px 5px 10px #ccc;
    box-shadow: 0px 5px 10px #ccc;
`

export const MemberHeader = styled.div`

    @media screen and (min-width: 500px){
        display: flex;
        align-items: center;
    }

`

export const MemberImage = styled.div`
    height: 130px;
    border-radius: 5px;

    > img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        border-radius: inherit;
    }

    @media screen and (min-width: 850px){
        height: 170px;
    } 
`

export const MemberTitle = styled.div`
    /* text-align: center; */
    margin: 0 10px 0 20px;
    margin-top: 15px;
    /* align-self: flex-end; */

    > h1 {
        text-transform: uppercase;
        font-size: 17px;
        font-weight: 700;
    }

    > h2 {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 400;
    }

    @media screen and (min-width: 500px){
        margin-top: 0;
    }

    @media screen and (min-width: 850px){
        > h1 {
            font-size: 22px;
        }

        > h2 {
            font-size: 18px;
        }
    } 
`

export const MemberDescription = styled.p`
    margin-top: 20px;
    /* font-style: italic; */
    font-size: 13px;
    text-align: justify;

    @media screen and (min-width: 850px){
        font-size: 16px;
    } 
`